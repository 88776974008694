import React from 'react'
import './card.css';

const Card = ({ children, css }) => {
  return (
    <div style={css ? css : { "width": "73.5%", "margin": "auto", "paddingTop": "0.8rem", "paddingBottom": "1rem" }} className="info__card">

      <section className="design_cart">
        {children}
      </section>

    </div>
  )
}

export default Card
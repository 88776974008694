import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
    planName:"",
    Plantype:"",
}

export const choosePlan = createSlice({
    name: 'choosePlan',
    initialState,
    reducers: {
        showAndToggle: (state, action) => {
            state.value = (action.payload);
        },
        addPlanName: (state, action) => {
            state.planName = (action.payload);
        },
        addPlanType: (state, action) => {
            state.Plantype = (action.payload);
        }
    },
})

// Action creators are generated for each case reducer function
export const { showAndToggle,addPlanName,addPlanType } = choosePlan.actions

export default choosePlan.reducer
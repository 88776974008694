import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
}

export const pastJourneyTable = createSlice({
    name: 'pastJourneyTable',
    initialState,
    reducers: {
        addTableData4: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { addTableData4 } = pastJourneyTable.actions

export default pastJourneyTable.reducer
import React, { useState } from 'react'
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import HeadingWithText from "../../components/final_components/HeadingWithText/HeadingWithText";
import ls from 'localstorage-slim';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/layouts/Footer/Footer';

const RefundRequest2 = () => {

    const nav = useNavigate();

    const planName = ls.get('choosePlanInRefund', { decrypt: true });
    const [refundData, setRefundData] = useState({
        "plan_name": planName,
        "refund_desc": "",
        "claim_amount": "",
    });

    const [isSuccess, setIsSuccess] = useState(false);

    // inputChange(e)
    function inputChange(e) {
        setRefundData({ ...refundData, [e.target.name]: e.target.value });
    }

    // post form data to backend
    function postRefundData(e) {
        e.preventDefault();
        axios.post("", JSON.stringify(refundData))
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    return (
        <>

            {/* navbar START*/}
            <SecondNavBar />
            {/* navbar END */}

            {/* top section Component */}
            <TopSection
                bigi={"Get Your Refund"}
                small={"lorem lorem lorem jhfge xuhfd gf"}
            >
            </TopSection>
            {/* top section Component END*/}

            {/* about us Main Card START */}
            <Card css={{ "width": "53.5%", "margin": "2rem auto", "paddingTop": "1.6rem", "paddingBottom": "1rem" }}>

                <HeadingWithText head={"Please fill your details"} />

                <div className='px-8 pt-3'>

                    <div>
                        <p className='font-Cardo'>Name of Subscription Plan</p>

                        <input onChange={(e) => { inputChange(e) }} name='plan_name' type={"text"} value={refundData.plan_name} className='rounded my-3 pl-3 outline-0' style={{ "width": "100%", "border": "1px solid #232323" }} />
                    </div>

                    <div>
                        <p className='font-Cardo'>Description(Reason why you need refund)</p>

                        <input onChange={(e) => { inputChange(e) }} name="refund_desc" type={"text"} value={refundData.refund_desc} placeholder={"Eg. Lorem"} className='rounded my-3 pl-3 outline-0 placeholder:text-xs' style={{ "width": "100%", "border": "1px solid #232323" }} />
                    </div>

                    <div>
                        <p className='font-Cardo' >How much amount you want to claim?</p>

                        <input onChange={(e) => { inputChange(e) }} name='claim_amount' value={refundData.claim_amount} type={"number"} placeholder={"Eg. 50"} className='rounded my-3 pl-3 outline-0 placeholder:text-xs' style={{ "width": "100%", "border": "1px solid #232323" }} />
                    </div>

                    <div className='flex justify-between items-center'>
                        <div className='my-6 flex items-center'>
                            <button className='flex  items-center text-white px-6 rounded shadow-md py-1' style={{ "background": "#262626" }} onClick={(e) => { postRefundData(e) }}><p>Continue</p></button>
                        </div>
                        <div className='my-6 flex items-center justify-end'>
                            <button onClick={() => { nav('/refund-request') }} className='flex  items-center text-white px-1 shadow-md py-1' style={{ "background": "#262626", "borderTopLeftRadius": "0.2rem", "borderBottomLeftRadius": "0.2rem" }}><span className="material-symbols-outlined ml-1">
                                expand_more
                            </span></button>
                        </div>
                    </div>




                </div>

            </Card>
            {/* about us Main Card END */}

            {/* bottomer */}
            <div className='flex justify-center ' onClick={() => { nav('/contact-us') }}>
                <div className='rounded-tl-lg cursor-pointer rounded-tr-lg flex justify-center items-center' style={{ "width": "53.5%", "margin": "auto", "height": "7rem", "background": "#262626" }}>
                    <p className='requst_bottom_para flex justify-center items-center'><span>Contact us</span> <span className="material-symbols-outlined ml-5">
                        arrow_forward
                    </span></p>
                </div>
            </div>

            <Footer />


            {/* all popup */}

            <div style={isSuccess ? { "display": "block" } : { "display": "none" }}>
                <section className="pop_up_main_wrapper" >
                    <div className="main_popup_1 edit-width-refunpop">

                        <div className="close_icon flex justify-end">
                            <span className="material-symbols-outlined" style={{ "background": "#F8F9FA", "borderRadius": "50%", "padding": "0.1rem", "cursor": "pointer" }}>
                                close
                            </span>
                        </div>

                        <div className="flex flex-col justify-center items-center edit-width-refun_warpper">
                            <div className="icon_wrapper">
                                <span className="material-symbols-outlined" style={{ "fontSize": "3rem" }}>
                                    check_box
                                </span>
                            </div>

                            <div>
                                <p className="track_popup1_mess edit-width-refunmess">Your refund request is submitted successfully.</p>
                            </div>

                            <div style={{ "width": "100%" }}>
                                <button className="track_whenclick_button rounded edit-width-refunbutton">Continue</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
}

export default RefundRequest2
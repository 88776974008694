import React, { useState } from "react";
import styled from "styled-components";
import { forgot_resetPassword } from "../../../apis/api";
import axios from "axios";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ls from 'localstorage-slim'

const FStep3 = () => {
  const nav = useNavigate();
  const user_id = ls.get('user_id_from_forgot', { decrypt: true });
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShow2, setPasswordShow2] = useState(false);
  const userWithID = ls.get("emailOTPId", { decrypt: true })
  // iniatize state variables...
  const [inputData, setInputData] = useState({
    "password": "",
    "compare_password": ""
  });

  // handle change function...
  function handleInputChange(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  }

  // reset Password
  function resetPassword(e) {
    if (inputData.password.length < 5 || inputData.compare_password.length < 5) {
      window.alert("Password must be include minimum 5 characters")
    } else if (inputData.pass !== inputData.cpass) {
      window.alert("password does not matched")
    } else {

      axios.post(`${forgot_resetPassword}/${userWithID}`, inputData).then(response => {
        console.log(response)
        if (response.data.success) {
          nav('/login')
        }
      }).catch(err => {
        window.alert(err.response.data.message);
      })
    }
  }



  return (
    <div className="bg-white flex items-center w-[full] h-[100vh] justify-center">
      <Row className=" py-8 w-[60%]">
        <div className="px-4 lg:px-36 flex items-center flex-col justify-center h-[30rem] w-[100%] gap-4">
          <div className="flex flex-row-reverse justify-between items-center mb-5 w-[100%]">

            <div className="flex items-center">

              <img src="logogo.png" alt="logo_img" style={{ "width": "6rem" }} />
            </div>

            <div className="flex items-center">
              <button className="pt-2" onClick={() => { nav(-1) }}><span className="material-symbols-outlined">
                keyboard_backspace
              </span></button>
              <div>
                <MainHeading HeadinName="Reset Password" />
                <span className="text-xs">Change your password</span>
              </div>
            </div>

          </div>
          <div className="main-input-container relative w-full">

            {/* password */}
            <div className="w-[97%] relative">
              <label htmlFor="cpass">Password</label>
              {/* input field of company_name START */}
              {passwordShow === false ? (
                <Input
                  label="Password"
                  type="password"
                  name="password"
                  placeholder="**********************"
                  className="pr-9"
                  onChange={(e) => { handleInputChange(e) }}
                />
              ) : (
                <Input
                  label="Password"
                  type="text"
                  name="password"
                  placeholder="Password"
                  className="pr-9"
                  onChange={(e) => { handleInputChange(e) }}
                />
              )}
              <div className="w-[3%] absolute right-5 top-[3.2rem]">
                {passwordShow === false ? (
                  <AiOutlineEyeInvisible
                    onClick={() => setPasswordShow(true)}
                  />
                ) : (
                  <AiOutlineEye
                    onClick={() => setPasswordShow(false)}
                  />
                )}
              </div>
            </div>

            {/* confirm password */}
            <div className="w-[97%] relative">
              <label htmlFor="cpass">Confirm Password</label>
              {/* input field of company_name START */}
              {passwordShow2 === false ? (
                <Input
                  label="Password"
                  type="password"
                  name="compare_password"
                  placeholder="********"
                  className="pr-9"
                  onChange={(e) => { handleInputChange(e) }}
                />
              ) : (
                <Input
                  label="Password"
                  type="text"
                  name="compare_password"
                  placeholder="password"
                  className="pr-9"
                  onChange={(e) => { handleInputChange(e) }}
                />
              )}
              <div className="w-[3%] absolute right-5 top-[3.2rem]">
                {passwordShow2 === false ? (
                  <AiOutlineEyeInvisible
                    onClick={() => setPasswordShow2(true)}
                  />
                ) : (
                  <AiOutlineEye
                    onClick={() => setPasswordShow2(false)}
                  />
                )}
              </div>
            </div>

            {/* <div className="w-full relative">
              <label htmlFor="cpass">Password</label>
              <Input
                label="Password"
                type="password"
                name="password"
                placeholder="**********************"
                className="pr-9"
                onChange={(e) => { handleInputChange(e) }}
              />
            </div> */}

            {/* <div className="w-full relative  mt-[1.375rem] ">
              <label htmlFor="cpass">Confirm assword</label>
              <Input
                label="Password"
                type="password"
                name="compare_password"
                placeholder="********"
                className="pr-9"
                onChange={(e) => { handleInputChange(e) }}
              />
            </div> */}
          </div>

          <div className="w-full mt-2 relative">

            <Button style={{ "background": "#232323", "color": "white", "width": "100%" }}
              onClick={(e) => {
                resetPassword(e);
              }}
            >Reset Password</Button>

            <div className="mt-[0.625rem]">
              <p className="font-Inter font-normal text-[rgba(0,0,0,1)] text-sm text-center">
                Don’t have an account?
                <span
                  style={{
                    color: "black",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  <Link to="/single-page-register" className="ml-3 underline">Signup</Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};
const Row = styled.div`

`;
const Input = styled.input`
margin-top: 0.75rem;
  width: 100%;
  padding: 0.75rem 2.25rem 0.75rem 1rem;
  border-radius: 5px;
  border: none;
  outline:none !important;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  font-family: "Inter", sans-serif;
`;
export default FStep3;

import { Button } from '@mui/material'
import React, {useEffect} from 'react'
import { useState } from 'react'
import { hdfcFirstSubmit, testApi } from '../../apis/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ls from 'localstorage-slim'
import {load} from "@cashfreepayments/cashfree-js";


const HDFCFORM = () => {
    const cpaster = useSelector((state) => state.cPA.value);
    sessionStorage.setItem("cpaster", cpaster);
    console.log("cpaster", cpaster)
    const [apiData,setApiData] = useState();
    const iLogin = ls.get('iLogin');
    let login_response_object, token_user, user_id;
    if (iLogin) {
        login_response_object = ls.get("login_data_object", { decrypt: true });
        token_user = login_response_object.token;
        user_id = login_response_object.user._id;
    }
    let states = ["Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"];

    const [inputData, setInputData] = useState({
        "merchant_id":2350710,
        "amount":cpaster,
        "language":"EN",
        "currency":"INR",
        "redirect_url": "https://lorrycare.com:5000/v1/payment-success/{order_id}",
        "cancel_url":"",
        "billing_name": "",
        "billing_address": "",
        "billing_state": "",
        "billing_zip": "",
        "billing_country": "INDIA",
        "billing_tel": "",
        "billing_email": "",
    });
    const [sessionID, setSessionID] = useState(null)
    const [cashfree, setCashfree] = useState(null)

    useEffect(() => {
        load({ mode:"production" }) //or production
            .then((result) => {
                setCashfree( result );
            })
            .catch((error) => {
                console.error("Error loading cashfree:", error);
            });
    }, [])
    useEffect(() => {
        if(cashfree) {
            const options = {
                values: {
                    upiId: '9512787947@paytm'
                }
            };
            const component = cashfree.create('upiCollect', options);
            component.mount('#my-div');
        }
    }, [cashfree])

    // change input function...
    function changeInput(e) {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
        console.log(inputData);
    }

    async function clickSubmit(e) {
        e.preventDefault()

        const url = 'https://lorrycare.com:5000/v1/payment/'
        // const url = 'http://localhost:5000/v1/payment/'
        await axios.post(url+user_id, inputData)
            .then(res => {
                setApiData(res.data);
                setSessionID(res.data.sessionId); // Assuming `response.data.sessionId` is returned from your API call
                handlePayment(res.data.sessionId)
            })
            .catch(err => {
                console.log(err);
            });
    }


    const handlePayment = (sessionID) => {
        let checkoutOptions = {
            paymentSessionId: sessionID,
            returnUrl: "https://lorrycare.com:5000/v1/status/{order_id}",
        }
        cashfree.checkout(checkoutOptions).then(function(result){
            if(result.error){
                alert(result.error.message)
            }
            if(result.redirect){
                console.log("Redirection")
            }
        });
    };
    // send data
    // function clickSubmit() {
    //     axios.post(hdfcFirstSubmit, inputData).then(res => {
    //         setApiData(res.data)
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }

    return (
        <>
            <div id="my-div"></div>
        <form name="frmPayment"  className='flex flex-col justify-center items-center h-[100vh]'>
            <div autocomplete="off" className="profile_main_section w-[73.5%]  m-auto rounded-lg p-2 bg-white" style={{ "boxShadow": "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}>
                <div className="container__profile_container w-[90%] m-auto h-[100%] py-2">
                    <input type={"hidden"} name={"merchant_id"} value={2350710}/>
                    <input type={"hidden"} name={"amount"} value={sessionStorage.getItem("cpaster")}/>
                    <input type={"hidden"} name={"language"} value={"EN"}/>
                    <input type={"hidden"} name={"currency"} value={"INR"}/>
                    {/* row1 START */}
                    <div className=' flex  w-[100%] mt-2 justify-between'>
                        <div className="inputItem flex flex-col gap-2 w-[48%]">
                            <label>Billing Name</label>
                            <input
                                className="border-none border-black rounded-md w-[100%] p-1 pl-2" name="billing_name"
                                style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }} type={"text"}
                                placeholder={"Enter your name"} onChange={(e) => { changeInput(e) }} required
                            />
                        </div>

                        <div className="inputItem flex flex-col gap-2 w-[48%]">
                            <label>Billing Address</label>
                            <input
                                type="text" autoComplete='OFF'
                                className="border-none border-black rounded-md w-[100%] p-1 pl-2 lowercase"
                                placeholder="Enter your address"
                                name="billing_address"
                                required style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }} onChange={(e) => { changeInput(e) }}
                            />

                        </div>
                    </div>
                    {/* row1 START */}

                    {/* row2 START */}
                    <div className="second-Section flex  w-[100%] mt-2 justify-between">

                        <div className="inputItem flex flex-col gap-2 w-[48%]">
                            <label>State</label>
                            <select style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }} onChange={(e) => { changeInput(e) }} className='border-none border-black rounded-md w-[100%] p-1 pl-2 lowercase' name="billing_state" id="billing_state">
                                {states.map(elm => {
                                    return <option value={elm}>{elm}</option>
                                })}
                            </select>
                        </div>

                        <div className="inputItem flex flex-col gap-2 w-[48%]">
                            <label>Zipcode</label>
                            <input autocomplete="off"
                                type="number"
                                className="border-none border-black rounded-md w-[100%] p-1 pl-2"
                                placeholder="Enter your zip code"
                                name="billing_zip"
                                style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                                onChange={(e) => { changeInput(e) }} required
                            />
                        </div>

                    </div>
                    {/* row2 END */}

                    {/* row3 START */}
                    <div className="second-Section flex  w-[100%] mt-2 justify-between">

                        <div className="inputItem flex flex-col gap-2 w-[48%]">
                            <label>Country</label>
                            <input autocomplete="off"
                                type="text"
                                className="border-none border-black rounded-md w-[100%] p-1 pl-2"
                                placeholder="Enter your zip code"
                                name="billing_country"
                                style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                                value={"INDIA"} onChange={(e) => { changeInput(e) }}
                                disabled required
                            />
                        </div>

                        <div className="inputItem flex flex-col gap-2 w-[48%]">
                            <label>Phone</label>
                            <input autocomplete="off"
                                type="number"
                                className="border-none border-black rounded-md w-[100%] p-1 pl-2"
                                placeholder="Enter your phone number"
                                name="billing_tel"
                                required style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }} onChange={(e) => { changeInput(e) }}
                            />
                        </div>

                    </div>
                    {/* row3 END */}

                    {/* row4 START */}
                    <div className="second-Section flex  w-[100%] mt-2 justify-between">

                        <div className="inputItem flex flex-col gap-2 w-[48%]">
                            <label>Email</label>
                            <input autocomplete="off"
                                type="email"
                                className="border-none border-black rounded-md w-[100%] p-1 pl-2"
                                placeholder="example@gmail.com"
                                name="billing_email"
                                style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                                onChange={(e) => { changeInput(e) }} required
                            />
                        </div>

                    </div>
                    {/* row4 END */}


                    <div className='flex justify-between items-center mt-5'>
                        <div>
                            <Button style={{ "background": "#232323", "color": "white", "fontFamily": "inter", "fontSize": "0.8rem" }} type={"submit"} onClick={clickSubmit}>Submit</Button>
                        </div>
                    </div>

                    
                </div>

            </div>

        </form>
        </>
    )
}

export default HDFCFORM
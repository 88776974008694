import React from "react";
import { useNavigate } from "react-router-dom";

const TopSection = ({ bigi, small, small2, small3, children, classes }) => {
  const navigate = useNavigate();

  return (
    <>
      {/* top section */}
      <section
        className="track_top_section"
        style={{
          borderBottom: "1px solid gray",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "73.5%",
          margin: "auto",
        }}
      >
        {/* left */}
        <div style={{ padding: "3px 0rem" }} className="flex top_sec_left">
          <button onClick={() => { navigate(-1) }}><span className="material-symbols-outlined">
            keyboard_backspace
          </span></button>
          <div className="ml-5">
            <h1 className="text-2xl font-Cardo font-bold my-2">{bigi}</h1>
            <p style={{ fontSize: "0.9rem", "marginLeft": "5px" }}>{small}{small2 ? <span> {small2} </span> : <></>}{small3}</p>
          </div>
        </div>

        {/* right */}
        <div className={classes?classes:"flex filter_div  top_sec_left"}>{children}</div>
      </section>
    </>
  );
};

export default TopSection;

import React, { useState, useEffect } from "react";
import TopSection from "../../TopSection";
import CommonTable from "../../CommonTable";
import { CSVLink } from "react-csv";
import Filter from "../../components/layouts/Filter/Filter";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import { useSelector, useDispatch } from 'react-redux'
import Footer from "../../components/layouts/Footer/Footer";
import { getPastJourney } from "../../apis/api";
import ls from 'localstorage-slim'
import { Button } from "@mui/material";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { Past_Journey_Top_Heading_Hn,Past_Journey_Top_Subheading_Hn,Past_Default_table_message_Hn } from '../../components/translation/Hn'
import { Past_Journey_Top_Heading_En,Past_Journey_Top_Subheading_En,Past_Default_table_message_En } from '../../components/translation/En'
import MobilePastJourney from "./Mobile_PastJourney";

const PastJourney = () => {
  const eng = useSelector((state) => state.translate.value);
  let tableData
  // get shorted and filter data
  const shortedFilterData4 = useSelector((state) => state.pastJourneyTable.value);
  const iLogin = ls.get('iLogin');
  let login_response_object, token_user, user_id;
  let [count, setCount] = useState(0);
  let [apiData,setApiData] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);


  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }

  // get ongoing data...
  useEffect(() => {
    axios.get(`${getPastJourney}/${user_id}/${count}`, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    }).then(response => {
      console.log(response)
      if (response.data.success) {
        console.log(response.data.data)
        setApiData(response.data.data);
      }
    }).catch(err => {
      console.log(err)
    })

  }, [count])

  if (shortedFilterData4 === 0) {
    tableData = apiData;
  } else {
    tableData = shortedFilterData4;
  }

  // csvData
  const csvData = tableData;
  let latestCsv=[];
  tableData.forEach(elm=>{
      console.log("elm16june====")
      console.log(elm)
      latestCsv.push({Vehicle_no:elm.vehicle_no,Origin:elm.source[0].source,Destination:elm.destination[0].destination,Journey_Number:elm.trip_no,Start_Date:elm.start_date,End_Date:elm.end_date})
  })

  return (
    <>
     {isMobile ? <>
                <MobilePastJourney />
            </> :
    <>
      <div className="">
        <SecondNavBar active6={true}/>
      </div>
      {/* top section Component */}
      <TopSection
        bigi={eng ? Past_Journey_Top_Heading_En : Past_Journey_Top_Heading_Hn}
        small={eng ? Past_Journey_Top_Subheading_En : Past_Journey_Top_Subheading_Hn}
      >
        <CSVLink
          style={{
            textDecoration: "none",
            display: "inline-block",
            background: "black",
            color: "white",
            borderRadius: "0.3rem",
            padding: "0.4rem 2rem",
            border: "none",
            cursor: "pointer",
          }}
          data={latestCsv}
          className={"download_scv"}
        >
          Download All to CSV
        </CSVLink>
        <Filter tableData={tableData} />
      </TopSection>
      {/* top section Component END*/}

      {/* common table START */}
      <CommonTable
        type={"2"}
        tableHead={[
          "vehicle registration number",
          "origin",
          "destination",
          "Journey Number",
          "start_date",
          "end_date",
          ""
        ]}
        tableData={tableData}
        extraCss={'tracker_main_container control_gap'}
        defaultMessage={eng?Past_Default_table_message_En:Past_Default_table_message_Hn}
      />
      {/* common table END */}

      {/* <div className="flex justify-end w-[73.5%]" style={{"margin":"auto"}}>
        <span className="mt-[-4rem] mr-[1rem]">please download within 90 days <span className="text-red-800">*</span></span>
      </div> */}

      {/* common table END */}
      {showLoadMore ?
        <div className="flex justify-center pb-8">
          <Button variant="contained" style={(apiData.length<5)?{ "display": "none" }:{ "background": "#232323" }} onClick={() => { setCount(count + 5) }}>Load More</Button>
        </div> : <></>
      }

      <Footer />
    </>}
    </>
  );
};

export default PastJourney;

import React, { useState, useEffect } from "react";
import "./toll.css";
import ls from "localstorage-slim";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { Button, Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";

const TollPlazaPublic = () => {
  const params = useParams();
  const id = params.id;
  const random = params.random;
  const [allPlazaMain, setAllPlazaMain] = useState({});
  const [allPlaza, setAllPlaza] = useState([]);
  const [origin, setOrigin] = useState();
  const [dest, setDest] = useState();
  const [startdate, setStartDate] = useState();
  const [apiData, setApiData] = useState([]);
  const [main, setMain] = useState([]);
  let endDate;
  let lastIndexOfTollPlaza = allPlaza.length - 1;

  useEffect(() => {
    console.log(
      `https://lorrycare.com:5000/v1/onGoingJourney/${id}/shared/${random}`
    );
    fetch(`https://lorrycare.com:5000/v1/onGoingJourney/${id}/shared/${random}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data);
        setApiData(data.data.allPlaza);
        setMain(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div
        className="flex justify-between items-center"
        style={{ width: "73.5%", margin: "auto" }}
      >
        <a
          href={
            "https://play.google.com/store/apps/details?id=com.lorrycare.app&pcampaignid=web_share"
          }
        >
          <Tooltip title="Download our app" placement="top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10rem"
              viewBox="0 0 135 40"
              id="google"
            >
              <path d="M130 40H5c-2.8 0-5-2.2-5-5V5c0-2.8 2.2-5 5-5h125c2.8 0 5 2.2 5 5v30c0 2.8-2.2 5-5 5z" />
              <path
                fill="#a6a6a6"
                d="M130 .8c2.3 0 4.2 1.9 4.2 4.2v30c0 2.3-1.9 4.2-4.2 4.2H5C2.7 39.2.8 37.3.8 35V5C.8 2.7 2.7.8 5 .8h125m0-.8H5C2.2 0 0 2.3 0 5v30c0 2.8 2.2 5 5 5h125c2.8 0 5-2.2 5-5V5c0-2.7-2.2-5-5-5z"
              />
              <path
                fill="#fff"
                stroke="#fff"
                strokeMiterlimit="10"
                strokeWidth=".2"
                d="M47.4 10.2c0 .8-.2 1.5-.7 2-.6.6-1.3.9-2.2.9-.9 0-1.6-.3-2.2-.9-.6-.6-.9-1.3-.9-2.2 0-.9.3-1.6.9-2.2.6-.6 1.3-.9 2.2-.9.4 0 .8.1 1.2.3.4.2.7.4.9.7l-.5.5c-.4-.5-.9-.7-1.6-.7-.6 0-1.2.2-1.6.7-.5.4-.7 1-.7 1.7s.2 1.3.7 1.7c.5.4 1 .7 1.6.7.7 0 1.2-.2 1.7-.7.3-.3.5-.7.5-1.2h-2.2v-.8h2.9v.4zM52 7.7h-2.7v1.9h2.5v.7h-2.5v1.9H52v.8h-3.5V7H52v.7zM55.3 13h-.8V7.7h-1.7V7H57v.7h-1.7V13zM59.9 13V7h.8v6h-.8zM64.1 13h-.8V7.7h-1.7V7h4.1v.7H64V13zM73.6 12.2c-.6.6-1.3.9-2.2.9-.9 0-1.6-.3-2.2-.9-.6-.6-.9-1.3-.9-2.2s.3-1.6.9-2.2c.6-.6 1.3-.9 2.2-.9.9 0 1.6.3 2.2.9.6.6.9 1.3.9 2.2 0 .9-.3 1.6-.9 2.2zm-3.8-.5c.4.4 1 .7 1.6.7.6 0 1.2-.2 1.6-.7.4-.4.7-1 .7-1.7s-.2-1.3-.7-1.7c-.4-.4-1-.7-1.6-.7-.6 0-1.2.2-1.6.7-.4.4-.7 1-.7 1.7s.2 1.3.7 1.7zM75.6 13V7h.9l2.9 4.7V7h.8v6h-.8l-3.1-4.9V13h-.7z"
              />
              <path
                fill="#fff"
                d="M68.1 21.8c-2.4 0-4.3 1.8-4.3 4.3 0 2.4 1.9 4.3 4.3 4.3s4.3-1.8 4.3-4.3c0-2.6-1.9-4.3-4.3-4.3zm0 6.8c-1.3 0-2.4-1.1-2.4-2.6s1.1-2.6 2.4-2.6c1.3 0 2.4 1 2.4 2.6 0 1.5-1.1 2.6-2.4 2.6zm-9.3-6.8c-2.4 0-4.3 1.8-4.3 4.3 0 2.4 1.9 4.3 4.3 4.3s4.3-1.8 4.3-4.3c0-2.6-1.9-4.3-4.3-4.3zm0 6.8c-1.3 0-2.4-1.1-2.4-2.6s1.1-2.6 2.4-2.6c1.3 0 2.4 1 2.4 2.6 0 1.5-1.1 2.6-2.4 2.6zm-11.1-5.5v1.8H52c-.1 1-.5 1.8-1 2.3-.6.6-1.6 1.3-3.3 1.3-2.7 0-4.7-2.1-4.7-4.8s2.1-4.8 4.7-4.8c1.4 0 2.5.6 3.3 1.3l1.3-1.3c-1.1-1-2.5-1.8-4.5-1.8-3.6 0-6.7 3-6.7 6.6 0 3.6 3.1 6.6 6.7 6.6 2 0 3.4-.6 4.6-1.9 1.2-1.2 1.6-2.9 1.6-4.2 0-.4 0-.8-.1-1.1h-6.2zm45.4 1.4c-.4-1-1.4-2.7-3.6-2.7s-4 1.7-4 4.3c0 2.4 1.8 4.3 4.2 4.3 1.9 0 3.1-1.2 3.5-1.9l-1.4-1c-.5.7-1.1 1.2-2.1 1.2s-1.6-.4-2.1-1.3l5.7-2.4-.2-.5zm-5.8 1.4c0-1.6 1.3-2.5 2.2-2.5.7 0 1.4.4 1.6.9l-3.8 1.6zM82.6 30h1.9V17.5h-1.9V30zm-3-7.3c-.5-.5-1.3-1-2.3-1-2.1 0-4.1 1.9-4.1 4.3s1.9 4.2 4.1 4.2c1 0 1.8-.5 2.2-1h.1v.6c0 1.6-.9 2.5-2.3 2.5-1.1 0-1.9-.8-2.1-1.5l-1.6.7c.5 1.1 1.7 2.5 3.8 2.5 2.2 0 4-1.3 4-4.4V22h-1.8v.7zm-2.2 5.9c-1.3 0-2.4-1.1-2.4-2.6s1.1-2.6 2.4-2.6c1.3 0 2.3 1.1 2.3 2.6s-1 2.6-2.3 2.6zm24.4-11.1h-4.5V30h1.9v-4.7h2.6c2.1 0 4.1-1.5 4.1-3.9s-2-3.9-4.1-3.9zm.1 6h-2.7v-4.3h2.7c1.4 0 2.2 1.2 2.2 2.1-.1 1.1-.9 2.2-2.2 2.2zm11.5-1.8c-1.4 0-2.8.6-3.3 1.9l1.7.7c.4-.7 1-.9 1.7-.9 1 0 1.9.6 2 1.6v.1c-.3-.2-1.1-.5-1.9-.5-1.8 0-3.6 1-3.6 2.8 0 1.7 1.5 2.8 3.1 2.8 1.3 0 1.9-.6 2.4-1.2h.1v1h1.8v-4.8c-.2-2.2-1.9-3.5-4-3.5zm-.2 6.9c-.6 0-1.5-.3-1.5-1.1 0-1 1.1-1.3 2-1.3.8 0 1.2.2 1.7.4-.2 1.2-1.2 2-2.2 2zm10.5-6.6l-2.1 5.4h-.1l-2.2-5.4h-2l3.3 7.6-1.9 4.2h1.9l5.1-11.8h-2zm-16.8 8h1.9V17.5h-1.9V30z"
              />
              <linearGradient
                id="a"
                x1="21.8"
                x2="5.017"
                y1="33.29"
                y2="16.508"
                gradientTransform="matrix(1 0 0 -1 0 42)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#00a0ff" />
                <stop offset=".007" stopColor="#00a1ff" />
                <stop offset=".26" stopColor="#00beff" />
                <stop offset=".512" stopColor="#00d2ff" />
                <stop offset=".76" stopColor="#00dfff" />
                <stop offset="1" stopColor="#00e3ff" />
              </linearGradient>
              <path
                fill="url(#a)"
                d="M10.4 7.5c-.3.3-.4.8-.4 1.4V31c0 .6.2 1.1.5 1.4l.1.1L23 20.1v-.2L10.4 7.5z"
              />
              <linearGradient
                id="b"
                x1="33.834"
                x2="9.637"
                y1="21.999"
                y2="21.999"
                gradientTransform="matrix(1 0 0 -1 0 42)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#ffe000" />
                <stop offset=".409" stopColor="#ffbd00" />
                <stop offset=".775" stopColor="orange" />
                <stop offset="1" stopColor="#ff9c00" />
              </linearGradient>
              <path
                fill="url(#b)"
                d="M27 24.3l-4.1-4.1V19.9l4.1-4.1.1.1 4.9 2.8c1.4.8 1.4 2.1 0 2.9l-5 2.7z"
              />
              <linearGradient
                id="c"
                x1="24.827"
                x2="2.069"
                y1="19.704"
                y2="-3.054"
                gradientTransform="matrix(1 0 0 -1 0 42)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#ff3a44" />
                <stop offset="1" stopColor="#c31162" />
              </linearGradient>
              <path
                fill="url(#c)"
                d="M27.1 24.2L22.9 20 10.4 32.5c.5.5 1.2.5 2.1.1l14.6-8.4"
              />
              <linearGradient
                id="d"
                x1="7.297"
                x2="17.46"
                y1="41.824"
                y2="31.661"
                gradientTransform="matrix(1 0 0 -1 0 42)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#32a071" />
                <stop offset=".069" stopColor="#2da771" />
                <stop offset=".476" stopColor="#15cf74" />
                <stop offset=".801" stopColor="#06e775" />
                <stop offset="1" stopColor="#00f076" />
              </linearGradient>
              <path
                fill="url(#d)"
                d="M27.1 15.8L12.5 7.5c-.9-.5-1.6-.4-2.1.1L22.9 20l4.2-4.2z"
              />
              <path
                d="M27 24.1l-14.5 8.2c-.8.5-1.5.4-2 0l-.1.1.1.1c.5.4 1.2.5 2 0L27 24.1z"
                enableBackground="new"
                opacity=".2"
              />
              <path
                d="M10.4 32.3c-.3-.3-.4-.8-.4-1.4v.1c0 .6.2 1.1.5 1.4v-.1h-.1zM32 21.3l-5 2.8.1.1 4.9-2.8c.7-.4 1-.9 1-1.4 0 .5-.4.9-1 1.3z"
                enableBackground="new"
                opacity=".12"
              />
              <path
                fill="#fff"
                d="M12.5 7.6L32 18.7c.6.4 1 .8 1 1.3 0-.5-.3-1-1-1.4L12.5 7.5c-1.4-.8-2.5-.2-2.5 1.4V9c0-1.5 1.1-2.2 2.5-1.4z"
                enableBackground="new"
                opacity=".25"
              />
            </svg>
          </Tooltip>
        </a>
        <a href="/" style={{ textDecoration: "underline" }}>
          Go To Website
        </a>
      </div>

      {/* bottomer */}
      <div className="flex justify-center">
        <div
          className="whenclick_bottom_tollPlaza width-for-toll-bottom-main  flex flex-col"
          style={{
            background: "none",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <div
            className="flex justify-between pl-3 pr-3"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            {/* vehicle number */}
            <div className="text-start">
              <span
                className="toll_bottom_para text-black"
                style={{ fontSize: "0.8rem" }}
              >
                Vehicle Registration Number
              </span>
              <h1 className=" text-black">{main.vehicle_no}</h1>
            </div>

            {/* start date */}
            <div className="text-start">
              <span
                className="toll_bottom_para text-black"
                style={{ fontSize: "0.8rem" }}
              >
                Start Date
              </span>
              <h1 className=" text-black">{main.start_date}</h1>
            </div>

            {/* end date */}
            <div className="text-start">
              <span
                className="toll_bottom_para text-black"
                style={{ fontSize: "0.8rem" }}
              >
                End Date
              </span>
              <h1 className=" text-black">{main.end_date}</h1>
            </div>

            {/* total distance */}
            <div className="text-start">
              <span
                className="toll_bottom_para text-black"
                style={{ fontSize: "0.8rem", fontWeight: "300" }}
              >
                Total Distance
              </span>
              <h1 className=" text-black">{main.total_distance}</h1>
            </div>
          </div>

          <div className="flex justify-between mt-2 pl-3 pr-3 pb-2">
            {/* source */}
            <div className="text-start w-[36.5%]">
              <span
                className="toll_bottom_para text-black"
                style={{ fontSize: "0.8rem" }}
              >
                From
              </span>
              <h1 className=" text-black">
                {main.source ? main.source[0].source : ""}
              </h1>
            </div>

            {/* destination */}
            <div className="text-start w-[36.5%]">
              <span
                className="toll_bottom_para text-black"
                style={{ fontSize: "0.8rem" }}
              >
                To
              </span>
              <h1 className="text-black">
                {main.destination ? main.destination[0].destination : ""}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <section
        className="track_my_goods"
        style={{ width: "73%", margin: "auto" }}
      >
        <div className="track_container">
          {/* main tracker section */}
          <section
            className="tracker_main_wrapper1"
            style={{ marginBottom: "3rem", width: "92%", margin: "auto" }}
          >
            <div className="tracker_main_container kam_gap">
              {apiData.map((elm, ind) => {
                return (
                  <div
                    className="tracker_row relative py-2"
                    style={{
                      marginTop: "0rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                  >
                    {/* icon */}
                    <div className={"w-[10%]"}>
                      {elm.color ? (
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: "50px", color: elm.color }}
                        >
                          location_on
                        </span>
                      ) : (
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: "50px" }}
                        >
                          location_on
                        </span>
                      )}
                    </div>

                    {/* plaza */}
                    <div className="city1 w-[20%]">
                      <h1 style={{ fontWeight: "500" }}>
                        {elm.match ? `${elm.findplza}*` : elm.findplza}
                      </h1>
                    </div>

                    <div
                      className="track_small_detail_wrapper"
                      style={{ width: "50%", overflow: "scroll" }}
                    >
                      <div
                        className="track_small_detail"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          "align-items": "center",
                          width: "26rem",
                        }}
                      >
                        {/* checkin date */}
                        <div
                          className="checkinDate"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "400",
                              marginTop: "0.4rem",
                            }}
                          >
                            City
                          </span>

                          <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                            {elm.city}
                          </b>
                        </div>

                        {/* checkin time */}
                        <div
                          className="checkinTime"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "400",
                              marginTop: "0.4rem",
                            }}
                          >
                            State
                          </span>

                          <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                            {elm.state}
                          </b>
                        </div>

                        {/* distance covered*/}
                        <div
                          className="timeLapse"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "400",
                              marginTop: "0.4rem",
                            }}
                          >
                            Distance Covered
                          </span>

                          <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                            {elm.distance}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </section>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <CSVLink
          filename={"my-file.csv"}
          style={{
            textDecoration: "none",
            display: "inline-block",
            background: "black",
            color: "white",
            borderRadius: "0.3rem",
            padding: "0.4rem 2rem",
            border: "none",
            cursor: "pointer",
          }}
          data={apiData}
          className={"download_scv"}
        >
          Download Csv
        </CSVLink>
      </div>
    </div>
  );
};

export default TollPlazaPublic;

import React, { useState, useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import SortIcon from "@mui/icons-material/Sort";
import { Box, Chip, Divider, Paper } from "@mui/material";
import Radio from "@mui/material/Radio";
import ls from "localstorage-slim";
import axios from "axios";
import { getVehicleListForUser, fullVehicleList, seachHistoryTrackData } from "../../apis/api";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";

const MobileHistoryTrack = () => {
  // nav reference...
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const buttonGetVehicle = useRef();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  let [isSortLastToFirst, setIsSortLastToFirst] = useState(true);

  // user is login or not data
  const iLogin = ls.get("iLogin");

  // last index of array

  let login_response_object, token_user, user_id;

  // if user login then it will be execute...
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }

  const [apiData, setApiData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const lastIndexOfMainDataArray = apiData.length - 1;
  const params = useParams();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // get history track...
  useEffect(() => {
    axios
      .get(`${seachHistoryTrackData}/${params.id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((response) => {
        console.log(response);
        setCompleteData(response.data.data);
        setApiData(response.data.data.more);
        console.log("----apiData----");
        console.log(apiData);
      })
      .catch((err) => {
        console.log("errorss");
        console.log(err);
      });

    axios
      .get(`${fullVehicleList}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setVehicleList(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // lastToFirst(e)
  function FirstToLast(e) {
    if (!isSortLastToFirst) {
      setApiData(apiData.reverse());
      setIsSortLastToFirst(true);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  // FirstToLast(e)
  function lastToFirst(e) {
    if (isSortLastToFirst) {
      setApiData(apiData.reverse());
      setIsSortLastToFirst(false);
    }
  }

  // getVehicleInformation(e)
  async function getVehicleInformation(e) {
    let shouldTermine = false;
    vehicleList.map((elm) => {
      if (elm.vin == completeData.vehicle_no) {
        shouldTermine = true;
        navigate("/ListOfVehicle");
      }
    });

    if (!shouldTermine) {
      navigate("/AddVehicle");
    }
  }

  let latestCsv = [];
  apiData.forEach((elm) => {
    console.log("elm====");
    console.log(elm);
    latestCsv.push({
      Toll: elm.plaza,
      Checking_Date: elm.readerReadTime.split(" ")[0],
      Checking_Time: elm.readerReadTime.split(" ")[1],
      City: elm.city,
      State: elm.state,
    });
  });

  return (
    <section className="px-2">
      {/* top */}
      <div className="flex justify-between items-center">
        {/* left start */}
        <div className="flex py-6">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <span className="material-symbols-outlined texl-sm">
              keyboard_backspace
            </span>
          </button>
          <div className="ml-2">
            <h1 className="text-xl font-Cardo font-bold">{completeData.vehicle_no}</h1>
            <p style={{ fontSize: "0.8rem", color: "grey", fontWeight: "400" }}>
              View Last 72 Hour Journey
            </p>
          </div>
        </div>
        {/* left end */}

        {/* right start */}
        <Button
          aria-describedby={id}
          style={{ color: "black" }}
          onClick={handleClick}
        >
          <SortIcon />
        </Button>
        <Popover
          id={id}
          open={open}
          style={{ marginTop: "1rem" }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Button
            onClick={(e) => {
              FirstToLast(e);
            }}
            style={{ color: "black", textTransform: "capitalize" }}
          >
            First To Last
          </Button>
          <br />
          <Button
            onClick={(e) => {
              lastToFirst(e);
            }}
            style={{ color: "black", textTransform: "capitalize" }}
          >
            Last To First
          </Button>
        </Popover>
        {/* right end */}
      </div>

      {/* divider */}
      <Divider />

      {/* track number */}
      <Chip label={completeData.track_no} className="mt-6" variant="outlined" />

      {/* main tracking list */}
      <section>
        {apiData.map((elm, ind) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 0.4,
                  width: "100%",
                },
              }}
            >
              <Paper
                style={{
                  display: "flex",
                  alignItems: "start",
                  paddingBottom: "0.5rem",
                }}
              >
                {/* icon */}
                <div>
                  <Radio color="success" checked />
                </div>

                {/* main start */}
                <div>
                  <h1 className="mt-2" style={{ fontWeight: "bold" }}>
                    {elm.plaza}
                  </h1>

                  <section>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                       Date -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.readerReadTime.slice(0, 10)}
                      </span>
                    </div>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        Time -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.readerReadTime.slice(11, 19)}
                      </span>
                    </div>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        State -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.state}
                      </span>
                    </div>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        city -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.city}
                      </span>
                    </div>
                  </section>
                </div>
                {/* main end */}
              </Paper>
            </Box>
          );
        })}
      </section>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <CSVLink
          className="text-sm inline-block m-auto"
          data={latestCsv}
          style={{
            background: "black",
            color: "white",
            padding: "0.5rem",
            borderRadius: "0.3rem",
          }}
          filename={`Tracking-History-Of ${completeData.vehicle_no}`}
        >
          Download
        </CSVLink>
      </div>
    </section>
  );
};

export default MobileHistoryTrack;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import FStep1 from "./pages/Auth/ForgotPassword/FStep1";
import FStep2 from "./pages/Auth/ForgotPassword/FStep2";
import FStep3 from "./pages/Auth/ForgotPassword/FStep3";
import FStep4 from "./pages/Auth/ForgotPassword/FStep4";
import HDFCFORM from "./pages/Plans/HDFCFORM";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import TagManager from "react-gtm-module";

// redux store and provider import...
import { store } from "./redux/app/store";
import { Provider } from "react-redux";

// import pages here...
import Login from "./pages/Auth/Login/Login";
import Register from "./pages/Auth/Register/Register";
import RStep2 from "./pages/Auth/Register/RStep2";
import RStep3 from "./pages/Auth/Register/RStep3";
import RStep4 from "./pages/Auth/Register/RStep4";
import RStep5 from "./pages/Auth/Register/RStep5";
import RStep6 from "./pages/Auth/Register/RStep6";
import RStep7 from "./pages/Auth/Register/RStep7";
import RStep8 from "./pages/Auth/Register/RStep8";
import RStep9 from "./pages/Auth/Register/RStep9";
import Home from "./pages/Home/Home";
import History from "./pages/History/History";
import JourneyPlanner from "./pages/journeyPlanner/JourneyPlanner";
import OnGoingJourney from "./pages/OnGoing/OnGoingJourney";
import PastJourney from "./pages/PastJourneys/PastJourney";
import Track from "./pages/TrackVehicle/Track";
import Share from "./components/assets/Share/Share";
import AddMyVehicle from "./pages/AddMyVehicle/AddMyVehicle";
import VehicleMake from "./pages/AddMyVehicle/VehicleMake";
import VehicleModel from "./pages/AddMyVehicle/VehicleModel";
import VehicleFuelType from "./pages/AddMyVehicle/VehicleFuelType";
import VehicleVelidity from "./pages/AddMyVehicle/VehicleVelidity";
import Plans from "./pages/Plans/Plans";
// import Plan2 from './pages/Plans/Plan2';
import VehicleList from "./pages/VehicleList/VehicleList";
import PremiumPlans from "./pages/Plans/PremiumPlans";
import Profile from "./pages/Profile/Profile";
import ContactDetail from "./pages/Profile/ContactDetail";
import Password from "./pages/Profile/Password";
import KYC from "./pages/Profile/KYC";
import Subscription from "./pages/Profile/Subscription";
import Company from "./pages/Profile/Company";
import Checkout from "./pages/Checkout/Checkout";
import CheckoutTwo from "./pages/Checkout/CheckoutTwo";
import Wallet from "./pages/Checkout/Wallet";
import WalletTwo from "./pages/Checkout/WalletTwo";
import About from "./pages/InfomativesPages/AboutUs";
import Faqs from "./pages/InfomativesPages/Faqs";
import ContactUs from "./pages/InfomativesPages/ContactUs";
import PrivatePolicy from "./pages/InfomativesPages/PrivatePolicy";
import TermsAndCondition from "./pages/InfomativesPages/TermsAndCondition";
import WhenClickTrack from "./pages/TrackVehicle/WhenClickTrack";
import TollPlazaPage from "./pages/TollPlazaPage/TollPlazaPage";
import RefundRequest from "./pages/Refunds/RefundRequest";
import RefundRequest2 from "./pages/Refunds/RefundRequest2";
import RefundRequest3 from "./pages/Refunds/RefundRequest3";
import BlogPage from "./pages/BlogPage/BlogPage";
import UpdateVehicle from "./pages/VehicleList/UpdateVehicle";
import UpdateOnGoing from "./pages/OnGoing/UpdateOnGoing";
import Notifications from "./pages/Notifications/Notification";
import RefundPolicy from "./pages/InfomativesPages/RefundPolicy";
import HistoryTrack from "./pages/TrackVehicle/HistoryTrack";
import TollPlazaPublic from "./pages/TollPlazaPage/TollPlazaPublic";
import MainPlan from "./pages/Plans/MainPlan";
import TransferCredits from "./pages/Checkout/TransferCredits";
import Invoice from "./pages/Checkout/Invoice";
import TollPlazaActive from "./pages/TollPlazaPage/TollPlazaActive";
import TrackPublic from "./pages/TrackVehicle/TrackPublic";
import PaymentSuccess from "./pages/Checkout/PaymentSuccess";
import ProfileTest from "./pages/Profile/ProfileTest";
import VehicleTest from "./pages/AddMyVehicle/VehicleTest";
import RegisterSingle from "./pages/Auth/Register";
import RedirectBack from "./pages/Checkout/RedirectBack";
import SuccessPay from "./pages/Plans/SuccessPay";
import NotSuccessfully from "./pages/Plans/NotSuccessfully";
import MobileHistoryTrack from "./pages/TrackVehicle/MobileHistoryTrack";
import MobileTrack_Public from "./pages/TrackVehicle/MobileTrack_Public";
import EWayBill from "./pages/EWayBill/EWayBill";
import WhenClickTrackEway from "./pages/TrackVehicle/WhenClickTrackEWay";
import TrackEway from "./pages/TrackVehicle/TrackEway";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/History",
    element: <History />,
  },
  {
    path: "/EWayBill",
    element: <EWayBill />,
  },
  {
    path: "/forgetPassword",
    element: <FStep1 />,
  },
  {
    path: "/FStep2",
    element: <FStep2 />,
  },
  {
    path: "/ResetPassword",
    element: <FStep3 />,
  },
  {
    path: "/resetSuccesfully",
    element: <FStep4 />,
  },
  {
    path: "/Register",
    element: <Register />,
  },
  {
    path: "/register-step-2",
    element: <RStep2 />,
  },

  {
    path: "/GetOtp",
    element: <RStep3 />,
  },
  {
    path: "/NumberVerification",
    element: <RStep4 />,
  },

  {
    path: "/mobileVerificationOtp",
    element: <RStep5 />,
  },

  {
    path: "/SignUp",
    element: <RStep6 />,
  },

  {
    path: "/CompanyDetail",
    element: <RStep7 />,
  },

  {
    path: "/KycDetail",
    element: <RStep8 />,
  },
  {
    path: "/PanVerify",
    element: <RStep9 />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  // {
  //   path: "/Track",
  //   element: (
  //     <Track />
  //   )
  // },
  {
    path: "/Track",
    element: <Track />,
  },

  {
    path: "/TrackEway",
    element: <TrackEway />,
  },
  {
    path: "/MyJourney",
    element: <JourneyPlanner />,
  },
  {
    path: "/MyJourney/:from",
    element: <JourneyPlanner />,
  },
  {
    path: "/OngoingJourneys",
    element: <OnGoingJourney />,
  },

  {
    path: "/PastJourneys",
    element: <PastJourney />,
  },
  {
    path: "/Share",
    element: <Share />,
  },

  {
    path: "/AddVehicle",
    element: <AddMyVehicle />,
  },

  {
    path: "/VehicleMake",
    element: <VehicleMake />,
  },

  {
    path: "/VehicleModel",
    element: <VehicleModel />,
  },

  {
    path: "/VehicleFuelType",
    element: <VehicleFuelType />,
  },

  {
    path: "/VehicleValidity",
    element: <VehicleVelidity />,
  },
  {
    path: "/Plans",
    element: <Plans />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/Continue",
    element: <CheckoutTwo />,
  },
  {
    path: "/Wallet",
    element: <Wallet />,
  },
  {
    path: "/WalletContinue",
    element: <WalletTwo />,
  },
  {
    path: "/ListOfVehicle",
    element: <VehicleList />,
  },
  {
    path: "/MonthlySubscription",
    element: <PremiumPlans />,
  },
  {
    path: "/PayPerUse",
    element: <Plans />,
  },

  {
    path: "/Profile",
    element: <Profile />,
  },
  {
    path: "/ContactDetail",
    element: <ContactDetail />,
  },
  {
    path: "/Password",
    element: <Password />,
  },
  {
    path: "/kyc",
    element: <KYC />,
  },
  {
    path: "/subscription",
    element: <Subscription />,
  },
  {
    path: "/company",
    element: <Company />,
  },
  {
    path: "/about-us",
    element: <About />,
  },
  {
    path: "/faqs",
    element: <Faqs />,
  },
  {
    path: "/private-policy",
    element: <PrivatePolicy />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndCondition />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/when-click-track",
    element: <WhenClickTrack />,
  },
  {
    path: "/when-click-track-EWay",
    element: <WhenClickTrackEway />,
  },
  {
    path: "/history-tracking/:id",
    element: <HistoryTrack />,
  },
  {
    path: "/toll-plaza",
    element: <TollPlazaPage />,
  },
  {
    path: "/refund-request",
    element: <RefundRequest />,
  },
  {
    path: "/refund-request2",
    element: <RefundRequest2 />,
  },
  {
    path: "/refund-request3",
    element: <RefundRequest3 />,
  },
  // {
  //   path: "/monthly-subscription",
  //   element: (
  //     <Plan2 />
  //   )
  // },
  {
    path: "/blog",
    element: <BlogPage />,
  },
  {
    path: "/refund-policy",
    element: <RefundPolicy />,
  },
  {
    path: "/update-vehicle",
    element: <UpdateVehicle />,
  },
  {
    path: "/update-ongoing-journey",
    element: <UpdateOnGoing />,
  },
  {
    path: "/notifications",
    element: <Notifications />,
  },
  {
    path: "/v1/onGoingJourney/:id/shared/:random",
    element: <TollPlazaPublic />,
  },
  {
    path: "/all-plans",
    element: <MainPlan />,
  },
  {
    path: "/transfer-credits",
    element: <TransferCredits />,
  },
  {
    path: "/invoice/:asdfg",
    element: <Invoice />,
  },
  {
    path: "/full-trip-list",
    element: <TollPlazaActive />,
  },
  {
    path: "/public-track",
    element: <TrackPublic />,
  },
  {
    path: "/v1/search-vehicle/:_id/shared/:random",
    element: <TrackPublic />,
  },
  {
    path: "/payment-success/:oi",
    element: <PaymentSuccess />,
  },
  {
    path: "/profile-main",
    element: <ProfileTest />,
  },
  {
    path: "/vehicle-add",
    element: <VehicleTest />,
  },
  {
    path: "/single-page-register",
    element: <RegisterSingle />,
  },
  {
    path: "/hdfc-form",
    element: <HDFCFORM />,
  },
  {
    path: "/successfully-done",
    element: <SuccessPay />,
  },
  {
    path: "/transaction-fail",
    element: <NotSuccessfully />,
  },
  {
    path: "/v2/search-vehicle/:_id/shared/:random",
    element: <MobileTrack_Public />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

const tagManagerArgs = {
  gtmId: "GTM-THK3PPF",
};

TagManager.initialize(tagManagerArgs);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

import React, { useEffect, useState } from "react";
import TopSection from "../../TopSection";
import CommonTable from "../../CommonTable";
import Filter2 from "../../components/layouts/Filter/Filter2";
import { CSVLink } from "react-csv";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import { useSelector, useDispatch } from 'react-redux';
import { getVehicleListForUser } from '../../apis/api';
import axios from 'axios';
import ls from 'localstorage-slim';
import Footer from "../../components/layouts/Footer/Footer";
import { Button } from "@mui/material";
import { vehicle_Head_Top2_Hn, vehicle_Sub_Top2_Hn, vehicle_table_data_Hn } from "../../components/translation/Hn";
import { vehicle_Head_Top2_En, vehicle_Sub_Top2_En, vehicle_table_data_En } from "../../components/translation/En";
// import { AddVehicleResponse } from '../../redux/slices/vehicleGetResponse';

const VehicleList = () => {
  const eng = useSelector((state) => state.translate.value);
  let tableData;
  let [apiData, setApiData] = useState([]);
  const iLogin = ls.get('iLogin');
  const [count, setCount] = useState(0);
  let login_response_object, token_user = " ", user_id = " ";
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }



  // get vehicle data...
  useEffect(() => {
    axios.get(`${getVehicleListForUser}/${user_id}/${count}`, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    }).then(response => {
      if (response.data.success) {
        console.log(response.data.data)
        setApiData(response.data.data.sendUser)
      }
    }).catch(err => {
      console.log(err)
    })

  }, [count])
  console.log("apiData" + JSON.stringify(apiData))
  // get shorted and filter data
  const shortedFilterData3 = useSelector((state) => state.vehicleTabledata.value);

  if (shortedFilterData3 === 0) {
    tableData = apiData;
  } else {
    tableData = shortedFilterData3;
  }

  // csvData
  const csvData = tableData;

  return (
    <>
      <div className="nav">
        <SecondNavBar active8={true} />
      </div>
      {/* top section Component */}
      <TopSection
        bigi={eng ? vehicle_Head_Top2_En : vehicle_Head_Top2_Hn}
        small={eng ? vehicle_Sub_Top2_En : vehicle_Sub_Top2_Hn}
      >
        <CSVLink
          style={{
            textDecoration: "none",
            display: "inline-block",
            background: "black",
            color: "white",
            borderRadius: "0.3rem",
            padding: "0.4rem 2rem",
            border: "none",
            cursor: "pointer",
          }}
          data={csvData}
          filename={"All-vehicles-list"}
        >
          Download All to CSV
        </CSVLink>

        <Filter2 tableData={tableData} />

      </TopSection>
      {/* top section Component END*/}

      {/* common table START */}
      <CommonTable
        type={"3"}
        tableHead={[
          "Vehicle Number",
          "Make",
          "Model",
          "Year",
          "Validity",
          ""
        ]}
        tableData={tableData}
        ids={"ongoing_ids"}
        defaultMessage={eng ? vehicle_table_data_En : vehicle_table_data_Hn}
        extraCss={'tracker_main_container control_gap'}
      />
      {/* common table END */}

      <div className="flex justify-center pb-8">
        <Button variant="contained" onClick={() => { setCount(count + 5) }} style={(apiData.length < 5) ? { "display": "none" } : { "background": "#232323" }}>Load More</Button>
      </div>

      <Footer />
    </>
  );
};

export default VehicleList;

import React, { useState } from "react";
import Dot from "../../components/assets/svg/icons/Dot";
import Line from "../../components/assets/svg/Line";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import TopSection from "../../TopSection";
import Tick from "../../components/assets/svg/Tick"
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowCircleRight } from "react-icons/md";
import ls from 'localstorage-slim';
import { vehiclePost } from '../../apis/api'
import axios from 'axios'
import Footer from "../../components/layouts/Footer/Footer";
import moment from 'moment'

function VehicleVelidity() {

  // all local states and variable declaration here START
  let vehical_make, vehical_model, vehical_iden_number, vehical_fuel;
  vehical_make = ls.get('vehical_make', { decrypt: true })
  vehical_model = ls.get('vehical_model', { decrypt: true })
  vehical_iden_number = ls.get('vehical_iden_number', { decrypt: true })
  vehical_fuel = ls.get('vehical_fuel', { decrypt: true })
  const validityData = ls.get("vehicle_validity", { decrypt: true });
  const iLogin = ls.get('iLogin');
  let login_response_object = "", token_user = "", user_id = "";
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }
  const nav = useNavigate();
  // all local states and variable declaration here END


  // this obj, user will post to backend for store in database START
  const [postData, setPostData] = useState({
    u_id: user_id,
    make: vehical_make,
    vin: vehical_iden_number,
    validity: "",
    type: vehical_fuel,
    model: vehical_model
  });


  // when user change validity date then it function will be execute
  function inputChange(e) {
    setPostData({ ...postData, [e.target.name]: e.target.value })
  }

  // when user click on next button then it function will be execute
  function okNext(e) {
    axios.post(vehiclePost, postData, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          window.alert("your vehicle has been added succesfully");
          let currentTime = moment().format("LLLL");
          let currentTime2 = moment().format("DD-MM-YYYY");
          let obj = {
            "name": "vehicleAddSuccess",
            "message": "your vehicle has been added succesfully",
            "time": currentTime,
            "forFilter": currentTime2
          }
          ls.set('vehicleAddSuccess', obj)
          nav('/ListOfVehicle')
          window.location.reload()
        }
      })
      .catch(function (error) {
        window.alert(error.response.data.message)
        console.log(error);
      });
  }

  return (
    <>
      <div>

        <div className="nav">
          <SecondNavBar />
        </div>

        {/* top section Component START*/}
        <TopSection
          bigi={"Add My Vehicle"}
          small={""}
        >
        </TopSection>
        {/* top section Component END*/}

        {/* MAIN SECTION START  */}
        <div className="parent-elem relative">
          <div className="header"></div>
          <div className="py-5">
            <div className="main-Box-Vehicle bg-[#fafafa] flex flex-col justify-center items-center ">
              <div style={{ "boxShadow": "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }} className="bg-white w-[90%] flex flex-col justify-center items-center Semi-Main-Box lg:w-[55%] gap-8 pb-4 rounded-md">
                <div className="h-2 bg-black w-full top-0 rounded-b-none rounded-t-md"></div>

                <div className="heading flex justify-between text-3xl font-Cardo font-bold lg:text-[40px] w-full px-8">
                  <h1>Let's Get to Know Your Vehicle!</h1>
                  <button onClick={(e) => { okNext(e) }} className={"text-sm flex justify-center items-center"}><p className="underline">Skip for now</p><svg className="ml-2" width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.992188 1.32591L4.52135 4.83991L0.992188 8.35449" stroke="black" strokeOpacity="0.8" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  </button>
                </div>
                <div className="second-Section flex flex-col gap-7 w-[85%]">
                  <div className=" text-base font-Cardo font-normal lg:text-xl ">
                    <p>What's your vehicle RTO validity?</p>
                  </div>
                  <div className="inputItem">
                    <input onChange={(e) => { inputChange(e) }}
                      type={"date"}
                      className=" border-none border-black rounded-md w-[100%] p-1 pl-2"
                      placeholder={"Example 11/04/2029"}
                      name={"validity"}
                      style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                    />
                  </div>
                  <div className="flex gap-4">
                    <div className="flex bg-black text-white cursor-pointer rounded-md px-10 py-1" onClick={(e) => { iLogin ? okNext(e) : window.alert("please login first") }}>
                      <div className="font-Cardo font-normal text-xl pr-3">
                        Ok
                      </div>
                      <div className="flex justify-center items-center">
                        <Tick />
                      </div>
                    </div>
                    <div className="flex enter_wrpper">
                      <div className="flex justify-center items-center font-Inter font-light text-sm">
                        Press Enter
                      </div>
                      <div className="flex justify-center items-center">
                        <MdOutlineArrowCircleRight />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%] mb-5 flex flex-col items-center justify-center" >
          <div className="pt-3 hidden h-[15vh] lg:flex justify-center items-center w-[55%] rounded flex-col md:visible" style={{ "boxShadow": "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", "background": "white" }}>
            <div className="line relative">
              <Line />
            </div>

            <div className="flex relative w-full justify-between pr-16 pl-20 bottom-[0.7rem]">
              <div className=" flex flex-col items-center">
                <Dot />
                <span className="material-symbols-outlined absolute top-0 ">
                  check
                </span>
                <p className="text-black mt-2">Step 1</p>
              </div>
              <div className=" flex flex-col items-center ">
                <Dot />
                <span className="material-symbols-outlined absolute top-0 ">
                  check
                </span>
                <p className="text-black mt-2">Step 2</p>
              </div>
              <div className=" flex flex-col items-center ">
                <Dot />
                <span className="material-symbols-outlined absolute top-0 ">
                  check
                </span>
                <p className="text-black mt-2">Step 3</p>
              </div>
              <div className=" flex flex-col items-center ">
                <Dot />
                <span className="material-symbols-outlined absolute top-0 ">
                  check
                </span>
                <p className="text-black mt-2">Step 4</p>
              </div>
              <div className=" flex flex-col items-center">
                <Dot />
                <p className="text-black mt-2">Step 5</p>
              </div>

            </div>
          </div>
        </div>
        {/* MAIN SECTION END */}
      </div>
      <Footer />
    </>
  );
}

export default VehicleVelidity;

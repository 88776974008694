import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import SortIcon from "@mui/icons-material/Sort";
import { Box, Chip, Divider, Paper } from "@mui/material";
import Radio from "@mui/material/Radio";
import ls from "localstorage-slim";
import axios from "axios";
import { getVehicleListForUser, fullVehicleList } from "../../apis/api";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";

const MobileTrack = () => {
  // copy from track page Start
  let [apiData, setApiData] = useState([]);
  let [isSortLastToFirst, setIsSortLastToFirst] = useState(true);

  const login_response_object = ls.get("login_data_object", { decrypt: true });
  const token_user = login_response_object.token;
  const user_id = login_response_object.user._id;
  const nav = useNavigate();
  useEffect(() => {
    axios.get(`${fullVehicleList}/${user_id}`, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    }).then(response => {
      if (response.data.success) {
        setApiData(response.data.data)
        console.log("divine")
      }

    }).catch(err => {
      console.log(err)
    })
  }, []);
  let trackVehicleData = useSelector((state) => state.vehicleTrackData.value);
  console.log("trackVehicleData", trackVehicleData);
  let trackVehicleDatafromLocalStorage = ls.get("trackVehicleData", {
    decrypt: true,
  });
  const [isSubscribe, setIsSubscribe] = useState(true);
  const [isSubscribeShow, setIsSubscribeShow] = useState(false);
  // close sub popup
  function closeSubPop() {
    setIsSubscribeShow(false);
  }

  // buySubscribePopup
  function buySubscribePopup() {
    setIsSubscribeShow(true);
  }
  const track_vehicle_no = ls.get("track_vehicle_no", { decrypt: true });
  if (trackVehicleData == "0") {
    trackVehicleData = trackVehicleDatafromLocalStorage;
  }
  // let mainDataArray = trackVehicleData.txn;
  let [mainDataArray, setMainDataArray] = useState(trackVehicleData.txn);
  console.log("trackVehicleData.txn", trackVehicleData.txn);
  let vehical_number = trackVehicleData.vehicle_no;
  const trackinNumber = trackVehicleData.tracking_no;
  let averageSpeed, averageDistance;
  averageSpeed = trackVehicleDatafromLocalStorage.speed;
  averageDistance = trackVehicleDatafromLocalStorage.distance;

  // const lastIndexOfMainDataArray = mainDataArray.length - 1;
  const lastIndexOfMainDataArray = mainDataArray ? mainDataArray.length - 1 : -1;

// Now, lastIndexOfMainDataArray will be -1 if mainDataArray is null or undefined

  let mainDataArra;

  // lastToFirst(e)
  function FirstToLast(e) {
    if (!isSortLastToFirst) {
      let newMainDataArray = JSON.parse(JSON.stringify(mainDataArray));
      mainDataArra = newMainDataArray.reverse();
      console.log(mainDataArra);
      setIsSortLastToFirst(true);
      setMainDataArray(mainDataArra);
    }
  }

  // FirstToLast(e)
  function lastToFirst(e) {
    if (isSortLastToFirst) {
      let newMainDataArray = JSON.parse(JSON.stringify(mainDataArray));
      mainDataArra = newMainDataArray.reverse();
      console.log(mainDataArra);
      setIsSortLastToFirst(false);
      setMainDataArray(mainDataArra);
    }
  }

  let tableData = [
    {
      date: "2019-06-01",
      v_no: "gj 18 AA 0998",
      source: "cali",
      destination: "mumbai",
    },
    {
      date: "2019-06-02",
      v_no: "agj 18 AA 0998",
      source: "zali",
      destination: "mumbai",
    },
    {
      date: "2019-07-01",
      v_no: "bgj 18 AA 0998",
      source: "aali",
      destination: "apali",
    },
    {
      date: "2018-07-01",
      v_no: "gj 18 AA 0998",
      source: "pali",
      destination: "mumbai",
    },
    {
      date: "2018-07-01",
      v_no: "gj 18 AA 0998",
      source: "pali",
      destination: "mumbai",
    },
    {
      date: "2022-07-01",
      v_no: "gj 18 AA 0998",
      source: "pali",
      destination: "mumbai",
    },
  ];
  const csvData = tableData;
  // copy from track page end

  // getVehicleInformation(e)
  async function getVehicleInformation(e) {
    let shouldTermine = false;
    if (isSubscribe) {
      apiData.map((elm) => {
        if (elm.vin == vehical_number) {
          shouldTermine = true;
          nav("/ListOfVehicle");
        }
      });

      if (!shouldTermine) {
        nav("/AddVehicle");
      }
    } else {
      buySubscribePopup();
    }
  }

  // customizing csvs
  let latestCsv = [];
  if (mainDataArray && Array.isArray(mainDataArray)) {
    mainDataArray.forEach((elm) => {
      latestCsv.push({
        PlazaName: elm.plaza,
        Date: elm.readerReadTime.slice(0, 11),
        Time: elm.readerReadTime.slice(11, 16),
        City: elm.city,
        State: elm.state,
      });
    });
  } else {
    // Handle the case when mainDataArray is null or not an array
    console.error("mainDataArray is null or not an array");
  }
  

  // dropdwon start setting
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // dropdwon end setting

  const navigate = useNavigate();
  return (

    <section className="px-2">
      {/* top */}
      <div className="flex justify-between items-center">
        {/* left start */}
        <div className="flex py-6">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <span className="material-symbols-outlined texl-sm">
              keyboard_backspace
            </span>
          </button>
          <div className="ml-2">
            <h1 className="text-xl font-Cardo font-bold">{vehical_number}</h1>
            <p style={{ fontSize: "0.8rem", color: "grey", fontWeight: "400" }}>
              View Last 72 Hour Journey
            </p>
          </div>
        </div>
        {/* left end */}

        {/* right start */}
        <Button
          aria-describedby={id}
          style={{ color: "black" }}
          onClick={handleClick}
        >
          <SortIcon />
        </Button>
        <Popover
          id={id}
          open={open}
          style={{ marginTop: "1rem" }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Button
            onClick={(e) => {
              FirstToLast(e);
            }}
            style={{ color: "black", textTransform: "capitalize" }}
          >
            First To Last
          </Button>
          <br />
          <Button
            onClick={(e) => {
              lastToFirst(e);
            }}
            style={{ color: "black", textTransform: "capitalize" }}
          >
            Last To First
          </Button>
        </Popover>
        {/* right end */}
      </div>

      {/* divider */}
      <Divider />

      {/* track number */}
      <Chip label={trackinNumber} className="mt-6" variant="outlined" />

      {/* main tracking list */}
      <section>
        {mainDataArray.map((elm, ind) => {
          console.log("elm track",mainDataArray);
          return (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 0.4,
                  width: "100%",
                },
              }}
            >
              <Paper
                style={{
                  display: "flex",
                  alignItems: "start",
                  paddingBottom: "0.5rem",
                }}
              >
                {/* icon */}
                <div>
                  <Radio color="success" checked />
                </div>

                {/* main start */}
                <div>
                  <h1 className="mt-2" style={{ fontWeight: "bold" }}>
                    {elm.plaza}
                  </h1>

                  <section>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        Checking Date -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.readerReadTime.slice(0, 10)}
                      </span>
                    </div>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        Checking Time -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.readerReadTime.slice(11, 19)}
                      </span>
                    </div>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        State -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.state}
                      </span>
                    </div>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        City -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.city}
                      </span>
                    </div>
                  </section>
                </div>
                {/* main end */}
              </Paper>
            </Box>
          );
        })}
      </section>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <CSVLink
          className="text-sm inline-block m-auto"
          data={latestCsv}
          style={{
            background: "black",
            color: "white",
            padding: "0.5rem",
            borderRadius: "0.3rem",
          }}
          filename={`Tracking-History-Of ${vehical_number}`}
        >
          Download
        </CSVLink>
      </div>
    </section>
  );
};

export default MobileTrack;

import React from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import HeadingWithText from "../../components/final_components/HeadingWithText/HeadingWithText";
import Footer from "../../components/layouts/Footer/Footer";

const RefundPolicy = () => {
  return (
    <>
      {/* navbar START*/}
      <SecondNavBar translationHide={"true"} />
      {/* navbar END */}

      {/* top section Component */}
      <TopSection bigi={"Refund Policy"} small={""}></TopSection>
      {/* top section Component END*/}

      <div className="my-1">
        {/* about us Main Card START */}
        <Card>
          <HeadingWithText
            head={"Return"}
            desc={[
              //   "Once the Product is received to the company successfully, ONEWHISTLE MARKETPLACE PVT.LTD. will instantly initiate the refund to your source account or chosen method of refund.",
              "Thank you for using LORRYCARE. We strive to provide an excellent experience with our credit-based services.",
              "Please be aware that all purchases and transactions involving credits within our web app are final. We do not offer returns, refunds, or exchanges for credits once they have been purchased or used.",
              "This policy helps us ensure fair usage and maintain the integrity of our services. We encourage you to thoroughly review your transactions before completing any purchase of credits. If you have any questions or need further assistance, our customer support team is available to help. You can reach us at support@lorrycare.com.",
              "We appreciate your understanding and cooperation.",
              "Sincerely,",
              "support@lorrycare.com",
              "LORRYCARE",
            ]}
          />
          {/* <HeadingWithText head={"Effective 1 April, 2023"} desc={["We value the trust you place in us. That's why we insist upon the highest standards for secure transactions and customer information privacy. Please read the following statement to learn about our information-gathering and dissemination practices.Note: Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically. By visiting this Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our Website. By mere use of the Website, you expressly consent to our use and disclosure of your personal information following this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use."]} /> */}
          <HeadingWithText
            head={"Refund"}
            desc={[
              "Thank you for choosing LORRYCARE. We are dedicated to providing you with high-quality services and an exceptional user experience.",
              "Please be aware that we have a no refund policy for all purchases of credits within our app. This policy applies to all users and ensures the integrity and sustainability of our services.",
              "<strong id='boldId'>Key Points of Our No Refund Policy:</stronjg>",
              "Final Sale: All purchases of credits are final and non-refundable. Once the purchase is complete, the credits will be added to your account and cannot be returned.",
              "Usage of Credits: We encourage you to carefully consider your credit purchases and usage. Our team is available to provide any necessary information or support to help you make informed decisions.",
              "Account and Credits Management: Please ensure that you manage your account and credits responsibly. We recommend reviewing your needs and plans before making a purchase.",
              "If you have any questions or require assistance, our customer support team is here to help. Please contact us at support@lorrycare.com, and we will be happy to assist you.",
              "Thank you for your understanding and continued support.",
              "Sincerely,",
              "support@lorrycare.com",
              "LORRYCARE",
            ]}
          />

          <HeadingWithText
            head={"Refund and Cancellation for Service Provider Company"}
            desc={[
              "Due to service providers in nature “NO REFUND”,“NO CANCELLATION”  will be entertained once the Payment has been made.",
            ]}
          />

          <HeadingWithText
            head={"Cancellation Policy"}
            desc={[
              "Thank you for choosing LORRYCARE. We are dedicated to providing you with a seamless and efficient experience.",
              "Please be aware that once credits are purchased and applied to your account, they are non-refundable and cannot be canceled. This policy ensures the integrity of our service and allows us to maintain a fair and consistent experience for all users.",
              "<strong id='boldId'>Key Points of Our No Cancellation Policy:</strong>",
              "Finality of Transactions: All credit purchases are final. Once the transaction is completed, credits cannot be canceled, refunded, or exchanged.",
              "Usage Commitment: By purchasing credits, you acknowledge and agree to use them within the scope of our platform's services.",
              "Informed Purchase: We encourage you to review your needs carefully before purchasing credits. If you have any questions or need assistance in making a purchase decision, our customer support team is available to help.",
              "For any inquiries or further clarification, please contact our customer support team at support@lorrycare.com. We are here to assist you and ensure you have the best possible experience with LORRYCARE.",
              "Thank you for your understanding and cooperation.",
              "Sincerely,",
              "support@lorrycare.com",
              "LORRYCARE",
            ]}
          />

          <HeadingWithText
            head={"Shipping & Delivery Policies"}
            desc={[
              "Thank you for choosing LORRYCARE. We are dedicated to providing you with a seamless and efficient experience using our credit-based web application.",
              "Please be advised that as a digital service, LORRYCARE does not involve any physical products that require shipping or delivery. All services and credits are provided electronically and are available immediately upon successful completion of your purchase or transaction.",
              "<strong id='boldId'>Key Points:</strong>",
              "All credits and services are delivered electronically within the platform.",
              "Upon purchase, your credits are instantly added to your account, ready for use without any waiting time or physical delivery.",
              "Since our services are entirely digital, there are no shipping fees, delivery times, or physical handling involved.",
              "For any questions or further assistance, please contact our customer support team at support@lorrycare.com. We are here to help you make the most out of LORRYCARE.",
              "Thank you for your understanding and continued support.",
              "Sincerely,",
              "support@lorrycare.com",
              "LORRYCARE",
            ]}
            boldId="boldId"
          />

          <HeadingWithText
            head={"Contact Us"}
            desc={[
              "ONEWHISTLE MARKETPLACE PVT.LTD.",
              "A/514, Joyos Hubtown,Geeta Mandir,Ahmedabad",
              "9677348824",
              "support@lorrycare.com",
            ]}
          />
          {/* <HeadingWithText
            head={"Cancellation and Refunds"}
            desc={[
              "There shall be no cancellation of payments made via online payment (except for payments through Cheque/ Demand Draft). Cancellation requests for orders placed via Cheque/ Demand Draft can be made only before such payment is realized by lorrycare.com. In case lorrycare.com receives a payment for a failed transaction, lorrycare.com shall refund the same. However, lorrycare.com offers no guarantees whatsoever for the accuracy or timeliness of the refunds reaching the Customer's card/bank accounts. Such refunds involve multiple organizations over which lorrycare.com does not have any control; lorrycare.com shall reserve the exclusive right to cancel any content whatsoever from being published or reflected on its website or in any other mode. The payment of refunds for this shall be at the sole discretion of lorrycare.com. Lorrycare.com is not obliged to make any refund under whatsoever circumstances.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"Maintenance and System Failures"}
            desc={[
              "While lorrycare.com shall take all reasonable efforts for data backup and business resumption, you will be solely responsible for retaining backup copies of all information, photographs, and other materials furnished/submitted by/to www.lorrycare.com. Lorrycare may also suspend the operation of lorrycare.com for support or technical upgradation, or maintenance work, to update the content, or for any other reason. Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authorities.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"Disclaimer"}
            desc={[
              "Lorrycare.com disclaims all warranties, express or implied, including, but not limited to, the warranties of merchantability, fitness for a particular purpose, non-infringement, title, custom, trade, quiet enjoyment, system integration, and freedom from computer virus. Lorrycare.com does not represent or warrant that the functions contained in this website will be error-free or uninterrupted, that defects will be corrected, or that the website or the server that makes the website available is free from any harmful components including viruses. Lorrycare.com does not make any representations or warranties that the information on this website is accurate, complete, correct, adequate, useful, timely, reliable, or otherwise. You acknowledge, by your use of this website, that your use is at your sole risk.",
            ]}
          /> */}
          {/* <HeadingWithText
            isMargin={true}
            head={"Grievance Officer"}
            desc={[
              "If there are any questions regarding this privacy policy you may contact us using the information below or drop us a mail with the subject line-PRIVACY POLICY:Address: A-514, Joyos Hubtown, S. T. Bus Stand, Geeta Mandir Road, Raipur Darwaza, Ahmedabad – 380002 Email: info@lorrycare.com ",
            ]}
          /> */}
        </Card>
        {/* about us Main Card END */}
      </div>
      <Footer />
    </>
  );
};

export default RefundPolicy;

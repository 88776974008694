import React, { useState } from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import Footer from "../../components/layouts/Footer/Footer";
import { Button } from "@mui/material";
import { transferCredits } from "../../apis/api";
import axios from 'axios'
import ls from 'localstorage-slim'
import { TRANSFER_CREDITS_Top_Hn, TRANSFER_CREDITS_Sub_Top_Hn, TRANSFER_CREDITS_line_Hn } from "../../components/translation/Hn";
import { TRANSFER_CREDITS_Top_En, TRANSFER_CREDITS_Sub_Top_En, TRANSFER_CREDITS_line_En } from "../../components/translation/En";
import { useSelector } from "react-redux";
import moment from 'moment'
import { CircularProgress } from "@mui/material";

const TransferCredits = () => {
  const eng = useSelector((state) => state.translate.value);
  let login_response_object = "", token_user = "", user_id = "";
  const iLogin = ls.get('iLogin');
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }
  const [postData, setPostData] = useState({
    "email": "",
    "credits": ""
  });
  const [isLoader, setIsLoader] = useState(false);

  // changeHandle(e){}
  function changeHandle(e) {
    setPostData({ ...postData, [e.target.name]: e.target.value })
    console.log(postData)
  }

  //transferCredits()
  async function transferCreditsss(e) {
    setIsLoader(true)
    await axios.post(`${transferCredits}/${user_id}`, postData, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    }).then(response => {
      if (response.data.success) {
        setIsLoader(false)
        let currentTime = moment().format("LLLL");
        let currentTime2 = moment().format("DD-MM-YYYY");
        let obj = {
          "name": "transferCred",
          "message": "Credits transferred succesfully",
          "time": currentTime,
          "forFilter": currentTime2
        }

        ls.set('transferCred', obj)
        window.alert("Credits transferred succesfully");
        window.location.reload();
      }
    }).catch(err => {
      setIsLoader(false)
      window.alert(err.response.data.message);
    })
  }

  return (
    <>

      {/* navbar START*/}
      <SecondNavBar />
      {/* navbar END */}

      {/* top section Component */}
      <TopSection
        bigi={eng ? TRANSFER_CREDITS_Top_En : TRANSFER_CREDITS_Top_Hn}
        small={eng ? TRANSFER_CREDITS_Sub_Top_En : TRANSFER_CREDITS_Sub_Top_Hn}
      >
      </TopSection>
      {/* top section Component END*/}

      <div className="my-1">
        {/* about us Main Card START */}
        <Card>

          {/* unique_ids_wrapper */}
          <div className="unique_ids_wrapper mt-5 px-8">
            <label htmlFor="unique" className=" font-Inter text-xl font-normal">Mobile</label>
            <p style={{ "fontSize": "0.9rem" }}>{eng ? TRANSFER_CREDITS_line_En : TRANSFER_CREDITS_line_Hn}</p>
            <input type="text" onChange={(e) => { changeHandle(e) }} placeholder="Example 7230842788" className="w-[100%] mt-2 rounded border-none" style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }} id="unique" name="email" />
          </div>

          {/* unique_ids_wrapper */}
          <div className="unique_ids_wrapper mt-5 px-8">
            <label htmlFor="credits" className="font-Inter text-xl font-normal">Credits</label>
            <input type={"number"} onChange={(e) => { changeHandle(e) }} placeholder="eg- 22" className="w-[100%] mt-2 rounded border-none" style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }} id="credits" name="credits" />
          </div>

          <div className="px-8 mt-5">
            <Button style={{ "background": "#232323", "color": "white", "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }} onClick={(e) => { transferCreditsss(e) }}>Transfer</Button>
          </div>



        </Card>
        {/* about us Main Card END */}
      </div>

      <Footer />

      {/* loader */}
      <section style={isLoader ? { "display": "flex", } : { "display": "none" }} id="home_loader" className="absolute top-0 left-0 w-[100%] h-[100%] bg-white opacity-50 flex justify-center items-center">
        <CircularProgress />
      </section>
    </>
  );
};

export default TransferCredits;

import React, { useState } from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { updateVehicle } from '../../apis/api'
import axios from 'axios';
import ls from 'localstorage-slim'
import { useNavigate } from "react-router-dom";
import Footer from "../../components/layouts/Footer/Footer";

const UpdateVehicle = () => {

    // nav power
    const nav = useNavigate();

    // get data from cookie
    var vehicleNumber = getCookie("vehicle0");
    var vehicleID = getCookie("vehical_id");
    var makeBy = getCookie("vehicle1");
    var fuelType = getCookie("vehicle2");
    var model = getCookie("vehicle3");
    var validity = getCookie("vehicle4");

    const login_response_object = ls.get("login_data_object", { decrypt: true });
    const token_user = login_response_object.token;

    // local states
    const [inputdata, setInputData] = useState({
        "vin": vehicleNumber,
        "make": makeBy,
        "type": fuelType,
        "model": model,
        "validity": validity
    })

    // get cookie data function
    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }



    // changeInput(e) onchange function
    function changeInput(e) {
        setInputData({ ...inputdata, [e.target.name]: e.target.value });
        console.log(inputdata);
    }

    // update function
    function updateFunction() {
        axios.put(`${updateVehicle}/${vehicleID}`, inputdata, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        })
            .then(function (response) {
                if (response.data.success) {
                    window.alert("data has updated successfully")
                    nav("/ListOfVehicle");
                }
                console.log(response);
            })
            .catch(function (error) {
                window.alert(error);
                console.log(error);
            });
    }

    return (
        <>

            {/* navbar START*/}
            <SecondNavBar />
            {/* navbar END */}

            {/* top section Component */}
            <TopSection
                bigi={"Update Vehicle"}
                small={""}
            >
            </TopSection>
            {/* top section Component END*/}

            {/* form wrapper */}
            <section className="form-wrapper m-auto pb-10" style={{ "width": "73.5%" }}>
                <form className="mt-10">

                    {/* vehicle number */}
                    <div className="mt-2" style={{ "width": "100%" }}>
                        <TextField id="vehicle-number" label={"vehicle number"} variant="filled" style={{ "width": "100%" }} name={"vin"} value={inputdata.vin} onChange={(e) => { changeInput(e) }} />
                    </div>

                    {/* make  */}
                    <div className="mt-2" style={{ "width": "100%" }}>
                        <TextField id="make-by" label={"make"} variant="filled" style={{ "width": "100%" }} name={"make"} value={inputdata.make} onChange={(e) => { changeInput(e) }} />
                    </div>

                    {/* model */}
                    <div className="mt-2" style={{ "width": "100%" }}>
                        <TextField id="model" label={"model"} variant="filled" style={{ "width": "100%" }} name={"model"} value={inputdata.model} onChange={(e) => { changeInput(e) }} />
                    </div>

                    {/* year */}
                    <div className="mt-2" style={{ "width": "100%" }}>
                        <TextField id="fuel-type" label={"year"} type={"number"} variant="filled" style={{ "width": "100%" }} name={"type"} value={inputdata.type} onChange={(e) => { changeInput(e) }} />
                    </div>

                    {/* validity */}
                    <div className="mt-2" style={{ "width": "100%" }}>
                        <TextField type={"date"} label={"validity"} id="validity" variant="filled" style={{ "width": "100%" }} name={"validity"} value={inputdata.validity} onChange={(e) => { changeInput(e) }} />
                    </div>

                    {/* button */}
                    <div className="mt-5">
                        <Button className="w-[100%]" variant="contained" onClick={(e) => { updateFunction() }}>Update</Button>
                    </div>

                </form>
            </section>

            <Footer />
        </>
    );
};

export default UpdateVehicle;

import React, { useState, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import { Button } from "@mui/material";
import ls from 'localstorage-slim'
import { sendRegisterEmailForVarification } from "../../../apis/api";

const Register = () => {

  const nav = useNavigate();


  // iniatize state variables...
  const [inputData, setInputData] = useState({
    FName: "",
    LName: "",
    email: "",
  });

  const [emailObj, setEmailObj] = useState({
    email: "",
  });

  // 
  function onlyEmailChange(e) {
    setEmailObj({ ...emailObj, [e.target.name]: e.target.value });
  }


  // when user user will input data in input box given function will be run...
  function inputChange(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
    console.log(inputData);
  }

  // when click on continue button...
  function continueRegister(e) {


    

    if (inputData.FName == "" || inputData.LName == "" || inputData.email == "") {
      window.alert("all field are required");
    } else {

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputData.email)) {

        // axios for post email to backend START
        axios.post(sendRegisterEmailForVarification, emailObj)
          .then(function (response) {
            if (response.data.success) {
              console.log(response)
              ls.set('emailOfRegisterFirstStep', emailObj);
              ls.set("emailOTPId", response.data.data.id, { encrypt: true })
              ls.set('registerDataStep1', JSON.stringify(inputData), { encrypt: true });
              nav('/GetOtp');
            }
            console.log(response);
          })
          .catch(function (error) {
            window.alert(error.response.data.message);
          });
        //  axios post END

      }else{
        window.alert("You have entered an invalid email address!")
      }



    }
  }



  return (
    <div className="flex items-center w-full h-[100vh] justify-center border ">
      <Row className="py-8">
        <div className="px-4 md:px-32 lg:px-36 flex items-center flex-col justify-center h-[30rem]">

          <div className="flex flex-row-reverse justify-between items-center mb-5 w-[80%]">

            <div className="flex items-center">

              <img src="logogo.png" alt="logo_img" style={{ "width": "6rem" }} />
            </div>

            <div className="flex items-center">
              <button className="pt-2" onClick={() => { nav(-1) }}><span className="material-symbols-outlined">
                keyboard_backspace
              </span></button>
              <div>
                <MainHeading HeadinName="Signup to LorryCare" />
                <span className="text-xs">Sign up / Register for FREE</span>
              </div>
            </div>

          </div>

          <div className="w-[80%]">

            {/* first name*/}
            <div className="w-[100%]">
              <label htmlFor="">First Name</label>
              <Input
                label="First Name"
                type="text"
                name="FName"
                placeholder="Example Alex"
                onChange={(e) => { inputChange(e) }}
              />
            </div>

            {/* last name*/}
            <div className="pass w-[100%] relative mt-3">
              <label htmlFor="">Last Name</label>

              <Input
                label="Last Name"
                onChange={(e) => {
                  inputChange(e);
                }}
                type="text"
                name="LName"
                placeholder="Example Volkov"


              />
              {/* input field of company_name END */}
            </div>

            {/*email*/}
            <div className="pass w-[100%] relative mt-3">
              <label htmlFor="">Email</label>
              <Input
                label="Email"
                onChange={(e) => {
                  inputChange(e);
                  onlyEmailChange(e);
                }}
                type="email"
                name="email"
                placeholder="Example example@gmail.com"


              />
              {/* input field of company_name END */}
            </div>

            <div className="w-full mt-5">
              <Button variant="contained" className="w-[100%]" style={{ "background": "#232323" }} onClick={(e) => { continueRegister(e) }}>
                Continue
              </Button>
              <div className="text-sm mt-2 text-center flex justify-between w-[100%]"><Link className="underline" to={"/login"}>Have an account?</Link><Link className="underline" to={"/forgetPassword"}>Forgot password?</Link></div>
              <span className="text-xs text-center mt-2 inline-block w-[100%]">By clicking on Continue, you accept and agree to our <Link className="underline" to={"/terms-and-conditions"}>"term of service"</Link> and <Link className="underline" to={"/private-policy"}>"privacy policy"</Link></span>
            </div>
          </div>

        </div>
      </Row>
    </div>
  );
};
const Row = styled.div`
  
`;
const Input = styled.input`
  margin-top: 0.75rem;
  width: 100%;
  padding: 0.75rem 2.25rem 0.75rem 1rem;
  border-radius: 5px;
  border: none;
  outline:none !important;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  font-family: "Inter", sans-serif;
`;
export default Register;

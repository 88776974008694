import React from "react";
import { Link } from "react-router-dom";

function PlansHeader(props) {
  return (
    <div>
      <div className="heading flex justify-between pt-4">
        <div>
          <div className="font-Cardo font-semibold text-4xl">Plans</div>
          <p className="font-Inter text-xs font-normal text-black">
            Porem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
        <div className="flex gap-2 justify-center items-center cursor-pointer">
          <div className="Btn">
            <Link to="/PayPerUse">
              <div
                className="px-12 rounded-md py-1 font-Cardo font-normal text-base text-center"
                style={props.style}
              >
                Pay Per Use
              </div>
            </Link>
          </div>
          <Link to="/MonthlySubscription">
            <div className="Btn">
              <div
                className="px-5 rounded-md py-1 font-Cardo font-normal text-base"
                style={props.secondBtnStyle}
              >
                Monthly Subscription{" "}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PlansHeader;

import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import SecondNavbar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import { vehiclePost } from '../../apis/api';
import ls from 'localstorage-slim'
import axios from 'axios'
import TopSection from '../../TopSection'
import Footer from "../../components/layouts/Footer/Footer";
import { Button } from "@mui/material";
import moment from "moment";
import { vehicle_Head_Top1_En,vehicle_Sub_Top2_En,sub__heading_add_veh } from '../../components/translation/En'
import { vehicle_Head_Top1_Hn,sub__heading_add_veh_hn } from '../../components/translation/Hn'
import {useSelector} from 'react-redux'

function VehicleTest() {
    const eng = useSelector((state) => state.translate.value);
    const nav = useNavigate();
    const vehicleADD = useRef();
    const iLogin = ls.get('iLogin');
    const [AddVehicleForm, setAddVehicleForm] = useState(false);
    const [isConfirmPop, setIsConfirmPop] = useState(false);
    let login_response_object, token_user, user_id;
    if (iLogin) {
        login_response_object = ls.get("login_data_object", { decrypt: true });
        token_user = login_response_object.token;
        user_id = login_response_object.user._id;
    }

    // vehicle data object for post
    const [inputDatasss, setInputData] = useState({
        u_id: user_id,
        make: "",
        vin: "",
        validity: "",
        type: "",
        model: ""
    })

    // inputChange(e)
    function inputChange(e) {
        if (e.target.name == "vin") {
            const value = ((e.target.value).replace(/\s/g, '')).toUpperCase();
            setInputData({ ...inputDatasss, "vin": value })
        } else {
            setInputData({ ...inputDatasss, [e.target.name]: e.target.value })
            console.log(inputDatasss)
        }

    }

    // addVehicalToBackend()
    function addVehicalToBackend() {
        if ((inputDatasss.vin).length > 12 || (inputDatasss.vin).length < 9) {
            window.alert("vehicle number must have 9 to 12 digits");
        } else if ((inputDatasss.vin).length == 0) {
            window.alert("please fill your vehicle number");
        } else if (!isFinite((inputDatasss.vin).slice(-4))) {
            window.alert("please enter valid vehicle number")
        } else {
            axios.post(vehiclePost, inputDatasss, {
                headers: {
                    Authorization: `Hello ${token_user}`
                }
            })
                .then(function (response) {
                    console.log(response);
                    if (response.data.success) {
                        window.alert("Your vehicle has been added succesfully");
                        let currentTime = moment().format("LLLL");
                        let currentTime2 = moment().format("DD-MM-YYYY");
                        let obj = {
                            "name": "vehicleAddSuccess",
                            "message": "your vehicle has been added succesfully",
                            "time": currentTime,
                            "forFilter": currentTime2
                        }
                        ls.set('vehicleAddSuccess', obj)
                        nav('/ListOfVehicle')
                        window.location.reload()
                    }
                })
                .catch(function (error) {
                    window.alert(error.response.data.message)
                    console.log(error);
                });
        }
    }

    return (
        <>
            <div className="">

                {/* navbar */}
                <div className="nav">
                    <SecondNavbar active7={true}/>
                </div>

                {/* top section Component */}
                <TopSection
                    bigi={eng ? vehicle_Head_Top1_En : vehicle_Head_Top1_Hn}
                    small={eng?sub__heading_add_veh:sub__heading_add_veh_hn}
                >
                </TopSection>
                {/* top section Component END*/}

                {/*  vehicle form main section START */}
                <main className="profile_main_section w-[73.5%] m-auto rounded-sm p-2 my-2 bg-white" style={{ "boxShadow": "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}>

                    <div className="container__profile_container w-[90%] m-auto py-2">
                        {/* row 1 */}
                        <div className="w-[100%] flex flex-col justify-center my-5 row_row">
                            <label htmlFor="vin">Vehicle number <span style={{ "color": "red" }}>*</span></label>
                            <input style={{ "textTransform": "uppercase", "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", "border": "none", "outline": "none" }} ref={vehicleADD} autoComplete="true" placeholder="Eg WB25D1324" onChange={(e) => { inputChange(e) }} id="vin" name="vin" className="uppercase w-[100%] py-2 px-3 border-none" />
                        </div>

                        {/* row 2*/}
                        <div className="w-[100%] flex justify-between my-5 row_row">
                            <div style={{ "width": "49%" }}>
                                <label htmlFor="make">Make</label>
                                <input style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", "border": "none", "outline": "none" }} placeholder="Example Tata" onChange={(e) => { inputChange(e) }} name={"make"} id={"make"} className=" w-[100%] py-2 px-3" />
                            </div>

                            <div style={{ "width": "49%" }}>
                                <label htmlFor="model">Model</label>
                                <input style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", "border": "none", "outline": "none" }} placeholder="Example LP2516" onChange={(e) => { inputChange(e) }} name="model" id="model" className="w-[100%] py-2 px-3" />
                            </div>

                        </div>

                        {/* row 3*/}
                        <div className="w-[100%] flex justify-between my-5 row_row">
                            <div style={{ "width": "49%" }}>
                                <label htmlFor="type">Year</label>
                                <input style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", "border": "none", "outline": "none" }} placeholder="Example 2022" onChange={(e) => { inputChange(e) }} name={"type"} id={"type"} className=" w-[100%] py-2 px-3" />
                            </div>
                            <div style={{ "width": "49%" }}>
                                <label htmlFor="validity">RTO Validity</label>
                                <input style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", "border": "none", "outline": "none" }} placeholder="mm/dd/yyyy" onChange={(e) => { inputChange(e) }} type={"date"} name="validity" id="validity" className="w-[100%] py-2 px-3" onKeyDown={(e)=>{e.preventDefault()}}/>
                            </div>
                        </div>

                        <div className="button_group_journeyplanner">
                            <Button onClick={() => { setIsConfirmPop(true) }} className="px-10" style={{ "background": "#232323", "color": "white", "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>Add Vehicle</Button>
                            {/* <Button onClick={() => { addVehicalToBackend() }} className="px-10" style={{ "background": "#232323", "color": "white", "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>Add Vehicle</Button> */}
                        </div>
                    </div>

                </main>
                {/* vehicle form main section END */}

            </div>

            <Footer />

            {/* popup */}
            <div style={isConfirmPop ? { "display": "block" } : { "display": "none" }}>
                <section className="pop_up_main_wrapper" >
                    <div className="main_popup_1ss" >

                        <div className="close_icon flex justify-between items-center">
                            <p className="plan_journey_pop_para">Confirm your details</p>
                            <span onClick={() => { setIsConfirmPop(false) }} className="material-symbols-outlined" style={{ "background": "#F8F9FA", "borderRadius": "50%", "padding": "0.1rem", "cursor": "pointer" }}>
                                close
                            </span>
                        </div>

                        <div className="pop1_body_main my-5">

                            {/* {row1} */}
                            <div className="popup__row1">

                                {/* vin */}
                                <div>
                                    <div className="text-center -mt-5 ">
                                        <span className="popup__row1_span1">Vehicle Registration Number</span>
                                        <h1 className="popup__row1_h1 break-words bg-gray-300">{inputDatasss.vin}</h1>
                                    </div>
                                </div>

                                {/* make */}
                                <div>
                                    <div className="text-center">
                                        <span className="popup__row1_span1">Make</span>
                                        <h1 className="popup__row1_h1 w-[100%]  break-words bg-gray-300">{inputDatasss.make}</h1>
                                    </div>
                                </div>

                                {/* model */}
                                <div>
                                    <div className="text-center ">
                                        <span className="popup__row1_span1">Model</span>
                                        <h1 className="popup__row1_h1 break-words bg-gray-300">{inputDatasss.model}</h1>
                                    </div>
                                </div>

                                {/* type */}
                                <div>
                                    <div className="text-center ">
                                        <span className="popup__row1_span1">Type</span>
                                        <h1 className="popup__row1_h1 break-words bg-gray-300">{inputDatasss.type}</h1>
                                    </div>
                                </div>

                                {/* validity */}
                                <div>
                                    <div className="text-center ">
                                        <span className="popup__row1_span1">RTO Validity</span>
                                        <h1 className="popup__row1_h1 break-words bg-gray-300">{inputDatasss.validity}</h1>
                                    </div>
                                </div>

                                <Button onClick={() => { addVehicalToBackend() }} className="px-10" style={{ "background": "#232323", "color": "white", "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", "marginTop": "1rem", "width": "100%" }}>Confirm and Add</Button>

                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default VehicleTest;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ls from "localstorage-slim";
import axios from "axios";
import "./mobile_TabMui.css";
import { addAMT, addDate, addCD } from "../../redux/slices/InvoiceAmount";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    transactionHistory,
    transferAllCredits,
    transferAllCredits_R,
    transaction_activity,
} from "../../apis/api";
import CommonTable from "../../CommonTable";
import {
    Wallet_Top_Head_En,
    Wallet_Top_Sub_En,
    Wallet_Table1_Default_En,
    Wallet_Table2_Default_En,
    Wallet_Table3_Default_En,
} from "../translation/En";
import {
    Wallet_Top_Head_Hn,
    Wallet_Top_Sub_Hn,
    Wallet_Table1_Default_Hn,
    Wallet_Table2_Default_Hn,
    Wallet_Table3_Default_Hn,
} from "../translation/Hn";
// import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
// './redux/slices/InvoiceAmount'
// import { addAMT, addDate, addCD } from './'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

 const Mobile_BasicTabs = () => {
    
    const eng = useSelector((state) => state.translate.value);
    const [value, setValue] = React.useState(0);
    const [count, setCount] = useState(0);
    const [count2, setCount2] = useState(0);
    const [count3, setCount3] = useState(0);
    const [count4, setCount4] = useState(0);
    const [apiData, setApiData] = useState([]);
    const [apiData2, setApiData2] = useState([]);
    const [apiData3, setApiData3] = useState([]);
    const [apiData4, setApiData4] = useState([]);
    const [showLoadMore, setShowLoadMore] = useState(true);
    const dispatch = useDispatch();
    const nav = useNavigate();
    const iLogin = ls.get("iLogin");
    let login_response_object = "",
        token_user = "",
        user_id = "";
    if (iLogin) {
        login_response_object = ls.get("login_data_object", { decrypt: true });
        token_user = login_response_object.token;
        user_id = login_response_object.user._id;
    }
   

    useEffect(() => {
        axios
            .get(`${transactionHistory}/${user_id}/${count}`, {
                headers: {
                    Authorization: `Hello ${token_user}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    setApiData(res.data.data);
                    console.log("datajuly" + JSON.stringify(res));
                }
                console.log("jank" + JSON.stringify(apiData));
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${transferAllCredits}/${user_id}/${count2}`, {
                headers: {
                    Authorization: `Hello ${token_user}`,
                },
            })
            .then((res) => {
                console.log("response2" + JSON.stringify(res));
                if (res.data.success) {
                    setApiData2(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${transferAllCredits_R}/${user_id}/${count3}`, {
                headers: {
                    Authorization: `Hello ${token_user}`,
                },
            })
            .then((res) => {
                console.log("response3" + JSON.stringify(res));
                if (res.data.success) {
                    setApiData3(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${transaction_activity}/${user_id}`, {
                headers: {
                    Authorization: `Hello ${token_user}`,
                },
            })
            .then((res) => {
                console.log("response4");
                console.log("response4" + JSON.stringify(res));
                if (res.data.success) {
                    setApiData4(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [count, count2, count3, count4]);

    let tableData = apiData;
    console.log(tableData, "console.log(typeof tableData.details)");

    let tableData2 = apiData2;
    console.log(tableData2, "console.log(typeof tableData.details)23223");
    let tableData3 = apiData3;
    console.log(tableData2, "console.log(typeof tableData.details)");
    let tableData4 = apiData4;
    console.log("tableData4", tableData4);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    return (
        <Box
            sx={{ width: "100%", marginTop: "3rem", margin: "auto", display: "flex", flexDirection: "column" }}
        >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Transaction history" {...a11yProps(0)} color={"black"} />
                    <Tab label="Credits Send" {...a11yProps(1)} color={"black"} />
                    <Tab label="Credits Received" {...a11yProps(2)} color={"black"} />
                    <Tab label="Transaction Activity" {...a11yProps(3)} color={"black"} />
                </Tabs>
            </Box>
            {/* Tab 0 - Transaction History */}
            <TabPanel value={value} index={0}>
                <div style={{ marginTop: '2rem' }}>
                    {Array.isArray(tableData.details) ? (
                        tableData.details.map((rowData, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">{rowData.id}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="mobile-table">
                                        <table>
                                            {/* Existing header */}
                                            <thead>
                                                <tr>
                                                    {/* <th>Property</th>
                                                    <th>Value</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {[
                                                    { property: 'createdAt', header: 'Date' },
                                                    { property: 'createdAt', header: 'Time' },
                                                    { property: 'id', header: 'ID' },
                                                    { property: 'amount', header: 'Amount' },
                                                    { property: 'credits', header: 'Credits' },
                                                    { property: 'status', header: 'Status' },
                                                  
                                                ].map(({ property, header }) => (
                                                    <tr key={property}>
                                                        <td>{header}</td>
                                                        <td>
                                                            {property === 'createdAt' &&
                                                                (header === 'Date'
                                                                    ? new Date(rowData[property]).toLocaleDateString()
                                                                    : new Date(rowData[property]).toLocaleTimeString())}
                                                            {property !== 'createdAt' && rowData[property]}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2">
                                                        <button
                                                            style={{
                                                                background: 'black',
                                                                color: 'white',
                                                                padding: '0.3rem 1rem',
                                                                textAlign: 'left', // Align text to the left
                                                                border: 'none',
                                                                borderRadius: '0.3rem',
                                                                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                                display: 'block', // This makes the button full-width
                                                            }}
                                                            onClick={() => 
                                                                { 
                                                                    dispatch(addAMT(rowData.amount));
                                                                    dispatch(addDate(rowData.createdAt.slice(0, 10)));
                                                                    dispatch(addCD(rowData.credits));
                                                                 nav(`/invoice/${rowData.id}`) 
                                                                }}
                                                        >
                                                            Invoice
                                                        </button>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <div>No data available</div>
                    )}
                    <div className="flex justify-center pb-8 mt-2" style={{ marginTop: '2rem' }}>
                        <Button
                            variant="contained"
                            style={apiData.length < 5 ? { display: "none" } : { background: "#232323" }}
                            onClick={() => {
                                setCount(count + 5);
                            }}
                        >
                            Load More
                        </Button>
                    </div>

                    {/* Download CSV Button */}
                    <div className="flex justify-center pb-8">
                        <Button style={{ background: "rgb(35, 35, 35)", color: "white" }}>
                            <CSVLink
                                style={{
                                    textDecoration: "none",
                                    display: "inline-block",
                                    background: "black",
                                    color: "white",
                                    borderRadius: "0.3rem",
                                    cursor: "pointer",
                                }}
                                filename="Transaction_history.csv"
                                data={tableData.details ? tableData.details : []} // Set your data source here
                                className={"download_scv"}
                            >
                                Download CSV
                            </CSVLink>
                        </Button>
                    </div>
                </div>
            </TabPanel>



            {/* Tab 1 - Credit Send */}
            <TabPanel value={value} index={1}>
                <div style={{ marginTop: '2rem' }}>
                    {Array.isArray(tableData2) && tableData2.length > 0 ? (
                        tableData2.map((rowData, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">{rowData.transaction_id}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="mobile-table">
                                        <table>
                                            <tbody>
                                                {[
                                                    { property: 'transfer_date', header: 'Date' },
                                                    { property: 'transfer_date', header: 'Time' },
                                                    { property: 'transaction_id', header: 'Transaction Id' },
                                                    { property: 'receiver_email', header: 'Receiver Mobile' },
                                                    { property: 'transfer_credit', header: 'Credits' },

                                                    // Updated property name
                                                ].map(({ property, header }) => (
                                                    <tr key={property}>
                                                        <td>{header}</td>
                                                        {/* <td>{rowData[property]}</td> */}
                                                        <td>
                                                            {property === 'transfer_date' &&
                                                                (header === 'Date'
                                                                    ? new Date(rowData[property]).toLocaleDateString()
                                                                    : new Date(rowData[property]).toLocaleTimeString())}
                                                            {property !== 'transfer_date' && rowData[property]}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <div>No data available</div>
                    )}
                    {/* Download CSV Button */}
                    <div className="flex justify-center pb-8">
                        <Button style={{ background: "rgb(35, 35, 35)", color: "white", marginTop: "1rem" }}>
                            <CSVLink
                                style={{
                                    textDecoration: "none",
                                    display: "inline-block",
                                    background: "black",
                                    color: "white",
                                    borderRadius: "0.3rem",
                                    padding: "0.4rem 2rem",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                                filename="Send_credits_history.csv"
                                data={tableData2}
                                className={"download_scv"}
                            >
                                Download CSV
                            </CSVLink>
                        </Button>
                    </div>
                </div>
            </TabPanel>



            {/* Tab 2 - Credits Received */}
            <TabPanel value={value} index={2}>
                <div style={{ marginTop: '2rem' }}>
                    {Array.isArray(tableData3) && tableData3.length > 0 ? (
                        tableData3.map((rowData, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">{rowData.transaction_id}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="mobile-table">
                                        <table>
                                            <tbody>
                                                {[
                                                    { property: 'transfer_date', header: 'Date' },
                                                    { property: 'transfer_date', header: 'Time' },
                                                    { property: 'transaction_id', header: 'Transaction Id' },
                                                    { property: 'sender_id', header: 'Sender Mobile' },
                                                    { property: 'transfer_credit', header: 'Credits' },

                                                    // Updated property name
                                                ].map(({ property, header }) => (
                                                    <tr key={property}>
                                                        <td>{header}</td>
                                                        {/* <td>{rowData[property]}</td> */}
                                                        <td>
                                                            {property === 'transfer_date' &&
                                                                (header === 'Date'
                                                                    ? new Date(rowData[property]).toLocaleDateString()
                                                                    : new Date(rowData[property]).toLocaleTimeString())}
                                                            {property !== 'transfer_date' && rowData[property]}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <div>No data available</div>
                    )}
                    {/* Download CSV Button */}
                    <div className="flex justify-center pb-8">
                        <Button style={{ background: "rgb(35, 35, 35)", color: "white", marginTop: "1rem" }}>
                            <CSVLink
                                style={{
                                    textDecoration: "none",
                                    display: "inline-block",
                                    background: "black",
                                    color: "white",
                                    borderRadius: "0.3rem",
                                    padding: "0.4rem 2rem",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                                filename="Received_credits_history.csv"
                                data={tableData3}
                                className={"download_scv"}
                            >
                                Download CSV
                            </CSVLink>
                        </Button>
                    </div>
                </div>
            </TabPanel>
            {/* Transaction Activity */}
            <TabPanel value={value} index={3}>
                <div style={{ marginTop: '2rem' }}>
                    {Array.isArray(tableData4) && tableData4.length > 0 ? (
                        tableData4.map((rowData, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">{rowData.transction_id}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="mobile-table">
                                        <table>
                                            <tbody>
                                                {[
                                                    { property: 'date', header: 'Date' },
                                                    { property: 'date', header: 'Time' },
                                                    { property: 'transction_id', header: 'Transaction Id' },
                                                    { property: 'credit', header: 'Credits' },
                                                    { property: 'type', header: 'Type' },

                                                    // Updated property name
                                                ].map(({ property, header }) => (
                                                    <tr key={property}>
                                                        <td>{header}</td>
                                                        {/* <td>{rowData[property]}</td> */}
                                                        <td>
                                                            {property === 'date' &&
                                                                (header === 'Date'
                                                                    ? new Date(rowData[property]).toLocaleDateString()
                                                                    : new Date(rowData[property]).toLocaleTimeString())}
                                                            {property !== 'date' && rowData[property]}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <div>No data available</div>
                    )}
                    {/* Download CSV Button */}
                    <div className="flex justify-center pb-8">
                        <Button style={{ background: "rgb(35, 35, 35)", color: "white", marginTop: "1rem" }}>
                            <CSVLink
                                style={{
                                    textDecoration: "none",
                                    display: "inline-block",
                                    background: "black",
                                    color: "white",
                                    borderRadius: "0.3rem",
                                    padding: "0.4rem 2rem",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                                filename="Transaction_activity.csv"
                                data={tableData4}
                                className={"download_scv"}
                            >
                                Download CSV
                            </CSVLink>
                        </Button>
                    </div>
                </div>
            </TabPanel>

            {/* <TabPanel value={value} index={3}>
                <div style={{ marginTop: '2rem' }}>
                    {Array.isArray(tableData4.details) ? (
                        tableData4.details.map((rowData, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Transaction Activity {index + 1}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="mobile-table">
                                        <table>
                                            <tbody>
                                                {[
                                                    { property: 'transction_id', header: 'Transaction Id' },
                                                    { property: 'type', header: 'Type' },
                                                    { property: 'credit', header: 'Credits' },
                                                    { property: 'Date', header: 'date' },
                                                ].map(({ property, header }) => (
                                                    <tr key={property}>
                                                        <td>{header}</td>
                                                        <td>{rowData[property]}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <div>No data available</div>
                    )}
                    <div className="flex justify-center pb-8">
                        <Button style={{ background: "rgb(35, 35, 35)", color: "white" }}>
                            <CSVLink
                                style={{
                                    textDecoration: "none",
                                    display: "inline-block",
                                    background: "black",

                                    color: "white",
                                    borderRadius: "0.3rem",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                                filename="Transaction_activity.csv"
                                data={tableData4.details ? tableData4.details : []} // Set your data source here
                                className={"download_scv"}
                            >
                                Download CSV
                            </CSVLink>
                        </Button>
                    </div>
                </div>
            </TabPanel> */}

        </Box>
    );
};
export default Mobile_BasicTabs;

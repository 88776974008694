import React, { useState } from "react";
import styled from "styled-components";
import "./FStep1.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import { Button } from "@mui/material";
import { forgot_email,modifyPasswordOtp } from "../../../apis/api";
import ls from 'localstorage-slim'

const FStep1 = () => {

  // localstates and variables declareation
  const nav = useNavigate();
  const [inputData, setInputData] = useState({
    email: "",
  });

  // when user change email field then it function will be execute
  function changeEmailField(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value })
    console.log(inputData)
  }

  // when user click on send email button it function will be execute
  async function sendEmail(event) {
    event.preventDefault();
    // post forgot form-1 data to backend..
    await axios
      .post(modifyPasswordOtp, inputData)
      .then((response) => {
        if (response.data.success) {
          console.log("aajkares",JSON.stringify(response))
          ls.set("emailOTPId", response.data.data, { encrypt: true })
          ls.set("emailonForgetResend", inputData);
          nav('/FStep2')
        }
        console.log(response)
      })
      .catch((error) => {
        window.alert(error.response.data.message)
      });

  }


  return (
    <div className=" flex items-center w-full h-[100vh] justify-center">
      <Row className="boxMain w-[45%] rounded-lg py-8 h-[38rem]">

        <div className="px-4 lg:px-36 flex items-center flex-col justify-center h-[30rem] gap-4">

          {/* top section */}
          <div className="flex flex-row-reverse justify-between items-center mb-5 w-[80%]">

            <div className="flex items-center">

              <img src="logogo.png" alt="logo_img" style={{ "width": "6rem" }} />
            </div>

            <div className="flex items-center">
              <button className="pt-2" onClick={() => { nav(-1) }}><span className="material-symbols-outlined">
                keyboard_backspace
              </span></button>
              <div>
                <MainHeading HeadinName="Forgot Password" />
              </div>
            </div>

          </div>

          <div style={{ "width": "80%" }}>
            <div style={{ "width": "100%" }}>
              <label htmlFor="mel">Mobile/Email</label>
              <Input
                type="text"
                placeholder="Example alex@gmail.com/6350182509"
                label="Email address"
                name="email"
                className="w-[100%]"
                id="mel"
                onChange={(e) => { changeEmailField(e) }}
              />
            </div>

            <div className="w-full mt-3">
              <Link>
                <Button variant="contained" style={{ "width": "100%", "background": "#232323" }}
                  onClick={(e) => {
                    sendEmail(e);
                  }}
                >Continue</Button>
              </Link>
            </div>
            <div className="mt-[0.625rem]">
              <div className="text-sm mt-2 text-center flex justify-between w-[100%]"><Link className="underline" to={"/single-page-register"}>Register</Link><Link className="underline" to={"/login"}>Login</Link></div>

            </div>

          </div>

        </div>
      </Row>
    </div>
  );
};

// styled components
const Row = styled.div`
`;
const Input = styled.input`
  margin-top: 0.75rem;
  width: 100%;
  text-transform:lowercase;
  padding: 0.75rem 2.25rem 0.75rem 1rem;
  border-radius: 5px;
  border: none;
  outline:none !important;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  font-family: "Inter", sans-serif;
`;



export default FStep1;

// HOME
const Home_tagline_Hn = "फास्टैग आधारित वाहन ट्रैकिंग सिस्टम";
const Home_small_tag_Hn =
  "हमारे फास्टैग आधारित वाहन ट्रैकिंग सिस्टम से आप किसी भी वाहन को आसानी से ट्रैक कर सकते हैं - चाहे वह आपका हो या किसी तीसरे पक्ष का वाहन, नई यात्राएं बनाएं, किसी भी यात्रा के लिए पूर्व-अलर्ट सेट करें, यात्राएं साझा करें जिससे माल की आवाजाही की उच्च दृश्यता प्राप्त हो।";

//PLAN_JOURNEYS
const Plan_Journey_Top_Heading_Hn = "अपनी यात्रा की योजना बनाएं";
const Plan_Journey_Top_Subheading_Hn =
  "आप जिस भी वाहन को ट्रैक करना चाहते हैं, उसके लिए यात्रा बनाएं";
const Plan_Journey_Top_Under_Button_Hn = "Frequently tracked vehicles";

// ACTIVE_JOURNEYS
const Active_Journey_Top_Heading_Hn = "जारी यात्राएं";
const Active_Journey_Top_Subheading_Hn =
  "अपनी सभी चल रही यात्राओं को यहां ट्रैक करें";
const Active_Default_table_message_Hn =
  "इस टेबल में आप अपनी सभी एक्टिव जर्नीस/यात्राओं को देख सकते है...";

// PAST_JOURNEYS
const Past_Journey_Top_Heading_Hn = "पिछली यात्राएँ";
const Past_Journey_Top_Subheading_Hn =
  "आपके द्वारा बनाई गई पिछली सभी यात्राएँ यहाँ देखें";
const Past_Default_table_message_Hn =
  "इस टेबल में आप अपनी सभी समाप्त जर्नीस/यात्राओं को देख सकते है...";

// SEARCH_TRACK_HISTORY
const SEARCH_TRACK_Top_Heading_Hn = "सर्च हिस्ट्री";
const SEARCH_TRACK_Top_Subheading_Hn =
  "यहां ट्रैक किए गए वाहनों का इतिहास/हिस्ट्री देखें";
const SEARCH_TRACK_table_message_Hn =
  "आप इस टेबल में सभी ट्रैक किए गए वाहन इतिहास/हिस्ट्री देख सकते हैं...";

// EWayBill Tracker
const EWayBillNumber_Heading_Hn = "ईवेबिल नंबर सूची";
const EWayBillNumber_Subheading_Hn = "ईवेबिल नंबर विवरण यहां देखें";

// PLAN_Modules
const PLAN_Top_Head_Hn = "रिचार्ज";
const PLAN_Top_Sub_Hn = "अपने लिए सबसे उपयुक्त रिचार्ज चुनें";
const PLAN_Benifits_head_Hn = "रिचार्ज के फायदे";
const PLAN_Benifits_Hn = {
  line1:
    "किसी वाहन को ट्रैक करें - किसी भी वाहन का नवीनतम फास्टैग आधारित टोल क्रॉसिंग डेटा प्राप्त करें",
  line2: "प्रत्येक टोल डेटा की लागत 1 ट्रैकिंग क्रेडिट होती है",
  line3: "अन्य उपयोगकर्ताओं के साथ डेटा साझा करें",
  line4:
    "24 घंटे प्रति वाहन 10 क्रेडिट पर वास्तविक समय में कई वाहन बनाएं, ट्रैक करें और साझा करें",
  line5: "यात्रा की योजना बनाने के लिए रास्ते में सभी टोल प्लाजा खोजें",
  line6: "यात्रा पूरी करने के लिए दूरी और समय का अनुमान लगाएं",
  line7: "आवश्यकतानुसार यात्रा की अवधि बढ़ाएँ",
  line8:
    "देरी और मोड़ के लिए पूर्व-अलर्ट सेट करें और ईमेल या एसएमएस के माध्यम से सूचनाएं प्राप्त करें",
  line9:
    "पूर्ण होने पर यात्रा का अनुकूलित सारांश डाउनलोड करें, और पहले से ट्रैक किए गए वाहनों का इतिहास देखें",
  line10: "अन्य पंजीकृत उपयोगकर्ताओं को ट्रैकिंग क्रेडिट ट्रांसफर करें",
  line11: "विशेष परिचयात्मक प्रस्ताव:",
  line12:
    "बिना किसी अतिरिक्त लागत के पिछले 72 घंटों के टोल क्रॉसिंग डेटा देखें",
  line13: "पोर्टल पर निःशुल्क पंजीकरण करें",
  line14: "सफल पंजीकरण पर शून्य लागत पर 50 ट्रैकिंग क्रेडिट प्राप्त करें",
  line15:
    "अतिरिक्त 50% ट्रैकिंग क्रेडिट ऑफर नवंबर के पूरे महीने के लिए वैध रहेगा।",
  line16:
    "बस अपने खाते को INR 1000 या उससे अधिक के साथ टॉप अप करें, और अतिरिक्त क्रेडिट आपके हैं!",
};

// WALLET
const Wallet_Top_Head_Hn = "वॉलेट";
const Wallet_Top_Sub_Hn = "सभी लेन-देन संबंधी गतिविधियाँ";
const Wallet_Table1_Default_Hn =
  "आपके द्वारा की गई ऑल पेमेंट्स इस टेबल में दिखेगी...";
const Wallet_Table2_Default_Hn =
  "इतिहास/हिस्ट्री देखने के लिए कृपया ट्रैकिंग क्रेडिट ट्रान्सफर करें...";
const Wallet_Table3_Default_Hn =
  "जब कोई आपको क्रेडिट भेजता है तब आप इस टेबल में सभी डेटा देख सकते हैं...";

// VEHICLE RELATED
const vehicle_Head_Top1_Hn = "अपना व्हीकल ऐड करे";
const vehicle_Head_Top2_Hn = "व्हीकल लिस्ट";
const vehicle_Sub_Top2_Hn = "आपके व्हीकलस की लिस्ट";
const vehicle_table_data_Hn =
  "कृपया लिस्ट देखने के लिए अक्सर वाहनों/व्हीकलस को ट्रैक करें";
const sub__heading_add_veh_hn =
  "उन वाहनों को जोड़ें जिन्हें आप अक्सर ट्रैक करने जा रहे हैं";

// TRANSFER_CREDITS
const TRANSFER_CREDITS_Top_Hn = "ट्रान्सफर क्रेडिटस";
const TRANSFER_CREDITS_Sub_Top_Hn =
  "किसी अन्य उपयोगकर्ता को क्रेडिट ट्रांसफर करें";
const TRANSFER_CREDITS_line_Hn =
  "उस उपयोगकर्ता का मोबाइल नंबर दर्ज करें जिसे आप ट्रैकिंग क्रेडिट ट्रांसफर करना चाहते हैं";

export {
  TRANSFER_CREDITS_Top_Hn,
  Wallet_Table3_Default_Hn,
  sub__heading_add_veh_hn,
  TRANSFER_CREDITS_Sub_Top_Hn,
  Home_tagline_Hn,
  Home_small_tag_Hn,
  Plan_Journey_Top_Heading_Hn,
  EWayBillNumber_Heading_Hn,
  EWayBillNumber_Subheading_Hn,
  Plan_Journey_Top_Subheading_Hn,
  Plan_Journey_Top_Under_Button_Hn,
  Active_Journey_Top_Heading_Hn,
  Active_Journey_Top_Subheading_Hn,
  Active_Default_table_message_Hn,
  Past_Journey_Top_Heading_Hn,
  Past_Journey_Top_Subheading_Hn,
  Past_Default_table_message_Hn,
  SEARCH_TRACK_Top_Heading_Hn,
  SEARCH_TRACK_Top_Subheading_Hn,
  SEARCH_TRACK_table_message_Hn,
  PLAN_Top_Head_Hn,
  PLAN_Top_Sub_Hn,
  PLAN_Benifits_head_Hn,
  PLAN_Benifits_Hn,
  Wallet_Top_Head_Hn,
  Wallet_Top_Sub_Hn,
  Wallet_Table1_Default_Hn,
  Wallet_Table2_Default_Hn,
  vehicle_Head_Top1_Hn,
  vehicle_Head_Top2_Hn,
  vehicle_Sub_Top2_Hn,
  vehicle_table_data_Hn,
  TRANSFER_CREDITS_line_Hn,
};

import React, { useState } from "react";
import styled from "styled-components";
import "./Login.css";
import { loginPost, otpvari, reSendOTP } from "../../../apis/api";
import axios from "axios";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import { Button } from "@mui/material";
import ls from 'localstorage-slim';

const Login = () => {
  const nav = useNavigate();
  const [passwordShow, setPasswordShow] = useState(false);
  const getIData = ls.get('getIData', { decrypt: true });


  // iniatize state variables...
  const [inputData, setInputData] = useState({
    email: "",
    password: "",
  });

  const [notvarify,setNotVarify] = useState(false);
  
  // resend OTP
  async function resendOTP() {
    await axios.post(`${reSendOTP}/${getIData}`).then(res => {
      console.log(res)
    }).catch(err => {
      console.log(err)
    })
  }


  const [otp, setOtp] = useState({
    "otp": ""
  })

  // send otp to backend
  async function otpVarification() {
    await axios.post(`${otpvari}/${getIData}`, otp).then(res => {
      console.log(res)
      if (res.data.success) {

        // post login form data to backend..
        axios
          .post(loginPost, {
            email: inputData.mobile,
            password: inputData.password,
          })
          .then((response) => {
            if (response.data.success) {
              window.alert(response.data.message)
              ls.set('login_data_object', response.data, { encrypt: true });
              ls.set('iLogin', true);
              nav('/');
            }
          })
          .catch((error) => {
            // console.log(error)
            window.alert(error.response.data.message)
          });

        // nav('/login')
      }
    }).catch(err => {
      window.alert(err.response.data.message)
    })
  }

  // when user user will input data in input box given function will be run...
  function inputChange(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
    console.log(inputData)
  }

  // login function
  async function login(event) {
    event.preventDefault();

    // post login form data to backend..
    await axios
      .post(loginPost, inputData)
      .then((response) => {
        if (response.data.success) {
          // window.alert(response.data.message)
          ls.set('login_data_object', response.data, { encrypt: true });
          ls.set('iLogin', true);
          nav('/');
        }
      })
      .catch((error) => {
        console.log(error)
        window.alert(error.response.data.message)
        if(error.response.status===401){
          // call otp send api
          resendOTP()
          setNotVarify(true)
        }
      });
  }

  function changeOTP(e) {
    setOtp({ ...otp, [e.target.name]: e.target.value });
    console.log(otp)
  }

  return (
    <div className="box-main- flex items-center w-full h-[100vh] justify-center">
      <Row className="boxMain w-[45%] rounded-lg py-8 h-[38rem]">
        <div className=" container-show-data px-36 flex items-center flex-col justify-center h-[30rem]">
          {/* <div className="text-[1.75rem] w-full">
            <MainHeading HeadinName="Login to LorryCare" />
            <Para Detail="Welcome to the low cost, quick and easy Fastag based tracking solution" />
          </div> */}
          <div className="flex flex-row-reverse justify-between items-center mb-5 w-[100%]">

            <div className="flex items-center">

              <img src="logogo.png" alt="logo_img" style={{ "width": "6rem" }} />
            </div>

            <div className="flex items-center">
              <button className="pt-2" onClick={() => { nav(-1) }}><span className="material-symbols-outlined">
                keyboard_backspace
              </span></button>
              <div>
                <MainHeading HeadinName="Signin to LorryCare" />
                <span className="text-xs">Sign in</span>
              </div>
            </div>

          </div>
          <div className="inputs-for-login w-full">
            <div className="w-full ">
              <label className="InputLabel">
                Email / Mobile Number
              </label>
              <Input
                label="Email address / Phone Number"
                onChange={(e) => {
                  inputChange(e);
                }}
                type="text"
                name="email"
                placeholder="Example alex@gmail.com / 7230842747"
              />
            </div>
            <div className="pass w-[100%] relative mt-[1.375rem]">
              <label className="InputLabel">
                Password
              </label>
              {/* input field of company_name START */}
              {passwordShow === false ? (
                <Input
                  onChange={(e) => {
                    inputChange(e);
                  }}
                  label="Password"
                  type="password"
                  name="password"
                  placeholder="**********************"
                  className="pr-9"
                />
              ) : (
                <Input
                  onChange={(e) => {
                    inputChange(e);
                  }}
                  label="Password"
                  type="text"
                  name="password"
                  placeholder="Your password"
                  className="pr-9"
                />
              )}
              {passwordShow === false ? (
                <AiOutlineEyeInvisible
                  onClick={() => setPasswordShow(true)}
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "3.4rem",
                  }}
                />
              ) : (
                <AiOutlineEye
                  onClick={() => setPasswordShow(false)}
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "3.4rem",
                  }}
                />
              )}
            </div>

            {/* OTP START*/}
            <div style={notvarify?{"display":"block"}:{"display":"none"}}>
              <div className="inputItem flex flex-col">
                <label>OTP</label>
                <div className='border-none border-black rounded-md w-[100%]  flex justify-between items-center pl-2' style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
                  <input
                    className='border-none outline-none w-[78%]'
                    name="otp"
                    type={"text"}
                    placeholder={"Ex 7543"} onChange={(e) => { changeOTP(e) }}
                    maxLength={4}
                  />
                  <div className="flex">
                    <Button style={{ "background": "#232323", "color": "white", "fontFamily": "inter", "fontSize": "0.8rem", "marginRight": "0.5rem","fontSize":"0.6rem" }} onClick={() => { otpVarification() }}>Submit OTP</Button>
                    <Button style={{ "background": "#232323", "color": "white", "fontFamily": "inter", "fontSize": "0.8rem","fontSize":"0.6rem"  }} onClick={() => { resendOTP() }}>ReSend OTP</Button>
                  </div>
                </div>
              </div>
            </div>
            {/* OTP END */}

            {/* input field of company_name END */}
          </div>

          <div className="mb-11 flex justify-between mt-3 w-full">
            <div className="Remember-Me flex items-center">
              <span style={{ marginRight: "3px" }}>
                <input type="checkbox" name="" id="" style={{ "border": "none", "boxShadow": "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }} />
              </span>
              <span className="ml-2">Remember Me</span>
            </div>
            <Link className="Forget-Password" to="/forgetPassword">
              Forgot Password?
            </Link>
          </div>

          <div className="w-full">
            <Link to="/">
              <Button variant="contained" style={{ "background": "#232323" }} className="w-[100%]"
                name="Login"
                onClick={(e) => {
                  login(e);
                }}
              > Login
              </Button>

            </Link>
          </div>
          <div className="SignUpAccount mt-[0.625rem]">
            <p className="font-Inter font-normal text-[rgba(0,0,0,1)] text-sm">
              Don’t have an account?
              <span
                style={{ color: "black", fontWeight: "600", cursor: "pointer" }}
              >
                <Link to="/single-page-register" className="ml-2 underline">Signup</Link>
              </span>{" "}
            </p>
          </div>
        </div>
      </Row>
    </div>
  );
};
const Row = styled.div`
  
`;

const Input = styled.input`
margin-top: 0.75rem;
width: 100%;

padding: 0.75rem 2.25rem 0.75rem 1rem;
border-radius: 5px;
border: none;
outline:none !important;
box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
font-family: "Inter", sans-serif;
`;
export default Login;

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value:0,
}

export const tableDatas = createSlice({
    name: 'tableDatas',
    initialState,
    reducers: {
        addTableData: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { addTableData } = tableDatas.actions

export default tableDatas.reducer
import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const filterDataSource = createSlice({
    name: 'filterDataSource',
    initialState,
    reducers: {
        addSourceForFilter: (state, action) => {
            state.push(action.payload);
        },
        removeSourceForFilter: (state, action) => {
            return state.filter(item => 
                item !== action.payload
            )
        }
    },
})

// Action creators are generated for each case reducer function
export const { addSourceForFilter, removeSourceForFilter } = filterDataSource.actions

export default filterDataSource.reducer
import React, { useState } from "react";
import "./acc.css";

const AccordianByTar = () => {
  const [isToggle1, setIsToggle1] = useState(true);
  const [isToggle2, setIsToggle2] = useState(true);
  const [isToggle3, setIsToggle3] = useState(true);
  const [isToggle4, setIsToggle4] = useState(true);
  const [isToggle5, setIsToggle5] = useState(true);
  const [isToggle6, setIsToggle6] = useState(true);
  const [isToggle7, setIsToggle7] = useState(true);

  return (
    <>
      <div className="accordian_main_wrapper mt-5">
        {/* tab 1 */}
        <div className="accordian_wrapper ">
          <button
            className="accordion font-semibold flex justify-between"
            onClick={() => {
              isToggle1 ? setIsToggle1(false) : setIsToggle1(true);
            }}
          >
            <p>What is lorrycare.com?</p>
            <span className="material-symbols-outlined">
              {isToggle1 ? "add" : "remove"}
            </span>
          </button>
          <div
            className="panel "
            style={isToggle1 ? { "display": "none" } : { "display": "block" }}
          >
            <p className={"pb-3"}>
              Lorrycare.com is promoted and backed by transport players having
              more than 5 decades of rich experience in active transportation.
              Lorrycare’s primary objective is to track and trace ANY vehicle in
              an easy cost-effective manner without any disturbance to the
              vehicle driver. All stakeholders can become part of ANY vehicle’s
              journey, get updates, share journey, get journey summaries and
              analyze the vehicle’s performance.
            </p>
          </div>
        </div>

        {/* tab 2 */}
        <div className="accordian_wrapper ">
          <button
            className="accordion font-semibold flex justify-between"
            onClick={() => {
              isToggle2 ? setIsToggle2(false) : setIsToggle2(true);
            }}
          >
            <p>How does lorrycare.com work?</p>
            <span className="material-symbols-outlined">
              {isToggle2 ? "add" : "remove"}
            </span>
          </button>
          <div
            className="panel "
            style={isToggle2 ? { "display": "none" } : { "display": "block" }}
          >
            <p className={"pb-3"}>
              We have tied up with ULIP – The government’s initiative formed
              under PM GatiShakti aiming to simplify and reduce overall
              logistics costs in India. ULIP provides us with the toll crossing
              data through Fastag API. Our systems convert this data into easy
              to understand format for all stakeholders thus letting them track
              the desired vehicle by simply entering the Vehicle Registration
              Number in the search bar. By augmenting this data further, our
              system allows users to CREATE / TRACK / SHARE a journey. It also
              lets users set PRE-ALERTS for any deviation/delay which is
              notified to the concerned users through sms or email or push
              notification.
            </p>
          </div>
        </div>

        {/* tab 3 */}
        <div className="accordian_wrapper ">
          <button
            className="accordion font-semibold flex justify-between"
            onClick={() => {
              isToggle3 ? setIsToggle3(false) : setIsToggle3(true);
            }}
          >
            <p>Who can use lorrycare.com?</p>
            <span className="material-symbols-outlined">
              {isToggle3 ? "add" : "remove"}
            </span>
          </button>
          <div
            className="panel "
            style={isToggle3 ? { "display": "none" } : { "display": "block" }}
          >
            <p className={"pb-3"}>
              Anyone related to the supply chain distribution ecosystem that
              requires knowing any vehicle’s location can use this service. All
              that any user needs to do is register themselves on our
              application as a mandatory requirement by ULIP.{" "}
            </p>
          </div>
        </div>

        {/* tab 4 */}
        <div className="accordian_wrapper ">
          <button
            className="accordion font-semibold flex justify-between"
            onClick={() => {
              isToggle4 ? setIsToggle4(false) : setIsToggle4(true);
            }}
          >
            <p>How does lorrycare.com benefit me?</p>
            <span className="material-symbols-outlined">
              {isToggle4 ? "add" : "remove"}
            </span>
          </button>
          <div
            className="panel "
            style={isToggle4 ? { "display": "none" } : { "display": "block" }}
          >
            <p className={"pb-3"}>
              Access to toll crossing data of ANY vehicle lets you identify its
              past performance before hiring it – how fast can it cross a
              particular journey? Does it halt more often? Average speed while
              it is moving? The average distance it can cover per day? Gain the
              trust of your clients by using our SHARE-A-journey feature. Track
              as and when you want. No disturbance to the driver thus reducing
              the chance of accidents. No miscommunication. Fastag-based
              tracking is indisputable and true journey summary allows you to
              conduct a post-journey analysis of each journey
            </p>
          </div>
        </div>

        {/* tab 5 */}
        <div className="accordian_wrapper ">
          <button
            className="accordion font-semibold flex justify-between"
            onClick={() => {
              isToggle5 ? setIsToggle5(false) : setIsToggle5(true);
            }}
          >
            <p>Why we are different?</p>
            <span className="material-symbols-outlined">
              {isToggle5 ? "add" : "remove"}
            </span>
          </button>
          <div
            className="panel "
            style={isToggle5 ? { "display": "none" } : { "display": "block" }}
          >
            <p className={"pb-3"}>
              Our easy-to-use, simple, low-cost, and novel approach puts us in a
              very strong position in this vehicle tracking market. With so many
              features at such a negligible cost, we surely are different from
              the rest.
            </p>
          </div>
        </div>

        {/* tab 6 */}
        <div className="accordian_wrapper ">
          <button
            className="accordion font-semibold flex justify-between"
            onClick={() => {
              isToggle6 ? setIsToggle6(false) : setIsToggle6(true);
            }}
          >
            <p>Why choose us?</p>
            <span className="material-symbols-outlined">
              {isToggle6 ? "add" : "remove"}
            </span>
          </button>
          <div
            className="panel "
            style={isToggle6 ? { "display": "none" } : { "display": "block" }}
          >
            <p className={"pb-3"}>
              You can rest assured of the level of service that you get from the
              lorrycare team. Your continuous support reinforces and enhances
              our resolve to serve the logistics industry with great enthusiasm.
              Our team is always ready and willing to assist you around the
              clock.
            </p>
          </div>
        </div>

        {/* tab 7 */}
        <div className="accordian_wrapper ">
          <button
            className="accordion font-semibold flex justify-between"
            onClick={() => {
              isToggle7 ? setIsToggle7(false) : setIsToggle7(true);
            }}
          >
            <p>Compare lorrycare.com to other websites.</p>
            <span className="material-symbols-outlined">
              {isToggle7 ? "add" : "remove"}
            </span>
          </button>
          <div
            className="panel "
            style={isToggle7 ? { "display": "none" } : { "display": "block" }}
          >
            <p className={"pb-3"}>
              Lorrycare.com is built using the latest software programming
              language with end-to-end encryption meaning there is no chance of
              any data theft or data loss. Lorrycare.com is designed only after
              carefully understanding the critical pain points of the logistics
              industry. Our experience of being a victim of in-transit delays
              and the non-visibility of a vehicle en route has greatly added to
              our mission of providing a customer-centric approach. Unlike other
              websites, we aim to improve the visibility of vehicles without
              disturbing the driver who is otherwise constantly hounded with
              calls. All the above services plus the value-added services of
              journey creation, sharing, and journey summary, make us distinct
              from our competitors.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordianByTar;

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
}

export const isFilterClick2 = createSlice({
    name: 'isFilterClick2',
    initialState,
    reducers: {
        setIsFilter22: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { setIsFilter22 } = isFilterClick2.actions

export default isFilterClick2.reducer
import React, { useState, useRef, useEffect } from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import HeadingWithText from "../../components/final_components/HeadingWithText/HeadingWithText";
import "./TrackVehicle.css";
import { Link, useNavigate } from "react-router-dom";
import ls from "localstorage-slim";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  getVehicleListForUser,
  trackVehicle,
  fullVehicleList,
  searchTrack,
  shareRoute,
} from "../../apis/api";
import { addVehicleTrackData } from "../../redux/slices/vehicleTrackData";
import { addewayTrackData } from "../../redux/slices/ewayTrackData";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../../components/layouts/Footer/Footer";
import { RWebShare } from "react-web-share";
import Tooltip from "@mui/material/Tooltip";
import { trackEway } from "../../apis/api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MobileWhenClickTrackEWay = () => {
  const walletBalance = useSelector((state) => state.WalletBalance.value);
  const walletBalanceFromLocal = ls.get("walletBalance", { decrypt: true });
  const [sharingLink, setSharingLink] = useState();
  const [eWay_no, setEway_number] = useState();
  let balanceOBJ;
  if (walletBalance) {
    balanceOBJ = walletBalance;
  } else {
    balanceOBJ = walletBalanceFromLocal;
  }

  // state hit suggestion
  const iLogin = ls.get("iLogin");
  const [showSuggestion, setShowSuggestion] = useState(false);
  let stateSuggestionData = [];
  let [shareLinkOfTrack, setShareLinkOfTrack] = useState();
  let state_suggesREF = useRef();
  if (!ls.get("stateSuggestionData")) {
    ls.set("stateSuggestionData", stateSuggestionData);
  }

  let stateArray = [];
  if (ls.get("stateSuggestionData")) {
    stateArray = ls.get("stateSuggestionData");
  }

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  // onFocusVehicleNumber(e)
  function onFocusVehicleNumber(e) {
    e.preventDefault();
    setStateSuggChecker(false);
    !stateSuggChecker ? setShowSuggestion(true) : setShowSuggestion(false);
  }
  const [stateSuggChecker, setStateSuggChecker] = useState(true);

  // stackClicking(e)
  function stackClicking(e, elms) {
    inputElement.current.value = elms;
    vehicleInputChange(elms, true);
    setShowSuggestion(false);
  }

  //   state suggestion hint END

  let [apiData, setApiData] = useState([]);
  let login_response_object, token_user, user_id;
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }
  let shareLink = useRef();

  useEffect(() => {
    axios
      .get(`${fullVehicleList}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setApiData(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${searchTrack}/${vehicleSearchId}/share`)
      .then((res) => {
        setShareLinkOfTrack(res.data.data.share_link.slice(26, -1));
        setIsLoader(false);
        setSharingLink(res.data.data.share_link.slice(26, -1));
        console.log(res.data.data.share_link);
      })
      .catch((err) => {
        console.log(err);
        setIsLoader(false);
      });
  }, []);

  const dispatch = useDispatch();
  const buttonGetVehicle = useRef();
  const inputElement = useRef();

  let vehicleSearchId;
  let trackVehicleData = useSelector((state) => state.vehicleTrackData.value);
  let trackEwayData = useSelector((state) => state.ewayTrackData.value);

  console.log(trackEwayData, "trackEwayData123");
  const [isLoader, setIsLoader] = useState();

  let trackVehicleDatafromLocalStorage = ls.get("trackVehicleData", {
    decrypt: true,
  });

  if (trackVehicleData == "0") {
    trackVehicleData = trackVehicleDatafromLocalStorage;
  }
  //
  let trackEwayDatafromLocalStorage = ls.get("trackEwayData", {
    decrypt: true,
  });
  if (trackEwayData == "0") {
    trackEwayData = trackEwayDatafromLocalStorage;
  }
  console.log(trackEwayData, "trackEwayDatalss");

  let mainDataArray,
    lastData,
    ewayNumber,
    validUptoDate,
    dateEway,
    expirydateEway,
    expirytimeEway,
    timeEway,
    ewayFromPinCode,
    ewayToPinCode,
    validEwayDate,
    validEwayTime;
  if (iLogin) {
    mainDataArray = trackEwayData.VehiclListDetails;
    ewayNumber = trackEwayData.ewbNo;
    dateEway = trackEwayData.ewayBillDate.slice(0, 10);
    timeEway = trackEwayData.ewayBillDate.slice(11, 19);
    ewayFromPinCode = trackEwayData.fromPincode;
    ewayToPinCode = trackEwayData.toPincode;
    validEwayDate = trackEwayData.validUpto.slice(0, 10);
    validEwayTime = trackEwayData.validUpto.slice(11, 19);

    // vehicleSearchId = trackVehicleData._id;
    // Check if the array is not empty
    if (mainDataArray.length > 0) {
      // Get the last element
      lastData = mainDataArray[mainDataArray.length - 1];
      console.log(lastData, "Last Index Data");
    } else {
      console.log("mainDataArray is empty.");
    }
  }
  console.log(mainDataArray, "mainDataArray");
  const [isSubscribe, setIsSubscribe] = useState(true);
  const [isSubscribeShow, setIsSubscribeShow] = useState(false);
  const [isShare, setIsShare] = useState(false);

  const nav = useNavigate();

  let track_vehicle_no, averageSpeed, averageDistance;

  if (iLogin) {
    track_vehicle_no = ls.get("track_vehicle_no", { decrypt: true });
    // averageSpeed = trackVehicleDatafromLocalStorage.speed;
    // averageDistance = trackVehicleDatafromLocalStorage.distance;
  }

  const [vehicle_nos, setVehicle_nos] = useState({
    vehicle_no: track_vehicle_no,
    u_id: user_id,
  });

  // vehicleInputChange(e)
  function vehicleInputChange(e, check) {
    if (check) {
      setVehicle_nos({ ...vehicle_nos, vehicle_no: e });
      // console.log("withTrue"+vehicle_no);
    } else {
      e.preventDefault();
      const value = e.target.value.replace(/\s/g, "").toUpperCase();
      setVehicle_nos({ ...vehicle_nos, [e.target.name]: value });
      // console.log("withFalse"+vehicle_no);
    }
  }

  function clickTrackFunction() {
    // axios post
    axios
      .post(trackVehicle, vehicle_nos, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then(function (response) {
        if (response.data.success === true) {
          dispatch(addVehicleTrackData(response.data.data));
          ls.set("trackVehicleData", response.data.data, { encrypt: true });
          setIsLoader(false);
          nav("/when-click-track");
          window.alert("1 credit deducted");
          window.location.reload();
        }
      })
      .catch(function (error) {
        setIsLoader(false);
        if (error.response.status) {
          window.alert(
            `Vehicle no. not available in ulip or vehicle has not crossed any in last 72 hours or incorrect vehicle number`
          );
        }
        console.log(error);
      });
  }

  function clickTrackFunctionEway(e) {
    if (!iLogin) {
      window.alert("Please register and sign in to view results");
    } else {
      const login_response_object = ls.get("login_data_object", {
        decrypt: true,
      });
      const token_user = login_response_object.token;
      console.log(token_user, "token_user");
      const user_id = login_response_object.user._id;

      setIsLoader(true);

      if (eWay_no.length !== 12) {
        window.alert("E-way number must have 12 digits");
        setIsLoader(false);
      } else if (!/^\d+$/.test(eWay_no)) {
        window.alert("Please enter a valid Eway bill number (numeric only)");
        setIsLoader(false);
      } else if (eWay_no.length === 0) {
        window.alert("Please enter Eway bill number");
        setIsLoader(false);
      } else if (!isFinite(eWay_no.slice(-4))) {
        window.alert("Please enter a valid Eway bill number");
        setIsLoader(false);
      } else {
        if (balanceOBJ.credits < 1) {
          window.alert("Please buy a Recharge");
          nav("/all-plans");
        } else {
          // Set your API key here
          const apiKey = "Jainish";

          // Set headers with the API key
          const headers = {
            Authorization: `Hello ${token_user}`,
            apiKey,
          };

          setIsLoader(false);
          const vehicle_no = lastData.vehicleNo;
          console.log("vehicle_no", vehicle_no);
          ls.set("track_vehicle_no", vehicle_no, { encrypt: true });
          ls.set("eWay_no", eWay_no, { encrypt: true });
          const stateSuggestionDataLocal = ls.get("stateSuggestionData");
          stateSuggestionDataLocal.push(eWay_no);
          ls.set("stateSuggestionData", stateSuggestionDataLocal);
          axios
            .get(
              `${trackEway}/${eWay_no}`,
              // Pass headers as the second parameter
              { headers: headers }
            )
            .then(function (response) {
              if (response.data.success === true) {
                console.log(
                  "response--" + JSON.stringify(response.data.data[0].response)
                );
                dispatch(addewayTrackData(response.data.data[0].response));
                console.log("lines...");
                console.log(response.data.data[0].response, "dfghjk");
                ls.set("trackEwayData", response.data.data[0].response, {
                  encrypt: true,
                });
                setIsLoader(false);
                nav("/when-click-track-EWay");
              }
              console.log(response, "response ewayyy");
            })
            .catch(function (error) {
              // Handle error
              console.error(error, "errorWway");
            });
        }
      }
    }
  }

  // refresh vehical from server..
  function refreshVehicleTrackData() {
    // axios post
    axios
      .post(trackVehicle, vehicle_nos, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then(function (response) {
        if (response.data.success === true) {
          dispatch(addewayTrackData(response.data.data[0].response));
          ls.set("trackEwayData", response.data.data[0].response, {
            encrypt: true,
          });
          setIsLoader(false);
          nav("/when-click-track-EWay");
          window.alert("1 credit deducted");
          window.location.reload();
        }
      })
      .catch(function (error) {
        setIsLoader(false);
        if (error.response.status) {
          window.alert(
            `Vehicle no. not available in ulip or vehicle has not crossed any in last 72 hours or incorrect vehicle number`
          );
        }
        console.log(error);
      });
  }

  // buySubscribePopup
  function buySubscribePopup() {
    setIsSubscribeShow(true);
  }

  // copyToclipboard
  function copyToclipboard(e) {
    // Get the text field
    var copyText = document.getElementById("copyInput");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
  }

  // getVehicleInformation(e)
  async function getVehicleInformation(e) {
    let shouldTermine = false;
    if (isSubscribe) {
      apiData.map((elm) => {
        if (elm.vin == vehicle_nos.vehicle_no) {
          shouldTermine = true;
          nav("/ListOfVehicle");
        }
      });

      if (!shouldTermine) {
        nav("/AddVehicle");
      }
    } else {
      buySubscribePopup();
    }
  }

  // viewLinkFunction(e)
  // function viewLinkFunction(e) {
  //     e.preventDefault();
  //     if (isSubscribe) {
  //         nav('/track')
  //     } else {
  //         buySubscribePopup();
  //     }
  // }
  async function viewLinkFunction(e) {
    if (lastData.vehicleNo === "") {
      alert(
        "Cannot track last 72 hours Journey, (Conceivably Transit In Train Or other medium)"
      );
      return;
    }
    console.log(lastData, "whatup dwg?");
    const vehicle_no = lastData.vehicleNo;
    ls.set("track_vehicle_no", vehicle_no, { encrypt: true });
    const stateSuggestionDataLocal = ls.get("stateSuggestionData");
    stateSuggestionDataLocal.push(vehicle_no);
    ls.set("stateSuggestionData", stateSuggestionDataLocal);
    // axios post
    await axios
      .post(
        trackVehicle,
        {
          vehicle_no: vehicle_no,
          u_id: user_id,
        },
        {
          headers: {
            Authorization: `Hello ${token_user}`,
          },
        }
      )
      .then(function (response) {
        if (response.data.success === true) {
          console.log("response--" + JSON.stringify(response.data.data));
          dispatch(addVehicleTrackData(response.data.data));
          console.log("lines...");
          console.log(response.data.data, "klllll");
          ls.set("trackVehicleData", response.data.data, {
            encrypt: true,
          });
          setIsLoader(false);
          if (isSubscribe) {
            nav("/track");
          } else {
            buySubscribePopup();
          }
        }
      })
      .catch(function (error) {
        setIsLoader(false);
        console.log(error);
        if (error.response.status == "500") {
          window.alert(`Internal Server Error`);
        }
        if (error.response.status == "400") {
          window.alert(
            `Vehicle no. not available in ULIP/vehicle has not crossed any toll in last 72 hours/incorrect vehicle number`
          );
        }
        if (error.response.status == "404") {
          window.alert("404 Error");
        }
      });
    e.preventDefault();
  }

  // close sub popup
  function closeSubPop() {
    setIsSubscribeShow(false);
  }

  //close closeSharePop
  function closeSharePop() {
    setIsShare(false);
  }

  // planYourJourneyFunction(e)
  function planYourJourneyFunction(e) {
    if (isSubscribe) {
      nav("/MyJourney");
    } else {
      buySubscribePopup();
    }
  }

  return (
    <div
      onClick={() => {
        setStateSuggChecker(true);
        stateSuggChecker ? setShowSuggestion(false) : setShowSuggestion(true);
      }}
    >
      {/* navbar START*/}
      <SecondNavBar />
      {/* navbar END */}

      {/* top section Component */}
      <TopSection bigi={ewayNumber} small={"Last toll crossing data"}>
        {/* <div style={{ display: "flex", width: "100%", "alignItems": "flex-end", flexDirection: "column" }}><p id="copy_lab" style={{ marginTop: "1rem" }}>copy link to share</p><input value={`https://lorrycare.com${shareLinkOfTrack}`} id="copyInput" /></div> */}
      </TopSection>
      {/* top section Component END*/}

      {/* card */}
      <Card>
        {/* {console.log("mainDataArray"+JSON.stringify(mainDataArray))} */}
        {iLogin ? (
          mainDataArray.map((elm, ind) => {
            if (ind == 0) {
              return (
                <>
                  <div className="flex justify-between items-center auto_renew_wrapper">
                    {/* <h1 className="heading_whenclick">{`Crossed ${elm.plaza} at ${elm.readerReadTime.slice(11, 19)}`}</h1> */}
                    <button
                      className="refresh_whenclick flex justify-between items-center"
                      onClick={() => {
                        refreshVehicleTrackData();
                      }}
                    >
                      <p>Refresh</p>
                      <span className="ml-5 material-symbols-outlined">
                        autorenew
                      </span>
                    </button>
                    {/* <h1 className='mt-5 w-[100%] justify-end please_scroll' style={{ "display": "none" }}><p>scroll for view</p> <span className="material-symbols-outlined">
                                    arrow_forward
                                </span></h1> */}
                  </div>
                  <div>
                    <div
                      className="tracker_row"
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        "alignItems": "start",
                      }}
                    >
                      {/* <div className="flex items-center">
                                        <div className="">
                                            <span
                                                className="material-symbols-outlined"
                                                style={{ fontSize: "50px" }}
                                            >
                                                location_on
                                            </span>
                                        </div>
                                        <div className="city" style={{ "maxWidth": "13.5rem" }}>
                                            <h1 className="w-[100%]" style={{ fontWeight: "500" }}>{elm.plaza}</h1>
                                        </div>
                                    </div> */}
                    </div>

                    {/* section EWay */}
                    <section>
                      <div className="mt-2">
                        <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                          E-way Bill Date -
                        </b>
                        <span style={{ color: "grey", fontSize: "0.9rem" }}>
                          {" "}
                          {/* {elm.readerReadTime.slice(0, 10)} */}
                          {dateEway}
                        </span>
                      </div>
                      <div className="mt-2">
                        <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                          E-way Bill Time -
                        </b>
                        <span style={{ color: "grey", fontSize: "0.9rem" }}>
                          {" "}
                          {/* {elm.readerReadTime.slice(11, 19)} */}
                          {timeEway}
                        </span>
                      </div>
                      <div className="mt-2">
                        <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                          From Pincode -
                        </b>
                        <span style={{ color: "grey", fontSize: "0.9rem" }}>
                          {" "}
                          {/* {elm.city} */}
                          {ewayFromPinCode}
                        </span>
                      </div>
                      <div className="mt-2">
                        <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                          To Pincode -
                        </b>
                        <span style={{ color: "grey", fontSize: "0.9rem" }}>
                          {" "}
                          {/* {elm.state} */}
                          {ewayToPinCode}
                        </span>
                      </div>
                      <div className="mt-2">
                        <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                          Expiry Date-
                        </b>
                        <span style={{ color: "grey", fontSize: "0.9rem" }}>
                          {" "}
                          {/* {trackinNumber} */}
                          {validEwayDate}
                        </span>
                      </div>
                      <div className="mt-2">
                        <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                          Expiry Time-
                        </b>
                        <span style={{ color: "grey", fontSize: "0.9rem" }}>
                          {" "}
                          {/* {trackinNumber} */}
                          {validEwayTime}
                        </span>
                      </div>
                    </section>
                    {/* section */}
                    {/* <section>
                                    <div className="mt-2">
                                        <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                                            Vehicle No. -
                                        </b>
                                        <span style={{ color: "grey", fontSize: "0.9rem" }}>
                                            {" "}
                                            {lastData.vehicleNo}
                                        </span>
                                    </div>
                                    <div className="mt-2">
                                        <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                                            Date -
                                        </b>
                                        <span style={{ color: "grey", fontSize: "0.9rem" }}>
                                            {" "}
                                            {lastData.enteredDate.slice(0, 10)}
                                        </span>
                                    </div>
                                    <div className="mt-2">
                                        <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                                            Time -
                                        </b>
                                        <span style={{ color: "grey", fontSize: "0.9rem" }}>
                                            {" "}
                                            {lastData.enteredDate.slice(11, 19)}
                                        </span>
                                    </div>
                                    <div className="mt-2">
                                        <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                                            TransMode -
                                        </b>
                                        <span style={{ color: "grey", fontSize: "0.9rem" }}>
                                            {" "}
                                            {lastData.transMode}
                                        </span>
                                    </div>

                                </section> */}
                    <section
                      style={{ marginBottom: "1rem", marginTop: "1rem" }}
                    >
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>See all Vehicle Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="mobile-table">
                            <table>
                              <thead>
                                <tr>
                                  {/* <th>Property</th>
                            <th>Value</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {mainDataArray.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td style={{ fontWeight: "600" }}>
                                        Vehicle No.
                                      </td>
                                      <td>{item.vehicleNo}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ fontWeight: "600" }}>
                                        Date
                                      </td>
                                      <td>{item.enteredDate.slice(0, 10)}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ fontWeight: "600" }}>
                                        Time
                                      </td>
                                      <td>{item.enteredDate.slice(11, 19)}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ fontWeight: "600" }}>
                                        TransMode
                                      </td>
                                      <td>{item.transMode}</td>
                                    </tr>
                                    {/* Add an empty row after each set of data for separation */}
                                    {index !== mainDataArray.length - 1 && (
                                      <tr>
                                        <td colSpan="2">&nbsp;</td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </section>
                  </div>
                </>
              );
            }
          })
        ) : (
          <></>
        )}

        {/* here */}

        <div className="flex justify-end mt-0">
          <button
            onClick={(e) => {
              viewLinkFunction(e);
            }}
            style={{ "textDecoration": "underline", "fontWeight": "bold" }}
          >
            View Last 72 Hour Journey
          </button>
        </div>

        {/* <div className="flex -mt-3">
                    <Tooltip title="You can share your vehicle tracking data to another" placement="bottom">
                        <button
                            style={{
                                "textDecoration": "none",
                                "display": "inline-block",
                                "background": "#232323",
                                "color": "white",
                                "borderRadius": "0.3rem",
                                "padding": "0.4rem 2rem",
                                "border": "none",
                                "cursor": "pointer",
                                "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                "width": "49%",
                                "marginTop": "1.5rem",
                            }}

                        // onClick={(e) => { ShareFunction(e); }}
                        >
                            <RWebShare
                                data={{
                                    url: `${shareRoute}${shareLinkOfTrack}`,
                                    title: "search vehicle track",
                                }}
                                onClick={() => console.log("shared successfully!")}
                            >
                                <button style={{ "width": "100%" }}>Share🔗</button>
                            </RWebShare>
                        </button>
                    </Tooltip>

                    <Tooltip title="Create your own Journey" placement="bottom">
                        <button className="hoverOnwithoutBackButton"
                            style={{
                                "textDecoration": "none",
                                "display": "inline-block",
                                "color": "black",
                                "background": "none",
                                "borderRadius": "0.3rem",
                                "padding": "0.4rem 2rem",
                                "border": "1px solid black",
                                "cursor": "pointer",
                                "marginLeft": "1rem",
                                "width": "49%",
                                "marginTop": "1.5rem",
                            }}

                            onClick={(e) => { planYourJourneyFunction(e) }}
                        >
                            Plan your Journey
                        </button>
                    </Tooltip>

                </div> */}
      </Card>

      <div className="-mt-10">
        {/* topper black section */}
        <div
          className="flex justify-center flex-col bg-white tooper-black"
          onClick={() => {
            setStateSuggChecker(true);
            stateSuggChecker
              ? setShowSuggestion(false)
              : setShowSuggestion(false);
          }}
        >
          <div className="whenclick_topper flex justify-between items-center mt-[4px] ">
            <h1 className="text-black font-Cardo">
              <p className="font-Cardo font-normal text-black text-start ">
                Track another Vehicle
              </p>
            </h1>
          </div>

          <div className="mt-2 flex justify-between track_again_wrapper">
            <div
              className="relative track_again_wrapper_input_wrapper"
              style={{ "width": "75%" }}
            >
              <input
                ref={inputElement}
                onFocus={(e) => {
                  onFocusVehicleNumber(e);
                }}
                name="vehicle_no"
                value={vehicle_nos.vehicle_no}
                onChange={(e) => {
                  vehicleInputChange(e, false);
                }}
                type="text"
                className="w-1/1 rounded px-5 bg-white"
                style={{ "width": "100%", "outline": "none", "height": "40px" }}
              />
              <div
                className="suggestion__box"
                style={
                  showSuggestion
                    ? {
                        "display": "flex",
                        "alignItems": "start",
                        "flexDirection": "column",
                        "boxShadow":
                          "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      }
                    : { "display": "none" }
                }
              >
                <div
                  className="h-[10rem] w-[100%] overflow-auto suggestion_inner_div"
                  style={{}}
                >
                  {removeDuplicates(stateArray).map((elm) => {
                    return (
                      <div
                        ref={state_suggesREF}
                        className="font-normal text-black text-start hover_on_suggestion w-[100%] uppercase mt-5"
                        onClick={(e) => {
                          stackClicking(e, elm);
                        }}
                      >
                        {elm}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div
              style={{ "width": "20%" }}
              className={"track_again_wrapper_button_wrapper"}
            >
              <button
                onClick={(e) => {
                  clickTrackFunction(e);
                }}
                className="rounded font-Cardo trackAgain"
                style={{
                  "background": "white",
                  "width": "100%",
                  "height": "40px",
                  "boxShadow": "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                }}
              >
                Track again
              </button>
            </div>
          </div>
        </div>
        <ul className="-mt-5 w-[73.5%] m-auto">
          <li className="text-start" style={{ "fontSize": "10px" }}>
            The data provided for this vehicle number is sourced from ULIP, and
            we do not take responsibility for its accuracy or completeness
          </li>
          {/* <li className="text-start" style={{ "fontSize": "10px" }}>The data for this vehicle number is only valid for a period of 3 months from the time of entry.</li> */}
        </ul>
      </div>

      <Footer />

      {/* all popup */}

      {/* Oops ! You can’t access full journey. Buy subscription plan for view full journey. */}
      <div
        style={isSubscribeShow ? { "display": "block" } : { "display": "none" }}
      >
        <section className="pop_up_main_wrapper">
          <div className="main_popup_1">
            <div className="close_icon flex justify-end">
              <span
                onClick={() => {
                  closeSubPop();
                }}
                className="material-symbols-outlined"
                style={{
                  "background": "#F8F9FA",
                  "borderRadius": "50%",
                  "padding": "0.1rem",
                  "cursor": "pointer",
                }}
              >
                close
              </span>
            </div>

            <div className="flex flex-col justify-center items-center">
              <div className="icon_wrapper">
                <span
                  className="material-symbols-outlined"
                  style={{ "fontSize": "3rem" }}
                >
                  check_box
                </span>
              </div>

              <div>
                <p className="track_popup1_mess">
                  Oops ! You can’t access full journey. Buy subscription plan
                  for view full journey.
                </p>
              </div>

              <div>
                <button
                  className="track_whenclick_button"
                  onClick={() => {
                    nav("/Plans");
                  }}
                >
                  Buy Plan
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* share popup 2 */}
      <div style={isShare ? { "display": "block" } : { "display": "none" }}>
        <section className="pop_up_main_wrapper">
          <div className="main_popup_2">
            <div className="close_icon flex justify-between items-start">
              <p className="track_popup1_mess2 font-bold">Share</p>
              <span
                onClick={() => {
                  closeSharePop();
                }}
                className="material-symbols-outlined"
                style={{
                  "background": "#F8F9FA",
                  "borderRadius": "50%",
                  "padding": "0.1rem",
                  "cursor": "pointer",
                }}
              >
                close
              </span>
            </div>

            <div className="mt-5 flex justify-between">
              <div>
                <RWebShare
                  data={{
                    url: shareLinkOfTrack,
                    title: "search vehicle track",
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <button>Share on any social media 🔗</button>
                </RWebShare>
              </div>
              {/* <div className="share_social_wrapper flex justify-center items-center cursor-pointer"><i className="fa fa-instagram" style={{ "fontSize": "24px" }}></i></div>
                            <div className="share_social_wrapper flex justify-center items-center cursor-pointer"><i className="fa fa-facebook-official" style={{ "fontSize": "24px" }}></i></div>
                            <div className="share_social_wrapper flex justify-center items-center cursor-pointer"><i className="fa fa-whatsapp" style={{ "fontSize": "24px" }}></i></div>
                            <div className="share_social_wrapper flex justify-center items-center cursor-pointer"><span className="material-symbols-outlined" style={{ "fontSize": "24px" }}>
                                comment
                            </span></div> */}
            </div>

            <div className="mt-5">
              <p>Page Link</p>
              <div
                className={
                  "mt-5 overflow-auto flex justify-between items-center"
                }
                style={{ "width": "100%", "background": "#F8F9FA" }}
              >
                <input
                  className="pl-3 pr-1 overflow-auto text-xs"
                  style={{ "width": "80%" }}
                  ref={shareLink}
                  id="myInput"
                  value={shareLinkOfTrack}
                />
                <div
                  className="cursor-pointer ml-5 my-1"
                  style={{ "width": "20%" }}
                  onClick={(e) => {
                    copyToclipboard(e);
                  }}
                >
                  <span className="material-symbols-outlined">
                    content_copy
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* loader */}
      <section
        style={
          isLoader
            ? { "display": "flex", "zIndex": "999999999" }
            : { "display": "none" }
        }
        id="home_loader"
        className="absolute top-0 left-0 w-[100%] h-[100%] bg-white opacity-50 flex justify-center items-center"
      >
        <CircularProgress />
      </section>
    </div>
  );
};

export default MobileWhenClickTrackEWay;

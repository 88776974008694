import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import ls from 'localstorage-slim';

const RStep6 = () => {
  const nav = useNavigate();
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShow2, setPasswordShow2] = useState(false);
  // iniatize state variables...
  const [inputData, setInputData] = useState({
    pass: "",
    cpass: "",
  });


  // clickContinue(e);
  function clickContinue(e) {
    if (inputData.pass == "" || inputData.cpass == "") {
      window.alert("all field are required");
    } else {
      if (inputData.pass !== inputData.cpass) {
        window.alert("password has not matched");
      } else if ((inputData.pass).length < 5 || (inputData.pass).length > 25) {
        window.alert("password legnth must between 5 to 25");
      } else {
        ls.set('register_password', inputData, { encrypt: true });
        nav('/CompanyDetail');
      }

    }
  }

  // handleChange
  function handleChange(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value })
    console.log(inputData)
  }


  return (
    <div className="flex items-center w-full h-[100vh] justify-center border ">
      <Row className="py-8 w-[70.5%]">
        <div className="px-4 md:px-32 lg:px-36 flex items-center flex-col justify-center h-[30rem]">

          <div className="flex flex-row-reverse justify-between items-center mb-5 w-[80%]">

            <div className="flex items-center">

              <img src="logogo.png" alt="logo_img" style={{ "width": "6rem" }} />
            </div>

            <div className="flex items-center">
              <button className="pt-2" onClick={() => { nav(-1) }}><span className="material-symbols-outlined">
                keyboard_backspace
              </span></button>
              <div>
                <MainHeading HeadinName="Set Password" />
                <span className="text-xs">Set your password</span>
              </div>
              {/* <Para Detail="Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio" /> */}
            </div>

          </div>

          <div className="w-[80%]">

            {/* password */}
            <div className="w-[100%]">
              <div className="w-[97%] relative">
                <label htmlFor="">password</label>
                {/* input field of company_name START */}
                {passwordShow === false ? (
                  <Input
                    type="password"
                    placeholder="*************"
                    label="Password"
                    name="pass"
                    className=""
                    onChange={(e) => { handleChange(e) }}
                  />
                ) : (
                  <Input
                    type="text"
                    placeholder="Password"
                    label="Password"
                    name="pass"
                    onChange={(e) => { handleChange(e) }}
                  />
                )}
                <div className="w-[3%] absolute right-5 top-[3.2rem]">
                  {passwordShow === false ? (
                    <AiOutlineEyeInvisible
                      onClick={() => setPasswordShow(true)}
                    />
                  ) : (
                    <AiOutlineEye
                      onClick={() => setPasswordShow(false)}
                    />
                  )}
                </div>
              </div>

              {/* <Input
                type="password"
                placeholder="Password"
                label="Password"
                name="pass"
                onChange={(e) => { handleChange(e) }}
              /> */}

            </div>

            <div className="w-[97%] relative">
              <label htmlFor="">confirm password</label>
              {/* input field of company_name START */}
              {passwordShow2 === false ? (
                <Input
                  type="password"
                  placeholder="************"
                  label="Confirm Password"
                  name="cpass"
                  onChange={(e) => { handleChange(e) }}
                />
              ) : (
                <Input
                  type="text"
                  placeholder="Confirm password"
                  label="Confirm Password"
                  name="cpass"
                  onChange={(e) => { handleChange(e) }}
                />
              )}
              <div className="w-[3%] absolute right-5 top-[3.2rem]">
                {passwordShow2 === false ? (
                  <AiOutlineEyeInvisible
                    onClick={() => setPasswordShow2(true)}
                  />
                ) : (
                  <AiOutlineEye
                    onClick={() => setPasswordShow2(false)}
                  />
                )}
              </div>
            </div>

            <div className="w-full mt-5">
              <Button variant="contained" className="w-[100%]"
                name="Continue" style={{ "background": "#232323" }}
                onClick={(e) => {
                  clickContinue(e);
                }}
              >Continue</Button>

              <div className="text-sm mt-2 text-center flex justify-between w-[100%]"><Link className="underline" to={"/login"}>Have an account?</Link><Link className="underline" to={"/forgetPassword"}>Forgot password?</Link></div>

            </div>

          </div>

        </div>
      </Row>
    </div>
  );
};
const Row = styled.div`
  
`;
const Input = styled.input`
margin-top: 0.75rem;
width: 100%;
padding: 0.75rem 2.25rem 0.75rem 1rem;
border-radius: 5px;
border: none;
outline:none !important;
box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
font-family: "Inter", sans-serif;
`;
export default RStep6;

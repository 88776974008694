import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
    link:0,
}

export const share_pop_up = createSlice({
    name: 'share_pop_up',
    initialState,
    reducers: {
        addBoolean: (state, action) => {
            state.value = (action.payload);
        },
        addShareLinkJourney: (state, action) => {
            state.link = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { addBoolean, addShareLinkJourney } = share_pop_up.actions

export default share_pop_up.reducer
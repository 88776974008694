import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
}

export const hasWalletMoney = createSlice({
    name: 'hasWalletMoney',
    initialState,
    reducers: {
        checkWalletMoney: (state, action) => {
            state.value = (action.payload);
        }
    },
})

// Action creators are generated for each case reducer function
export const { checkWalletMoney } = hasWalletMoney.actions

export default hasWalletMoney.reducer
import React, { useRef, useState } from "react";
import TopSection from "../../TopSection";
import CommonTable from "../../CommonTable";
import Filter from "../../components/layouts/Filter/Filter";
import { CSVLink } from "react-csv";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import HeadWithUnderline from "../../components/layouts/HeadingUnderline/HeadWithUnderline";
import "./WalletTwo.css";
import Footer from "../../components/layouts/Footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import { checkWalletMoney } from "../../redux/slices/hasWalletMoney";
import { Button } from "@mui/material";
import ls from "localstorage-slim";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  transactionHistory,
  transferAllCredits,
  closeJourneyCredits,
  transaction_activity,
  transferAllCredits_R,
} from "../../apis/api";
import axios from "axios";
import {
  Wallet_Top_Head_En,
  Wallet_Top_Sub_En,
  Wallet_Table1_Default_En,
  Wallet_Table2_Default_En,
  Wallet_Table3_Default_En,
} from "../../components/translation/En";
import {
  Wallet_Top_Head_Hn,
  Wallet_Top_Sub_Hn,
  Wallet_Table1_Default_Hn,
  Wallet_Table2_Default_Hn,
  Wallet_Table3_Default_Hn,
} from "../../components/translation/Hn";
import TabMui from "../../components/TabMui/TabMui";

const WalletTwo = () => {
  const eng = useSelector((state) => state.translate.value);
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [apiData2, setApiData2] = useState([]);
  const [apiData3, setApiData3] = useState([]);
  const [apiData4, setApiData4] = useState([]);
  const walletBalance = useSelector((state) => state.WalletBalance.value);
  const mouseFocus = useSelector((state) => state.mouseOnVehicle.value);
  const walletBalanceFromLocal = ls.get("walletBalance", { decrypt: true });
  const [showLoadMore, setShowLoadMore] = useState(true);

  let balanceOBJ;
  if (walletBalance) {
    balanceOBJ = walletBalance;
  } else {
    balanceOBJ = walletBalanceFromLocal;
  }

  const dispatch = useDispatch();
  const nav = useNavigate();
  const iLogin = ls.get("iLogin");
  let login_response_object = "",
    token_user = "",
    user_id = "";
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }
  console.log("27april" + JSON.stringify(balanceOBJ));

  useEffect(() => {
    axios
      .get(`${transactionHistory}/${user_id}/${count}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setApiData(res.data.data);
          console.log("////today" + JSON.stringify(res.data.data));
        }
        console.log("jank" + JSON.stringify(apiData));
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${transferAllCredits}/${user_id}/${count2}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log("response2" + JSON.stringify(res));
        if (res.data.success) {
          setApiData2(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${transferAllCredits_R}/${user_id}/${count3}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log("response3" + JSON.stringify(res));
        if (res.data.success) {
          setApiData3(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${transaction_activity}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log("response4");
        console.log("response4" + JSON.stringify(res));
        if (res.data.success) {
          setApiData4(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [count, count2, count3, count4]);

  let tableData = apiData;
  let tableData2 = apiData2;
  let tableData3 = apiData3;
  let tableData4 = apiData4;

  // csvData
  const csvData = tableData;

  return (
    <>
      <div className="nav">
        <SecondNavBar active3={true} />
      </div>

      {/* top section Component */}
      <TopSection
        bigi={eng ? Wallet_Top_Head_En : Wallet_Top_Head_Hn}
        small={eng ? Wallet_Top_Sub_En : Wallet_Top_Sub_Hn}
      ></TopSection>
      {/* top section Component END*/}

      {/* available balance START*/}
      <section className="w-[73.5%] m-auto available_wrapper">
        <div className="available_container pt-2 flex  justify-between">
          {/* left */}
          <div className="available_wrapper__left flex">
            {/* <div>
              <h1 className='text-xl font-bold'>Availbale Balance</h1>
              <p className={"mt-3 text-lg"} data-amount={balanceOBJ?balanceOBJ.balance:"0"}>₹ {balanceOBJ?balanceOBJ.balance:"0"}</p>
            </div> */}

            <div className="ml-5 flex items-center">
              <h1 className="text-xl font-bold">Available Credits</h1>
              <p
                className={"text-lg ml-3"}
                data-amount={balanceOBJ ? balanceOBJ.credits : "0"}
              >
                {balanceOBJ ? balanceOBJ.credits : "0"} TC
              </p>
            </div>
          </div>

          {/* right */}
          <div
            className="available_wrapper__right"
            style={mouseFocus ? { zIndex: "-9999" } : { zIndex: "0" }}
          >
            <Button
              className="font-Inter"
              style={{
                background: "#232323",
                color: "white",
                marginRight: "1rem",
              }}
              onClick={() => {
                nav("/all-plans");
              }}
            >
              Add Credits
            </Button>
            <Button
              className="font-Inter"
              style={{
                background: "white",
                color: "black",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}
              onClick={() => {
                nav("/transfer-credits");
              }}
            >
              Transfer Credits
            </Button>
          </div>
        </div>
      </section>
      {/* available balance END*/}

      {/* tabs start mui */}
      <div className="mt-5">
        <TabMui />
      </div>
      {/* mui tab end */}

      <Footer />
    </>
  );
};

export default WalletTwo;

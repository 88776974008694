import React, { useState } from 'react'
import { Card, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ls from 'localstorage-slim'
import { useDispatch, useSelector } from 'react-redux'
import { addCPA } from '../../redux/slices/cPA';


const PlanCard1 = ({ Main, small, head, classes, type, singleData, planID }) => {
    const nav = useNavigate();
    const iLogin = ls.get('iLogin');
    const dispatch = useDispatch();
    const [showInputOfAmount, setShowInputOfAmount] = useState(false);
    const [amountData, setAmountData] = useState({
        amounts: ""
    })
    const [amountData2, setAmountData2] = useState({
        amounts: ""
    })


    // changeHandle(e)
    function changeHandle(e) {
        setAmountData({ ...amountData, [e.target.name]: e.target.value });
        console.log("1" + JSON.stringify(amountData))
    }
    // changeHandle2(e)
    function changeHandle2(e) {
        setAmountData2({ ...amountData2, [e.target.name]: e.target.value });
        console.log("2" + JSON.stringify(amountData2))
    }

    const walletBalance = useSelector((state) => state.WalletBalance.value);
    const walletBalanceFromLocal = ls.get("walletBalance", { decrypt: true })

    let balanceOBJ;
    if (walletBalance) {
        balanceOBJ = walletBalance;
    } else {
        balanceOBJ = walletBalanceFromLocal;
    }

    // for gateway
    let amount, order_id;
    let uid = "";
    if (iLogin) {
        const login_response_object = ls.get("login_data_object", { decrypt: true });
        uid = login_response_object.user._id;
    }

    function buyCredits(amount) {
        if (!iLogin) {
            window.alert("please login first");
        } else {
            dispatch(addCPA(amount));
            nav('/hdfc-form')
        }
    }
    //showPopUp()
    function showPopUp() {
        if (amountData.amounts.length === 0) {
            window.alert(`please fill your amount`)
        } else if (amountData.amounts < 201) {
            window.alert(`please fill your amount between 201 to 499`)
        } else if (amountData.amounts > 499) {
            window.alert(`please fill your amount between 201 to 499`)
        }
        else {
            buyCredits(amountData.amounts);
        }
    }
    function showSpecificPopUp() {
        if (amountData.amounts.length === 0) {
            window.alert(`please fill your amount`)
        } else if (amountData.amounts < 1000) {
            window.alert(`please fill your amount 1000 or above`)
        } else if (amountData.amounts > 99999) {
            window.alert(`please fill your amount 1000 or above`)
        }
        else {
            buyCredits(amountData.amounts);
        }
    }

    // showPopUp2()
    function showPopUp2() {
        if (amountData2.amounts.length === 0) {
            window.alert(`please fill your amount`)
        } else if (amountData2.amounts < 500) {
            window.alert(`please fill your amount - 500 and above`)
        } else if (amountData2.amounts > 99999) {
            window.alert(`please fill your amount - 500 and above`)
        } else {
            buyCredits(amountData2.amounts);
        }
    }

    return (
        <>
            <div className={classes ? classes : "one rounded-md mt-1 plan_cardy"} style={{ "width": "33%", "height": "auto" }}>
                <div className="card" style={{ "height": "100%" }}>
                    <Card variant="outlined plan_card_card" style={{ "height": "100%", "boxShadow": "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset", "cursor": "default" }}>
                        <div className="flex flex-col gap-1 py-2 pl-2 pr-2">
                            <div className="HeadMSg text-center font-Cardo text-2xl font-extrabold uppercase">
                                {Main}
                                <div className="text-sm" style={{ "textTransform": "initial" }}>{small}</div>
                            </div>
                            <div className="flex justify-center gap-2">
                                <div className="font-Inter font-bold text-3xl">{head}</div>
                            </div>
                            {(planID === "6557294d080c7eb033174307") ?
                                <div className='flex justify-center'>
                                    <input type="text" className='border rounded-md placeholder:text-sm w-[90%]' name='amounts' style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }} onChange={(e) => changeHandle(e)} placeholder={`${head}`} />
                                    {/* Display specific content for planID "6557294d080c7eb033174307" */}
                                    {/* Add your content or components here */}
                                </div> :
                                (planID === "644ba8b8f242393cba895a4c" || planID === "644ba93cf242393cba895a50" || planID === "644ba8b8f242393cba895a4c") ?
                                    <div className='flex justify-center'>
                                        <input type="text" className='border rounded-md placeholder:text-sm w-[90%]' name='amounts' style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }} onChange={(e) => { planID === "644ba8b8f242393cba895a4c" ? changeHandle(e) : changeHandle2(e) }} placeholder={`${head}`} />
                                    </div> : <></>
                            }
                        </div>

                        <div className="flex justify-center items-center mb-4">
                            <Button onClick={() => {
                                iLogin ?
                                    (planID === "644ba8b8f242393cba895a4c" || planID === "644ba93cf242393cba895a50" || planID === "644ba8b8f242393cba895a4c") ?
                                        (planID === "644ba8b8f242393cba895a4c" ? showPopUp() : showPopUp2()) :
                                        (planID === "6557294d080c7eb033174307" ? showSpecificPopUp() : buyCredits(singleData.amount)) :
                                    window.alert("please login first")
                            }}
                                className="hoverButoon"
                                variant="outlined"
                                style={{ "background": "#262626", "border": "none", "color": "white", "cursor": "pointer" }}>
                                Buy Credits
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>

        </>

    )
}

export default PlanCard1
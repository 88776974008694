import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
}

export const vehicleGetResponse = createSlice({
    name: 'vehicleGetResponse',
    initialState,
    reducers: {
        AddVehicleResponse: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { AddVehicleResponse } = vehicleGetResponse.actions

export default vehicleGetResponse.reducer



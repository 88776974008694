import React from 'react'
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import HeadingWithText from "../../components/final_components/HeadingWithText/HeadingWithText";
import Footer from '../../components/layouts/Footer/Footer';

const RefundRequest2 = () => {
    return (
        <>

            {/* navbar START*/}
            <SecondNavBar />
            {/* navbar END */}

            {/* top section Component */}
            <TopSection
                bigi={"Get Your Refund"}
                small={"lorem lorem lorem jhfge xuhfd gf"}
            >
            </TopSection>
            {/* top section Component END*/}

            {/* about us Main Card START */}
            <Card css={{ "width": "53.5%", "margin": "2rem auto", "paddingTop": "1.6rem", "paddingBottom": "1rem" }}>

                <HeadingWithText head={"Raise Refund Request"} />

                <div className='px-8 pt-3'>

                    <p className='font-Cardo'>What type of  plan you are using?</p>

                    {/* plan select box */}
                    <select className='rounded my-3 pl-3' style={{ "width": "100%", "border": "1px solid #232323" }}>
                        <option defaultChecked>Choose Your Plan</option>
                        <option value="">Basic</option>
                        <option value="">Pro</option>
                        <option value="">Standard</option>
                        <option value="">Enterprise</option>
                    </select>

                    <div className='mt-3 flex items-center'>
                        <button className='flex  items-center text-white px-6 rounded shadow-md py-1' style={{ "background": "#262626" }}><p>ok</p><span className="material-symbols-outlined ml-1">
                            done
                        </span></button>
                        <div className='ml-5 cursor-pointer flex items-center'><p>Press Enter</p> <span className="material-symbols-outlined ml-2 border border-black rounded-full" style={{ "fontSize": "0.7rem" }}>
                            arrow_forward
                        </span></div>
                    </div>

                    <div className='my-6 flex items-center justify-end'>
                        <button className='flex  items-center text-white px-1 shadow-md py-1' style={{ "background": "#262626", "borderTopLeftRadius": "0.2rem", "borderBottomLeftRadius": "0.2rem" }}><span className="material-symbols-outlined ml-1">
                            expand_more
                        </span></button>
                        <button className='flex  items-center text-white px-1 shadow-md py-1' style={{ "background": "#262626", "marginLeft": "1px", "borderTopRightRadius": "0.2rem", "borderBottomRightRadius": "0.2rem" }}><span className="material-symbols-outlined ml-1">
                            expand_less
                        </span></button>
                    </div>

                </div>

            </Card>
            {/* about us Main Card END */}

            {/* bottomer */}
            <div className='flex justify-center '>
                <div className='rounded-tl-lg cursor-pointer rounded-tr-lg flex justify-center items-center' style={{ "width": "53.5%", "margin": "auto", "height": "7rem", "background": "#262626" }}>
                    <p className='requst_bottom_para flex justify-center items-center'><span>Contact</span> <span className="material-symbols-outlined ml-5">
                        arrow_forward
                    </span></p>
                </div>
            </div>

            <Footer />

        </>
    );
}

export default RefundRequest2
import React, { useState } from "react";
import styled from "styled-components";
import { registerPost } from "../../../apis/api";
import axios from "axios";
import { Link } from "react-router-dom";
import Blue_button from "../../../components/buttons/Blue_button";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import Para from "../../../components/layouts/introPara/Para";
import InputFeild from "../../../components/layouts/InputFeilds/InputFeild";
import { WiDirectionRight } from "react-icons/wi";

const RStep9 = () => {
  // iniatize state variables...
  const [inputData, setInputData] = useState({
    FName: "",
    LName: "",
  });

  // when user user will input data in input box given function will be run...
  function inputChange(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  }

  // login function
  async function RPost(event) {
    event.preventDefault();

    // post login form data to backend..
    await axios
      .post(registerPost, JSON.stringify(inputData))
      .then((response) => {
        console.log(`Register response= ${response}`);
      })
      .catch((error) => {
        console.log(`Register error = ${error}`);
      });
  }
  return (
    <div className="flex items-center w-full h-[100vh] justify-center bg-gray-100 ">
      <Row className="w-[45%] bg-white rounded-lg py-8 h-[38rem]">
        <div className="px-8 flex">
          <div className="w-40 flex justify-center items-center font-Cardo font-normal text-xs underline">
            <Link to="/login">Skip for now </Link>
            <span>
              <WiDirectionRight />
            </span>
          </div>
        </div>
        <div className="px-36 flex items-center flex-col justify-center h-[30rem]">
          <div>
            <MainHeading HeadinName="Fill KYC form" />
            <Para Detail="Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio" />
          </div>
          <div className="w-[100%] ">
            <InputFeild
              label="Pan Card No."
              onChange={(e) => {
                inputChange(e);
              }}
              type="number"
              name="FName"
              placeholder="Example 9573 8102 9543"
            />
          </div>
          <div className="pass w-[100%] relative mt-[1.375rem]">
            <InputFeild
              label="Upload Pan Card"
              onChange={(e) => {
                inputChange(e);
              }}
              type="file"
              name="LName"
              placeholder="Example Volkov"
            />
            {/* input field of company_name END */}
          </div>

          <div className="w-full mt-[2.5rem]">
            <Link to="/login">
              <Blue_button
                name="Continue"
                onClick={(e) => {
                  RPost();
                }}
              />
            </Link>
          </div>
          <div className="mt-[0.625rem]">
            <p className="font-Inter font-normal text-[rgba(0,0,0,1)] text-base">
              Already have an account?
              <span
                style={{ color: "black", fontWeight: "600", cursor: "pointer" }}
              >
                <Link to="/login">Login</Link>
              </span>{" "}
            </p>
          </div>
        </div>
      </Row>
    </div>
  );
};
const Row = styled.div`
  box-shadow: -2px 2px 2px #8080805c;
  border: 1px solid #80808024;
  background-color: #ffffff;
  border-radius: 15px;
`;
export default RStep9;

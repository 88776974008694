import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import HeadWithUnderline from "../../components/layouts/HeadingUnderline/HeadWithUnderline";
import SecondNavbar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/layouts/Footer/Footer'
import ls from 'localstorage-slim'
import TopSection from '../../TopSection'

function Checkout() {
  const nav = useNavigate();
  let userAllData = ls.get("login_data_object", { decrypt: true });
  let name = userAllData.user.name;
  let phone = userAllData.user.mobile;
  let email = userAllData.user.email;
  const [inputData, setInputData] = useState({
    "fname": name,
    "phone": phone,
    "email": email
  })

  // changeInputChange(e)
  function changeInputChange(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value })
    console.log(inputData);
  }

  // continueFunction()
  function continueFunction(e) {
    if ((inputData.fname).length < 3 && (inputData.fname).length < 30) {
      window.alert("name must be include characters between 3 to 30")
    } else if ((inputData.phone).length != 10) {
      window.alert("phone number has only 10 digits")
    } else if ((inputData.email).length == 0) {
      window.alert("please fill email address")
    } else {
      ls.set('checkoutData', inputData);
      nav('/Continue')
    }
  }

  return (
    <>
      <div className="">
        <div className="nav">
          <SecondNavbar />
        </div>

        <TopSection
          bigi={"Checkout"}
          small={"lorem lorem lorem jhfge xuhfd gf"}
        >
        </TopSection>

        <div className="parent-elem relative my-16">
          <div>
            <div className="main-Box-Vehicle bg-[#fafafa] flex flex-col justify-center items-center ">
              <div className="bg-white w-[90%] flex flex-col justify-center items-center Semi-Main-Box lg:w-[55%] gap-8 pb-4 rounded-md">
                <div className="h-2 bg-black w-full top-0 rounded-b-none rounded-t-md"></div>

                <div className="heading text-3xl font-Cardo font-bold lg:text-[40px] w-full px-8">
                  <h1>You want to edit your details</h1>
                </div>
                <div className="second-Section flex flex-col gap-7 w-[85%]">


                  <div className="inputItem flex flex-col gap-2">
                    <label>First Name<span style={{"color":"red"}}>*</span></label>
                    <input
                      className=" border-2 border-black rounded-md w-[100%] p-1 pl-2"
                      placeholder="Charlie"
                      name="fname"
                      onChange={(e) => { changeInputChange(e) }}
                      value={inputData.fname}
                    />
                  </div>

                  <div className="inputItem flex flex-col gap-2">
                    <label>Phone Number <span style={{"color":"red"}}>*</span></label>
                    <input
                      type="number"
                      className=" border-2 border-black rounded-md w-[100%] p-1 pl-2"
                      placeholder="+91-9873556792"
                      name={"phone"}
                      onChange={(e) => { changeInputChange(e) }}
                      value={inputData.phone}
                    />
                  </div>

                  <div className="inputItem flex flex-col gap-2">
                    <label>Email Id <span style={{"color":"red"}}>*</span></label>
                    <input
                      type="email"
                      className=" border-2 border-black rounded-md w-[100%] p-1 pl-2"
                      placeholder="charliebotosh@gmail.com"
                      name={"email"}
                      onChange={(e) => { changeInputChange(e) }}
                      value={inputData.email}
                    />
                  </div>

                  <div className="flex gap-between">
                    <div className="flex bg-black text-white rounded-md px-10 py-1">

                      <Button className="w-[100%] text-white " onClick={() => continueFunction()}>
                        <span className="text-white">Continue</span>
                      </Button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Checkout;

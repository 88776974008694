import React, { useState, useRef, useEffect } from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import HeadingWithText from "../../components/final_components/HeadingWithText/HeadingWithText";
import "./TrackVehicle.css";
import { Link, useNavigate } from "react-router-dom";
import ls from "localstorage-slim";
import { isMobile } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  getVehicleListForUser,
  trackVehicle,
  fullVehicleList,
  searchTrack,
  shareRoute,
} from "../../apis/api";
import { addVehicleTrackData } from "../../redux/slices/vehicleTrackData";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../../components/layouts/Footer/Footer";
import { RWebShare } from "react-web-share";
import Tooltip from "@mui/material/Tooltip";
import MobileTrack from "./MobileTrack";
import MobileWhenClickTrack from "./Mobile_WhenClickTrack";

const WhenClickTrack = () => {
  const walletBalance = useSelector((state) => state.WalletBalance.value);
  const walletBalanceFromLocal = ls.get("walletBalance", { decrypt: true });
  const [sharingLink, setSharingLink] = useState();

  let balanceOBJ;
  if (walletBalance) {
    balanceOBJ = walletBalance;
  } else {
    balanceOBJ = walletBalanceFromLocal;
  }

  // state hit suggestion
  const iLogin = ls.get("iLogin");
  const [showSuggestion, setShowSuggestion] = useState(false);
  let stateSuggestionData = [];
  let [shareLinkOfTrack, setShareLinkOfTrack] = useState();
  let state_suggesREF = useRef();
  if (!ls.get("stateSuggestionData")) {
    ls.set("stateSuggestionData", stateSuggestionData);
  }
  let stateArray = [];
  if (ls.get("stateSuggestionData")) {
    stateArray = ls.get("stateSuggestionData");
  }

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  // onFocusVehicleNumber(e)
  function onFocusVehicleNumber(e) {
    e.preventDefault();
    setStateSuggChecker(false);
    !stateSuggChecker ? setShowSuggestion(true) : setShowSuggestion(false);
  }

  const [stateSuggChecker, setStateSuggChecker] = useState(true);

  // stackClicking(e)
  function stackClicking(e, elms) {
    inputElement.current.value = elms;
    vehicleInputChange(elms, true);
    setShowSuggestion(false);
  }

  //   state suggestion hint END

  let [apiData, setApiData] = useState([]);
  let login_response_object, token_user, user_id;
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }
  let shareLink = useRef();

  useEffect(() => {
    axios
      .get(`${fullVehicleList}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setApiData(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${searchTrack}/${vehicleSearchId}/share`)
      .then((res) => {
        setShareLinkOfTrack(res.data.data.share_link.slice(26, -1));
        setIsLoader(false);
        setSharingLink(res.data.data.share_link.slice(26, -1));
        console.log(res.data.data.share_link);
      })
      .catch((err) => {
        console.log(err);
        setIsLoader(false);
      });
  }, []);

  const dispatch = useDispatch();
  const buttonGetVehicle = useRef();
  const inputElement = useRef();

  let vehicleSearchId;
  let trackVehicleData = useSelector((state) => state.vehicleTrackData.value);
  const [isLoader, setIsLoader] = useState();
  let trackVehicleDatafromLocalStorage = ls.get("trackVehicleData", {
    decrypt: true,
  });
  if (trackVehicleData == "0") {
    trackVehicleData = trackVehicleDatafromLocalStorage;
  }
  let mainDataArray, trackinNumber, vehical_number;
  if (iLogin) {
    mainDataArray = trackVehicleData.txn;
    vehicleSearchId = trackVehicleData;
    vehicleSearchId = trackVehicleData._id;
    trackinNumber = trackVehicleData.tracking_no;
    vehical_number = trackVehicleData.vehicle_no;
  }
  console.log(mainDataArray, "loooo");

  const [isSubscribe, setIsSubscribe] = useState(true);
  const [isSubscribeShow, setIsSubscribeShow] = useState(false);
  const [isShare, setIsShare] = useState(false);

  const nav = useNavigate();

  let track_vehicle_no, averageSpeed, averageDistance;

  if (iLogin) {
    track_vehicle_no = ls.get("track_vehicle_no", { decrypt: true });
    averageSpeed = trackVehicleDatafromLocalStorage.speed;
    averageDistance = trackVehicleDatafromLocalStorage.distance;
  }

  const [vehicle_nos, setVehicle_nos] = useState({
    vehicle_no: track_vehicle_no,
    u_id: user_id,
  });

  // vehicleInputChange(e)
  function vehicleInputChange(e, check) {
    if (check) {
      setVehicle_nos({ ...vehicle_nos, vehicle_no: e });
      // console.log("withTrue"+vehicle_no);
    } else {
      e.preventDefault();
      const value = e.target.value.replace(/\s/g, "").toUpperCase();
      setVehicle_nos({ ...vehicle_nos, [e.target.name]: value });
      // console.log("withFalse"+vehicle_no);
    }
  }

  // clickTrackFunction(e)
  // function clickTrackFunction(e) {
  //     setIsLoader(true);
  //     // window.alert("It will be again deduct 1 TC.Do You want to Proceed?");

  //     //   console.log("real_redits-console",balanceOBJ)
  //         if (balanceOBJ.credits < 1) {
  //             window.alert("please buy plan");
  //             nav('/all-plans')
  //         } else {
  //             fetch(trackVehicle, {
  //                 method: 'POST',
  //                 headers: {
  //                   'Content-Type': 'application/json',
  //                   'Authorization': `Hello ${token_user}`,
  //                 },
  //                 body: JSON.stringify(vehicle_nos),
  //               })
  //                 .then(function (response) {
  //                   if (response.ok) {
  //                     return response.json();
  //                   } else {
  //                     throw new Error(`HTTP error! Status: ${response.status}`);
  //                   }
  //                 })
  //                 .then(function (data) {
  //                   if (data.success === true) {
  //                     dispatch(addVehicleTrackData(data.data));
  //                     ls.set('trackVehicleData', data.data, { encrypt: true });
  //                     setIsLoader(false);
  //                     nav('/when-click-track');
  //                   }
  //                 })
  //                 .catch(function (error) {
  //                   setIsLoader(false);
  //                   window.alert(`Vehicle no. not available in ulip or vehicle has not crossed any in the last 72 hours or incorrect vehicle number: ${error.message}`);
  //                 });

  //     }
  // }
  function clickTrackFunction() {
    // axios post
    axios
      .post(trackVehicle, vehicle_nos, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then(function (response) {
        if (response.data.success === true) {
          dispatch(addVehicleTrackData(response.data.data));
          ls.set("trackVehicleData", response.data.data, { encrypt: true });
          setIsLoader(false);
          nav("/when-click-track");
          window.alert("1 credit deducted");
          window.location.reload();
        }
      })
      .catch(function (error) {
        setIsLoader(false);
        if (error.response.status) {
          window.alert(
            `Vehicle no. not available in ulip or vehicle has not crossed any in last 72 hours or incorrect vehicle number`
          );
        }
        console.log(error);
      });
  }

  // refresh vehical from server..
  function refreshVehicleTrackData() {
    // axios post
    axios
      .post(trackVehicle, vehicle_nos, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then(function (response) {
        if (response.data.success === true) {
          dispatch(addVehicleTrackData(response.data.data));
          ls.set("trackVehicleData", response.data.data, { encrypt: true });
          setIsLoader(false);
          nav("/when-click-track");
          window.alert("1 credit deducted");
          window.location.reload();
        }
      })
      .catch(function (error) {
        setIsLoader(false);
        if (error.response.status) {
          window.alert(
            `Vehicle no. not available in ulip or vehicle has not crossed any in last 72 hours or incorrect vehicle number`
          );
        }
        console.log(error);
      });
  }

  // buySubscribePopup
  function buySubscribePopup() {
    setIsSubscribeShow(true);
  }

  // ShareFunction(e) 26april
  // function ShareFunction(e) {
  //     e.preventDefault();
  //     if (isSubscribe) {
  //         setIsLoader(true)
  //         console.log(`${searchTrack}/${vehicleSearchId}/share`);
  //         axios.get(`${searchTrack}/${vehicleSearchId}/share`).then(res => {
  //             setShareLinkOfTrack(res.data.data.share_link.slice(26, -1));
  //             setIsLoader(false)
  //             console.log(res.data.data.share_link);
  //         }).catch(err => {
  //             console.log(err);
  //             setIsLoader(false)
  //         })
  //     } else {
  //         buySubscribePopup();
  //     }
  // }

  // copyToclipboard
  function copyToclipboard(e) {
    // Get the text field
    var copyText = document.getElementById("copyInput");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
  }

  // getVehicleInformation(e)
  async function getVehicleInformation(e) {
    let shouldTermine = false;
    if (isSubscribe) {
      apiData.map((elm) => {
        if (elm.vin == vehicle_nos.vehicle_no) {
          shouldTermine = true;
          nav("/ListOfVehicle");
        }
      });

      if (!shouldTermine) {
        nav("/AddVehicle");
      }
    } else {
      buySubscribePopup();
    }
  }

  // viewLinkFunction(e)
  function viewLinkFunction(e) {
    e.preventDefault();
    if (isSubscribe) {
      nav("/track");
    } else {
      buySubscribePopup();
    }
  }

  // close sub popup
  function closeSubPop() {
    setIsSubscribeShow(false);
  }

  //close closeSharePop
  function closeSharePop() {
    setIsShare(false);
  }

  // planYourJourneyFunction(e)
  function planYourJourneyFunction(e) {
    if (isSubscribe) {
      nav("/MyJourney");
    } else {
      buySubscribePopup();
    }
  }

  return (
    <>
      {isMobile ? (
        <>
          <MobileWhenClickTrack />
        </>
      ) : (
        <>
          <div
            onClick={() => {
              setStateSuggChecker(true);
              stateSuggChecker
                ? setShowSuggestion(false)
                : setShowSuggestion(true);
            }}
          >
            {/* navbar START*/}
            <SecondNavBar />
            {/* navbar END */}

            {/* top section Component */}
            <TopSection bigi={vehical_number} small={"Last toll crossing data"}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  "alignItems": "flex-end",
                  flexDirection: "column",
                }}
              >
                <p id="copy_lab" style={{ marginTop: "1rem" }}>
                  copy link to share
                </p>
                <input
                  value={`https://lorrycare.com${shareLinkOfTrack}`}
                  id="copyInput"
                />
              </div>
            </TopSection>
            {/* top section Component END*/}

            {/* card */}
            <Card>
              {/* {console.log("mainDataArray"+JSON.stringify(mainDataArray))} */}
              {iLogin ? (
                mainDataArray.map((elm, ind) => {
                  console.log(elm, "elmdfgh");
                  if (ind == 0) {
                    return (
                      <>
                        <div className="flex justify-between items-center auto_renew_wrapper">
                          <h1 className="heading_whenclick">{`Crossed ${
                            elm.plaza
                          } at ${elm.readerReadTime.slice(11, 19)}`}</h1>
                          <button
                            className="refresh_whenclick flex justify-between items-center"
                            onClick={() => {
                              refreshVehicleTrackData();
                            }}
                          >
                            <p>Refresh</p>
                            <span className="ml-5 material-symbols-outlined">
                              autorenew
                            </span>
                          </button>
                          <h1
                            className="mt-5 w-[100%] justify-end please_scroll"
                            style={{ "display": "none" }}
                          >
                            <p>scroll for view</p>{" "}
                            <span className="material-symbols-outlined">
                              arrow_forward
                            </span>
                          </h1>
                        </div>
                        <div>
                          <div
                            className="tracker_row "
                            style={{
                              marginTop: "1rem",
                              display: "flex",
                              justifyContent: "space-between",
                              "alignItems": "start",
                            }}
                          >
                            {/* <div className="flex items-center">
                                        <div className="">
                                            <span
                                                className="material-symbols-outlined"
                                                style={{ fontSize: "50px" }}
                                            >
                                                location_on
                                            </span>
                                        </div>


                                        <div className="city" style={{ "maxWidth": "13.5rem" }}>
                                            <h1 className="w-[100%]" style={{ fontWeight: "500" }}>{elm.plaza}</h1>
                                        </div>
                                    </div> */}

                            <div
                              className="track_small_detail_wrapper"
                              style={{ width: "70%", overflow: "scroll" }}
                            >
                              <div
                                className="track_small_detail"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  "align-items": "center",
                                  width: "40rem",
                                }}
                              >
                                <div
                                  className="checkinDate"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "0.8rem",
                                      fontWeight: "400",
                                      marginTop: "0.4rem",
                                    }}
                                  >
                                    Date
                                  </span>
                                  <b
                                    style={{
                                      fontSize: "0.9rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {elm.readerReadTime.slice(0, 10)}
                                  </b>
                                </div>

                                <div
                                  className="checkinTime"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "0.8rem",
                                      fontWeight: "400",
                                      marginTop: "0.4rem",
                                    }}
                                  >
                                    Time
                                  </span>
                                  <b
                                    style={{
                                      fontSize: "0.9rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {elm.readerReadTime.slice(11, 19)}
                                  </b>
                                </div>

                                <div
                                  className="timeLapse"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "0.8rem",
                                      fontWeight: "400",
                                      marginTop: "0.4rem",
                                    }}
                                  >
                                    City
                                  </span>
                                  <b
                                    style={{
                                      fontSize: "0.9rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {elm.city}
                                  </b>
                                </div>

                                <div
                                  className="timeLapse"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "0.8rem",
                                      fontWeight: "400",
                                      marginTop: "0.4rem",
                                    }}
                                  >
                                    State
                                  </span>
                                  <b
                                    style={{
                                      fontSize: "0.9rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {elm.state}
                                  </b>
                                </div>

                                <div
                                  className="timeLapse"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "0.8rem",
                                      fontWeight: "400",
                                      marginTop: "0.4rem",
                                    }}
                                  >
                                    Tracking No.
                                  </span>
                                  <b
                                    style={{
                                      fontSize: "0.9rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {trackinNumber}
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                })
              ) : (
                <></>
              )}

              {/* here */}

              <div className="flex justify-end mt-0">
                <button
                  onClick={(e) => {
                    viewLinkFunction(e);
                  }}
                  style={{
                    "textDecoration": "underline",
                    "fontWeight": "bold",
                  }}
                >
                  View Last 72 Hour Journey
                </button>
              </div>

              <div className="flex -mt-3">
                <Tooltip
                  title="You can share your vehicle tracking data to another"
                  placement="bottom"
                >
                  <button
                    style={{
                      "textDecoration": "none",
                      "display": "inline-block",
                      "background": "#232323",
                      "color": "white",
                      "borderRadius": "0.3rem",
                      "padding": "0.4rem 2rem",
                      "border": "none",
                      "cursor": "pointer",
                      "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      "width": "49%",
                      "marginTop": "1.5rem",
                    }}

                    // onClick={(e) => { ShareFunction(e); }}
                  >
                    <RWebShare
                      data={{
                        url: `${shareRoute}${shareLinkOfTrack}`,
                        title: "search vehicle track",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <button style={{ "width": "100%" }}>Share🔗</button>
                    </RWebShare>
                  </button>
                </Tooltip>

                <Tooltip title="Create your own Journey" placement="bottom">
                  <button
                    className="hoverOnwithoutBackButton"
                    style={{
                      "textDecoration": "none",
                      "display": "inline-block",
                      "color": "black",
                      "background": "none",
                      "borderRadius": "0.3rem",
                      "padding": "0.4rem 2rem",
                      "border": "1px solid black",
                      "cursor": "pointer",
                      "marginLeft": "1rem",
                      "width": "49%",
                      "marginTop": "1.5rem",
                    }}
                    onClick={(e) => {
                      planYourJourneyFunction(e);
                    }}
                  >
                    Plan your Journey
                  </button>
                </Tooltip>
              </div>
            </Card>

            <div className="-mt-10">
              {/* topper black section */}
              <div
                className="flex justify-center flex-col bg-white tooper-black"
                onClick={() => {
                  setStateSuggChecker(true);
                  stateSuggChecker
                    ? setShowSuggestion(false)
                    : setShowSuggestion(false);
                }}
              >
                <div className="whenclick_topper flex justify-between items-center mt-[4px] ">
                  <h1 className="text-black font-Cardo">
                    <p className="font-Cardo font-normal text-black text-start ">
                      Track another Vehicle
                    </p>
                  </h1>
                </div>

                <div className="mt-2 flex justify-between track_again_wrapper">
                  <div
                    className="relative track_again_wrapper_input_wrapper"
                    style={{ "width": "75%" }}
                  >
                    <input
                      ref={inputElement}
                      onFocus={(e) => {
                        onFocusVehicleNumber(e);
                      }}
                      name="vehicle_no"
                      value={vehicle_nos.vehicle_no}
                      onChange={(e) => {
                        vehicleInputChange(e, false);
                      }}
                      type="text"
                      className="w-1/1 rounded px-5 bg-white"
                      style={{
                        "width": "100%",
                        "outline": "none",
                        "height": "40px",
                      }}
                    />
                    <div
                      className="suggestion__box"
                      style={
                        showSuggestion
                          ? {
                              "display": "flex",
                              "alignItems": "start",
                              "flexDirection": "column",
                              "boxShadow":
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }
                          : { "display": "none" }
                      }
                    >
                      <div
                        className="h-[10rem] w-[100%] overflow-auto suggestion_inner_div"
                        style={{}}
                      >
                        {removeDuplicates(stateArray).map((elm) => {
                          return (
                            <div
                              ref={state_suggesREF}
                              className="font-normal text-black text-start hover_on_suggestion w-[100%] uppercase mt-5"
                              onClick={(e) => {
                                stackClicking(e, elm);
                              }}
                            >
                              {elm}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ "width": "20%" }}
                    className={"track_again_wrapper_button_wrapper"}
                  >
                    <button
                      onClick={() => {
                        clickTrackFunction();
                      }}
                      className="rounded font-Cardo trackAgain"
                      style={{
                        "background": "white",
                        "width": "100%",
                        "height": "40px",
                        "boxShadow": "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                    >
                      Track again
                    </button>
                  </div>
                </div>
              </div>
              <ul className="-mt-5 w-[73.5%] m-auto">
                <li className="text-start" style={{ "fontSize": "10px" }}>
                  The data provided for this vehicle number is sourced from
                  ULIP, and we do not take responsibility for its accuracy or
                  completeness
                </li>
                {/* <li className="text-start" style={{ "fontSize": "10px" }}>The data for this vehicle number is only valid for a period of 3 months from the time of entry.</li> */}
              </ul>
            </div>

            <Footer />

            {/* all popup */}

            {/* Oops ! You can’t access full journey. Buy subscription plan for view full journey. */}
            <div
              style={
                isSubscribeShow ? { "display": "block" } : { "display": "none" }
              }
            >
              <section className="pop_up_main_wrapper">
                <div className="main_popup_1">
                  <div className="close_icon flex justify-end">
                    <span
                      onClick={() => {
                        closeSubPop();
                      }}
                      className="material-symbols-outlined"
                      style={{
                        "background": "#F8F9FA",
                        "borderRadius": "50%",
                        "padding": "0.1rem",
                        "cursor": "pointer",
                      }}
                    >
                      close
                    </span>
                  </div>

                  <div className="flex flex-col justify-center items-center">
                    <div className="icon_wrapper">
                      <span
                        className="material-symbols-outlined"
                        style={{ "fontSize": "3rem" }}
                      >
                        check_box
                      </span>
                    </div>

                    <div>
                      <p className="track_popup1_mess">
                        Oops ! You can’t access full journey. Buy subscription
                        plan for view full journey.
                      </p>
                    </div>

                    <div>
                      <button
                        className="track_whenclick_button"
                        onClick={() => {
                          nav("/Plans");
                        }}
                      >
                        Buy Plan
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* share popup 2 */}
            <div
              style={isShare ? { "display": "block" } : { "display": "none" }}
            >
              <section className="pop_up_main_wrapper">
                <div className="main_popup_2">
                  <div className="close_icon flex justify-between items-start">
                    <p className="track_popup1_mess2 font-bold">Share</p>
                    <span
                      onClick={() => {
                        closeSharePop();
                      }}
                      className="material-symbols-outlined"
                      style={{
                        "background": "#F8F9FA",
                        "borderRadius": "50%",
                        "padding": "0.1rem",
                        "cursor": "pointer",
                      }}
                    >
                      close
                    </span>
                  </div>

                  <div className="mt-5 flex justify-between">
                    <div>
                      <RWebShare
                        data={{
                          url: shareLinkOfTrack,
                          title: "search vehicle track",
                        }}
                        onClick={() => console.log("shared successfully!")}
                      >
                        <button>Share on any social media 🔗</button>
                      </RWebShare>
                    </div>
                    {/* <div className="share_social_wrapper flex justify-center items-center cursor-pointer"><i className="fa fa-instagram" style={{ "fontSize": "24px" }}></i></div>
                            <div className="share_social_wrapper flex justify-center items-center cursor-pointer"><i className="fa fa-facebook-official" style={{ "fontSize": "24px" }}></i></div>
                            <div className="share_social_wrapper flex justify-center items-center cursor-pointer"><i className="fa fa-whatsapp" style={{ "fontSize": "24px" }}></i></div>
                            <div className="share_social_wrapper flex justify-center items-center cursor-pointer"><span className="material-symbols-outlined" style={{ "fontSize": "24px" }}>
                                comment
                            </span></div> */}
                  </div>

                  <div className="mt-5">
                    <p>Page Link</p>
                    <div
                      className={
                        "mt-5 overflow-auto flex justify-between items-center"
                      }
                      style={{ "width": "100%", "background": "#F8F9FA" }}
                    >
                      <input
                        className="pl-3 pr-1 overflow-auto text-xs"
                        style={{ "width": "80%" }}
                        ref={shareLink}
                        id="myInput"
                        value={shareLinkOfTrack}
                      />
                      <div
                        className="cursor-pointer ml-5 my-1"
                        style={{ "width": "20%" }}
                        onClick={(e) => {
                          copyToclipboard(e);
                        }}
                      >
                        <span className="material-symbols-outlined">
                          content_copy
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* loader */}
            <section
              style={
                isLoader
                  ? { "display": "flex", "zIndex": "999999999" }
                  : { "display": "none" }
              }
              id="home_loader"
              className="absolute top-0 left-0 w-[100%] h-[100%] bg-white opacity-50 flex justify-center items-center"
            >
              <CircularProgress />
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default WhenClickTrack;

import React, { useState, useEffect } from "react";
import "../Navbar.css";
import NavIcon from "../../../assets/svg/icons/NavIcon2";
import { BsChevronDown } from "react-icons/bs";
import ls from "localstorage-slim";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import { useSelector, useDispatch } from "react-redux";
import { addBool } from "../../../../redux/slices/mouseOnVehicle";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import ResponsiveNavbar from "../ResponsiveNavbar";
import { logOut } from "../../../../apis/api";
import axios from "axios";
import { Switch } from "@mui/material";
import { changeLanguage } from "../../../../redux/slices/translate";
import { addBoolean } from "../../../../redux/slices/hoverMenu";

function SecondNavbar({
  translationHide,
  active1,
  active2,
  active3,
  active4,
  active5,
  active6,
  active7,
  active8,
}) {
  const eng = useSelector((state) => state.translate.value);
  const [isDH, setIsDH] = useState(false);
  let totalNotification;
  if (ls.get("totalNoty")) {
    totalNotification = ls.get("totalNoty");
  } else {
    totalNotification = 0;
  }

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );

    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  const walletBalance = useSelector((state) => state.WalletBalance.value);
  const walletBalanceFromLocal = ls.get("walletBalance", { decrypt: true });

  let balanceOBJ;
  if (walletBalance) {
    balanceOBJ = walletBalance;
  } else {
    balanceOBJ = walletBalanceFromLocal;
  }

  const dispatch = useDispatch();
  const nav = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const iLogin = ls.get("iLogin");
  let login_response_object, token_user, user_id;
  let FirstLetterOfName;
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    FirstLetterOfName = login_response_object.user.name
      .slice(0, 1)
      .toUpperCase();
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  } else {
    FirstLetterOfName = "_";
  }

  // profile dropdown START
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // notification option START
  const [open1, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    // if (open1) {
    //   mouseOutFunc();
    // } else {
    //   mouseOverFunc();
    // }
  };

  // focusOnNotification(e)
  function focusOnNotification(e) {
    mouseOverFunc();
  }

  function blurOnNotification(params) {
    mouseOutFunc();
  }

  const handleClose1 = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  // notification option END

  // Logout()
  function Logout() {
    axios
      .put(`${logOut}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log(res);
      });
    localStorage.clear();
    nav("/login");
  }

  // profile dropdown states END

  const MouseHover = () => {
    const ShowData = (document.getElementById("SetEffect").style.display =
      "initial");
    if (ShowData) {
      setTimeout(() => {
        document.getElementById("SetEffect").style.display = "none";
      }, 2000);
    }
  };

  let valueB11 = useSelector((state) => state.mouseOnVehicle.value);
  if (!valueB11) {
    valueB11 = true;
  }
  function mouseOverFunc() {
    dispatch(addBool(true));
    setIsDH(valueB11);
    console.log(valueB11);
  }
  function mouseOutFunc() {
    dispatch(addBool(false));
    setIsDH(valueB11);
    console.log(valueB11);
  }

  return (
    <>
      <div id="google_translate_element" style={{ display: "none" }}></div>
      <div className="desktop_navbar_1 ">
        <nav
          id="navbar_main"
          className="navbar w-full bg-transparent shadow flex h-[5rem]"
        >
          <div className="justify-between w-[100%] px-4 mx-auto lg:justify-evenly lg:items-center lg:flex lg:px-8">
            <div className="">
              <div className="flex items-center justify-between py-3 lg:py-5 lg:block">
                <Link to={"/"} className="logo w-[20%]">
                  {/* <logo /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.0"
                    width="6rem"
                    viewBox="0 0 584.000000 427.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <metadata>
                      Created by potrace 1.16, written by Peter Selinger
                      2001-2019
                    </metadata>
                    <g
                      transform="translate(0.000000,427.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path d="M3020 4256 c-168 -26 -352 -90 -484 -168 -215 -126 -410 -330 -521 -545 -24 -47 -30 -52 -63 -55 l-37 -3 0 -65 0 -65 143 -3 142 -3 0 70 c0 60 -2 69 -20 74 -11 3 -20 10 -20 15 0 27 114 177 199 262 200 199 423 308 711 347 95 13 136 13 232 4 318 -30 545 -136 764 -355 181 -182 291 -389 341 -646 27 -137 22 -357 -10 -496 -113 -492 -468 -840 -962 -945 -93 -20 -362 -17 -463 4 -249 55 -430 153 -608 331 -103 103 -213 251 -197 267 4 4 53 10 108 11 l100 3 0 70 0 70 -255 0 -255 0 -3 -64 c-4 -75 1 -81 75 -81 l51 0 40 -73 c187 -340 501 -574 902 -672 105 -26 375 -31 501 -10 557 93 994 519 1111 1083 31 150 31 402 0 549 -119 558 -530 971 -1073 1078 -112 22 -341 28 -449 11z" />
                      <path d="M2300 3420 l0 -70 180 0 180 0 0 -60 0 -60 -225 0 -225 0 0 -75 0 -75 225 0 225 0 0 -60 0 -60 -365 0 -365 0 0 -70 0 -70 365 0 365 0 0 -60 0 -60 -130 0 -130 0 0 -70 0 -70 130 0 130 0 0 -60 0 -60 -90 0 -90 0 0 -75 0 -75 150 0 150 0 0 600 0 600 -240 0 -240 0 0 -70z" />
                      <path d="M2880 2891 l0 -601 94 0 c78 0 97 3 119 20 23 18 25 27 29 112 4 111 21 152 93 223 68 69 113 89 210 93 103 5 172 -19 239 -84 69 -66 100 -138 101 -242 2 -113 5 -116 147 -120 128 -4 178 7 207 45 20 24 21 39 21 280 0 199 -3 260 -14 281 -23 45 -163 86 -363 108 l-162 17 -75 136 c-75 136 -75 137 -146 179 -38 23 -73 42 -75 42 -3 0 -29 16 -58 36 -85 59 -111 66 -244 72 l-123 4 0 -601z m439 415 c19 -10 50 -54 99 -140 40 -69 72 -128 72 -131 0 -3 -119 -5 -265 -5 l-265 0 0 145 0 145 165 0 c125 0 173 -4 194 -14z" />
                      <path d="M1700 3155 l0 -75 190 0 190 0 0 75 0 75 -190 0 -190 0 0 -75z" />
                      <path d="M1560 2890 l0 -70 125 0 125 0 0 70 0 70 -125 0 -125 0 0 -70z" />
                      <path d="M2120 2630 l0 -70 75 0 75 0 0 70 0 70 -75 0 -75 0 0 -70z" />
                      <path d="M3390 2669 c-138 -26 -233 -172 -200 -307 23 -94 99 -170 193 -193 172 -41 340 119 307 293 -13 65 -31 97 -83 145 -61 56 -134 77 -217 62z m136 -163 c30 -30 34 -41 34 -84 0 -116 -129 -172 -208 -89 -54 58 -50 132 9 182 24 19 42 25 81 25 44 0 54 -4 84 -34z" />
                      <path d="M665 723 c-96 -35 -171 -103 -214 -193 -36 -76 -42 -211 -13 -288 99 -260 428 -324 616 -121 77 83 99 146 94 269 -3 77 -9 104 -30 145 -32 63 -122 149 -185 177 -68 30 -200 35 -268 11z m224 -142 c48 -25 95 -79 111 -126 20 -61 9 -165 -23 -214 -42 -64 -97 -95 -176 -99 -145 -8 -244 84 -244 228 0 96 44 169 128 213 44 24 156 22 204 -2z" />
                      <path d="M3593 726 c-100 -33 -169 -92 -221 -189 -25 -48 -27 -61 -27 -167 0 -107 2 -119 28 -168 34 -64 100 -133 154 -160 147 -74 332 -44 439 70 25 27 42 53 38 58 -5 4 -32 22 -60 38 l-52 30 -20 -28 c-31 -42 -98 -70 -165 -70 -139 0 -228 90 -229 231 -1 87 29 150 91 194 37 26 53 30 120 33 88 4 141 -15 181 -65 l21 -27 52 28 c29 16 56 32 61 36 15 14 -76 101 -139 132 -50 26 -74 31 -145 34 -55 3 -100 -1 -127 -10z" />
                      <path d="M0 375 l0 -355 205 0 205 0 0 65 0 65 -135 0 -135 0 0 290 0 290 -70 0 -70 0 0 -355z" />
                      <path d="M1250 375 l0 -355 70 0 70 0 0 120 0 121 57 -3 57 -3 67 -115 67 -115 77 -3 77 -3 -20 33 c-102 172 -134 232 -123 236 7 2 29 18 49 36 101 88 98 260 -6 346 -61 50 -108 60 -284 60 l-158 0 0 -355z m325 211 c41 -18 55 -42 55 -98 -1 -79 -45 -108 -166 -108 l-74 0 0 110 0 110 75 0 c43 0 90 -6 110 -14z" />
                      <path d="M1880 375 l0 -355 70 0 70 0 0 120 0 120 53 0 53 0 69 -120 69 -120 73 0 c40 0 73 3 73 6 0 3 -31 59 -70 124 -38 65 -70 123 -70 128 0 5 15 20 34 33 130 89 123 283 -14 376 -52 36 -95 43 -257 43 l-153 0 0 -355z m320 210 c32 -17 60 -61 60 -95 0 -37 -30 -81 -65 -96 -19 -8 -66 -14 -105 -14 l-70 0 0 110 0 110 75 0 c48 0 87 -6 105 -15z" />
                      <path d="M2423 723 c2 -5 56 -103 120 -218 l117 -211 0 -137 0 -137 70 0 70 0 0 136 0 135 120 213 c66 117 120 216 120 220 0 3 -35 6 -78 6 l-77 0 -15 -32 c-35 -77 -132 -258 -138 -258 -4 0 -41 66 -81 145 l-75 145 -79 0 c-43 0 -76 -3 -74 -7z" />
                      <path d="M4272 703 c-5 -16 -28 -80 -50 -143 -22 -63 -72 -206 -111 -317 -39 -111 -71 -206 -71 -212 0 -7 27 -11 73 -11 l74 0 18 60 18 60 147 0 148 0 11 -30 c6 -17 16 -44 22 -60 11 -29 13 -30 80 -30 38 0 69 2 69 4 0 2 -54 157 -120 344 -66 187 -120 345 -120 351 0 7 -32 11 -89 11 -88 0 -89 0 -99 -27z m148 -283 c24 -69 45 -131 48 -137 3 -10 -21 -13 -98 -13 -77 0 -101 3 -97 13 3 6 24 71 48 144 24 72 47 129 50 125 3 -4 26 -63 49 -132z" />
                      <path d="M4770 375 l0 -355 70 0 70 0 0 120 0 121 57 -3 58 -3 69 -118 68 -117 75 0 c86 0 88 -19 -15 155 -41 70 -60 111 -52 113 6 2 28 18 47 35 108 95 101 265 -15 354 -60 46 -97 53 -274 53 l-158 0 0 -355z m330 207 c77 -38 74 -155 -5 -188 -24 -10 -61 -14 -108 -12 l-72 3 -3 108 -3 107 78 0 c54 0 89 -5 113 -18z" />
                      <path d="M5400 375 l0 -355 220 0 220 0 0 65 0 65 -150 0 -150 0 0 80 0 80 135 0 135 0 0 65 0 65 -135 0 -135 0 0 75 0 75 145 0 145 0 0 70 0 70 -215 0 -215 0 0 -355z" />
                    </g>
                  </svg>
                </Link>
                <div className="lg:hidden">
                  <button
                    className="p-2 rounded-lg outline-none text-white focus:border-gray-400 focus:border"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {isOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-white"
                        viewBox="0 0 20 20"
                        fill="black"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="black"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`flex-1 justify-self-center pb-3 mt-8 lg:block lg:pb-0 lg:mt-0 z-10 ${
                  isOpen ? "block" : "hidden"
                }`}
              >
                <ul className="items-center justify-center space-y-8 lg:flex lg:space-x-6 lg:space-y-0">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className=" relative w-[100%] flex max-sm:justify-center">
                    <div
                      className="dropdown"
                      onMouseOver={() => {
                        mouseOverFunc();
                      }}
                      onMouseOut={() => {
                        mouseOutFunc();
                      }}
                    >
                      <span className="flex items-center gap-1 cursor-pointer">
                        Journey <span className="text-sm">(Beta)</span>{" "}
                        <BsChevronDown />
                      </span>

                      <div className="dropdownItems w-52 absolute bg-white text-black w-50 px-6 py-2 right-[-3.5rem]  rounded-lg max-sm:right-10">
                        <div className="flex flex-col  text-start">
                          <Link
                            to="/MyJourney"
                            className={
                              active4
                                ? "underline font-Inter font-medium"
                                : "font-Inter font-medium"
                            }
                            style={{ fontSize: "13px" }}
                          >
                            Plan Your Journey <span>(Beta)</span>
                          </Link>
                          <span className="font-Inter font-normal text-xs">
                            Create a customized trip
                          </span>
                        </div>
                        <div className="flex flex-col  text-start">
                          <a
                            href="/OngoingJourneys"
                            style={{ fontSize: "13px" }}
                            className={
                              active5
                                ? "underline font-Inter font-medium text-sm"
                                : "font-Inter font-medium text-sm"
                            }
                          >
                            Active Journeys
                          </a>
                          <span className="font-Inter font-normal text-xs">
                            List of all ongoing trips
                          </span>
                        </div>
                        <div
                          className="flex flex-col  text-start"
                          style={{ zIndex: "9999999999999" }}
                        >
                          <a
                            href="/PastJourneys"
                            style={{ fontSize: "13px" }}
                            className={
                              active6
                                ? "underline font-Inter font-medium text-sm"
                                : "font-Inter font-medium text-sm"
                            }
                          >
                            Past Journeys
                          </a>
                          <span className="font-Inter font-normal text-xs">
                            History of closed trips
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    className=""
                    onMouseOver={() => {
                      mouseOverFunc();
                    }}
                    onMouseOut={() => {
                      mouseOutFunc();
                    }}
                  >
                    <Link to="/History" className={active1 ? "underline" : ""}>
                      History
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/all-plans"
                      className={active2 ? "underline" : ""}
                    >
                      Recharge
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/WalletContinue"
                      className={active3 ? "underline" : ""}
                    >
                      Wallet
                    </Link>
                  </li>
                  <li className="relative w-[100%] flex items-center text-center justify-center vahicleNavDropdown">
                    <div
                      className="dropdown"
                      onMouseOver={() => {
                        mouseOverFunc();
                      }}
                      onMouseOut={() => {
                        mouseOutFunc();
                      }}
                    >
                      <span className="flex items-center gap-1 max-sm:justify-center cursor-pointer">
                        Vehicle <BsChevronDown />
                      </span>
                      <div className="dropdownItems w-52 absolute bg-[#ffffff] text-black w-50 px-6 py-2 right-[-3.5rem]  rounded-lg max-sm:right-10 ">
                        <div className="flex flex-col text-start">
                          <Link
                            to="/vehicle-add"
                            style={{ fontSize: "13px" }}
                            className={
                              active7
                                ? "underline font-Inter font-medium"
                                : "font-Inter font-medium"
                            }
                          >
                            Add My Vehicle
                          </Link>

                          <span className="font-Inter font-normal text-xs">
                            Enter details about vehicle
                          </span>
                        </div>
                        <div className="flex flex-col text-start">
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(addBool(false));
                              nav("/ListOfVehicle");
                            }}
                            style={{ fontSize: "13px" }}
                            className={
                              active8
                                ? "underline font-Inter font-medium "
                                : "font-Inter font-medium "
                            }
                          >
                            List of Vehicles
                          </Link>
                          <span className="font-Inter font-normal text-xs">
                            List of all vehicles associated with account
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>

                  {/* EWayBill Tracker */}
                  <li className=" relative w-[100%] flex items-center text-center justify-center">
                    <div className="dropdown">
                      <span className="flex items-center gap-1 max-sm:justify-center cursor-pointer">
                        EWayBill Tracker <BsChevronDown />
                      </span>
                      <div
                        style={{
                          boxShadow:
                            "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                        }}
                        className="dropdownItems w-52 absolute bg-[#ffffff] text-black w-50 px-6 py-2 right-[-3.5rem]  rounded-lg max-sm:right-10 "
                      >
                        <div className="flex flex-col text-start">
                          <Link
                            to="/EWayBill"
                            className=" font-Inter font-medium"
                            style={{ fontSize: "13px" }}
                          >
                            Upload EWayBill Number
                          </Link>

                          <span className="font-Inter font-normal text-xs">
                            Enter details about EWayBill Number
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* End EWayBill Tracker */}
                  <li></li>
                </ul>
              </div>
            </div>
            {!iLogin ? (
              <div className="button flex gap-4 font-Cardo  text-lg max-sm:flex-col">
                <Tooltip
                  style={
                    translationHide ? { display: "none" } : { display: "block" }
                  }
                  title="Translate"
                  placement="top"
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      eng
                        ? dispatch(changeLanguage(false))
                        : dispatch(changeLanguage(true));
                    }}
                  >
                    <span className="material-symbols-outlined">
                      g_translate
                    </span>
                  </div>
                </Tooltip>
                <li className=" mr-5" style={{ listStyle: "none" }}>
                  <a
                    target="_black"
                    href="https://www.youtube.com/watch?v=slVK8eIaLU8"
                  >
                    How To Use
                  </a>
                </li>
                <div className="signin flex justify-center items-center">
                  <Link to="/login">Sign In</Link>
                </div>
                <div className="bg-[#ffffff] text-black font-bold px-6 py-1 rounded-lg">
                  <Link to="/single-page-register">Sign Up</Link>
                </div>
              </div>
            ) : (
              <div className="button flex gap-4 font-Cardo items-center text-lg max-sm:flex-col">
                {/* notification DDL START */}
                <Stack
                  direction="row"
                  spacing={2}
                  onFocus={(e) => {
                    focusOnNotification(e);
                  }}
                  onBlur={(e) => {
                    blurOnNotification(e);
                  }}
                  onClick={() => {
                    nav("/notifications");
                  }}
                >
                  <div>
                    <Button
                      ref={anchorRef}
                      id="composition-button"
                      aria-controls={open1 ? "composition-menu" : undefined}
                      aria-expanded={open1 ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={() => {
                        handleToggle();
                      }}
                      className={"relative"}
                    >
                      {/* notification icon */}
                      <Tooltip title="All notification" placement="bottom">
                        <svg
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.5 21.3096C18.549 21.3096 21.8101 20.4053 22.125 16.7756C22.125 13.1485 19.8514 13.3817 19.8514 8.93139C19.8514 5.45517 16.5565 1.5 11.5 1.5C6.44346 1.5 3.14856 5.45517 3.14856 8.93139C3.14856 13.3817 0.875 13.1485 0.875 16.7756C1.19119 20.419 4.45222 21.3096 11.5 21.3096Z"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Tooltip>
                      <span
                        className="absolute text-white px-1 right-[8px] top-[-9px]"
                        style={{
                          background: "red",
                          borderRadius: "1rem",
                          top: "-9px",
                        }}
                      >
                        {totalNotification}
                      </span>
                    </Button>

                    {/* <Popper
                      open={open1}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom-start' ? 'left top' : 'left bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose1}>
                              <MenuList
                                autoFocusItem={open1}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                              >
                                <MenuItem onClick={handleClose} style={{ "width": "100%" }}>
                                  <div className="flex justify-between items-center w-[100%]">
                                    <h1 className="text-2xl">Notifications</h1>
                                    <span className="underline">Mark all as read</span>
                                  </div>
                                </MenuItem>

                                <div className="mt-3"></div>
                                <MenuItem onClick={handleClose}>
                                  <div className="text-start">
                                    <h1 className="font-xl font-semibold">Your password has been successfully changed.</h1>
                                    <span className="text-sm">Feb 23,2023 at 9:15 AM</span>
                                  </div>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleClose} style={{ "width": "100%" }}>
                                  <div className="flex justify-end w-[100%]"><Link to={"/notifications"} className="underline font-medium">View all notification</Link></div>
                                </MenuItem>

                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper> */}
                  </div>
                </Stack>
                {/* notification icon end */}
                <li className=" mr-5" style={{ listStyle: "none" }}>
                  <a
                    target="_black"
                    href="https://www.youtube.com/watch?v=ZET9i_Qdrso"
                  >
                    How To Use
                  </a>
                </li>
                {/* wallet icon*/}
                <Tooltip title="Tracking Credits" placement="bottom">
                  <div>
                    <Link to={"/WalletContinue"} className="flex items-center">
                      <span
                        className="material-symbols-outlined flex items-center"
                        style={{ fontSize: "2rem" }}
                      >
                        account_balance_wallet
                      </span>
                      <p
                        className=" flex items-start"
                        style={{
                          background: "#d7d7d7",
                          paddingRight: "0.4rem",
                          borderTopRightRadius: "0.2rem",
                          borderBottomRightRadius: "0.2rem",
                          paddingLeft: "0.2rem",
                          boxShadow:
                            "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
                        }}
                      >
                        <span className="" style={{ fontFamily: "poppins" }}>
                          {balanceOBJ ? balanceOBJ.credits : ""} Credits
                        </span>
                      </p>
                    </Link>
                  </div>
                </Tooltip>
                {/* wallet link */}

                <Tooltip
                  style={
                    translationHide ? { display: "none" } : { display: "block" }
                  }
                  title="Translate"
                  placement="top"
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      eng
                        ? dispatch(changeLanguage(false))
                        : dispatch(changeLanguage(true));
                    }}
                  >
                    <span className="material-symbols-outlined">
                      g_translate
                    </span>
                  </div>
                </Tooltip>

                {/* profile start*/}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Avatar sx={{ width: 32, height: 32 }}>
                        {FirstLetterOfName}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={handleClose}>
                    <Link
                      to={"/profile-main"}
                      className={"flex justify-between items-center"}
                    >
                      {" "}
                      <Avatar /> Profile
                    </Link>
                  </MenuItem>
                  <Divider />
                  {/* <MenuItem onClick={handleClose}>
                    <Link to={"/profile-main"} className={"flex justify-between items-center"}>
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      Settings</Link>
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      Logout();
                    }}
                  >
                    <div
                      className="material-symbols-outlined mr-3"
                      style={{ color: "#757575" }}
                    >
                      logout
                    </div>
                    Logout
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleClose}>
                    <Link to={"/WalletContinue"}>My Invoices</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to={"/about-us"}>About us</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to={"/refund-policy"}>Refund Policy</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to={"/faqs"}>FAQs</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to={"/blog"}>Blog</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to={"/terms-and-conditions"}>Terms of Service</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to={"/private-policy"}>Privacy Policy</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link to="/contact-us">Contact Us</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link
                      style={{
                        background: "black",
                        color: "white",
                        padding: "0.5rem",
                        borderRadius: "0.3rem",
                      }}
                      to="/profile-main"
                    >
                      Add Gst
                    </Link>
                  </MenuItem>
                </Menu>

                {/* profile end */}

                {/* theme switcher */}
                {/* <Switch /> */}
              </div>
            )}
          </div>
        </nav>
      </div>

      <ResponsiveNavbar />
    </>
  );
}

export default SecondNavbar;

// https://www.google.com/maps/dir/origin_place_name,district,+Gujarat/destination_place_name,+Gujarat/

import React from "react";

function Dot() {
  return (
    <div className="relative" style={{"boxShadow": "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" fill="white" />
      </svg>
    </div>
  );
}

export default Dot;

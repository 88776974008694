import React, { useEffect, useState } from "react";
import axios from "axios";
import { getWalletBalances } from "./apis/api";
import ls from "localstorage-slim";
import { useDispatch } from "react-redux";
import { getWalletBalance } from "./redux/slices/WalletBalance";

const App = () => {
  const [apiData, setApiData] = useState([]);
  const dispatch = useDispatch();
  const notifyArray = [];

  const iLogin = ls.get("iLogin");

  if (ls.get("planSuccess")) {
    notifyArray.push(ls.get("planSuccess"));
  }
  if (ls.get("vehicleAddSuccess")) {
    notifyArray.push(ls.get("vehicleAddSuccess"));
  }
  if (ls.get("preAlertActive")) {
    notifyArray.push(ls.get("preAlertActive"));
  }
  if (ls.get("journeyCreated")) {
    notifyArray.push(ls.get("journeyCreated"));
  }
  if (ls.get("transferCred")) {
    notifyArray.push(ls.get("transferCred"));
  }
  if (ls.get("loginSuccess")) {
    notifyArray.push(ls.get("loginSuccess"));
  }

  ls.set("totalNoty", notifyArray.length);

  let login_response_object, token_user, user_id;

  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }
  useEffect(() => {
    if (iLogin) {
      axios
        .get(`${getWalletBalances}/${user_id}`, {
          headers: {
            Authorization: `Hello ${token_user}`,
          },
        })
        .then((response) => {
          if (response.data.success) {
            // console.log(response.data.success);

            setApiData(response.data.data);
            dispatch(getWalletBalance(response.data.data));
            ls.set("walletBalance", response.data.data, { encrypt: true });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <>
      <button
        onClick={(e) => {
          window.scrollTo(0, 0);
        }}
        className="bg-black z-[999999] fixed flex justify-center items-center bottom-[2rem] right-[1rem] rounded-full w-[3rem] h-[3rem]"
      >
        <span className="material-symbols-outlined text-white">
          arrow_upward
        </span>
      </button>
    </>
  );
};

export default App;

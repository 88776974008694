import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";

function Blue_button(props) {
  return (
    <>
      <Button variant="contained" className="w-[100%]">
        {props.name}
      </Button>
    </>
  );
}

export default Blue_button;

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value:true,
}

export const translate = createSlice({
    name: 'translate',
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeLanguage } = translate.actions

export default translate.reducer
import React, { useState, useEffect } from 'react'
import TopSection from './TopSection'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteVehicle, deleteActiveJourney, getSingleClickJourney, share_trip, pastJourneyDelete, preAlertCheck, trackVehicle, getWalletBalances, trackEway } from './apis/api'
import ls from 'localstorage-slim'
import axios from 'axios'
import { CSVLink } from "react-csv";
import { useSelector, useDispatch } from 'react-redux'
import { addBoolean, addShareLinkJourney } from './redux/slices/share_pop_up'
import { ADDPAGENAME } from './redux/slices/HistoryVSTrack';
import { Link } from 'react-router-dom'
import { setIsShowFeed } from './redux/slices/isShowFeed'
import { totalRows } from './redux/slices/tableLegnth'
import { addTollData } from './redux/slices/journeyTollData'
import CircularProgress from "@mui/material/CircularProgress";
import { addCloseJourneyData } from './redux/slices/closeJourneyPostData'
import moment from 'moment';
import { addVehicleTrackData } from "./redux/slices/vehicleTrackData"
import { addewayTrackData } from './redux/slices/ewayTrackData'
import { ADDVehicles } from './redux/slices/Vehicle_Number_from_vehicleList'
import { addAMT, addDate, addCD } from './redux/slices/InvoiceAmount'
import { Tooltip } from '@mui/material'
import { click } from '@testing-library/user-event/dist/click'

const CommonTable = ({ tableHead, tableData, type, css, marginSet, defaultMessage, ids, extraCss }) => {

  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState();
  const [isSubscribe, setIsSubscribe] = useState(true)
  const valueB11 = useSelector((state) => state.mouseOnVehicle.value);

  // navigation power
  const nav = useNavigate();
  const iLogin = ls.get('iLogin');
  let login_response_object, token_user = "";
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
  }


  // track vehicle from vehicle list
  function trackThisVehicle(vehicle_number) {
    if (!iLogin) {
      window.alert("please register and sign in to view results")
    } else {
      const login_response_object = ls.get("login_data_object", { decrypt: true });
      const token_user = login_response_object.token;
      const user_id = login_response_object.user._id;
      // if (balanceOBJ.credits < 1) {
      //   window.alert("please buy plan");
      //   nav('/all-plans')
      // } else {
      ls.set('track_vehicle_no', vehicle_number, { encrypt: true });
      const stateSuggestionDataLocal = ls.get('stateSuggestionData');
      stateSuggestionDataLocal.push(vehicle_number);
      ls.set('stateSuggestionData', stateSuggestionDataLocal);
      // axios post
      axios.post(trackVehicle, {
        vehicle_no: vehicle_number,
        u_id: user_id
      }, {
        headers: {
          Authorization: `Hello ${token_user}`
        }
      })
        .then(function (response) {
          if (response.data.success === true) {
            dispatch(addVehicleTrackData(response.data.data));
            ls.set('trackVehicleData', response.data.data, { encrypt: true });
            nav('/when-click-track');
          }
        })
        .catch(function (error) {
          setIsLoader(false);
          console.log(error);
          if (error.response.status == '500') {
            window.alert(`Internal Server Error`)
          }
          if (error.response.status == '400') {
            window.alert(`Vehicle no. not available in ULIP/vehicle has not crossed any toll in last 72 hours/incorrect vehicle number`)
          }
          if (error.response.status == '404') {
            window.alert('404 Error')
          }
        });
      // }
    }
  }
  let trackVehicleData = useSelector((state) => state.vehicleTrackData.value);
  let trackVehicleDatafromLocalStorage = ls.get("trackVehicleData", { decrypt: true })

  if (trackVehicleData == '0') {
      trackVehicleData = trackVehicleDatafromLocalStorage;
  }

  function clickTrackFunctionEway(eWay_no) {
    if (!iLogin) {
      window.alert("Please register and sign in to view results");
    } else {
      const login_response_object = ls.get("login_data_object", {
        decrypt: true,
      });
      const token_user = login_response_object.token;
      console.log(token_user, "token_user")
      // const user_id = login_response_object.user._id;
      // let vehicleSearchId = trackVehicleData._id;
      const apiKey = "Jainish";

      // Set headers with the API key
      const headers = {
        Authorization: `Hello ${token_user}`,
        apiKey,
      };

      setIsLoader(false);
      ls.set("eWay_no", eWay_no, { encrypt: false });

      const stateSuggestionDataLocal = ls.get("stateSuggestionData");
      stateSuggestionDataLocal.push(eWay_no);
      ls.set("stateSuggestionData", stateSuggestionDataLocal);
      axios
        .get(
          `${trackEway}/${eWay_no}`,
          // Pass headers as the second parameter
          { headers: headers }
        )
        .then(function (response) {
          if (response.data.success === true) {
            console.log("response--" + JSON.stringify(response.data.data[0].response));
            dispatch(addewayTrackData(response.data.data[0].response));
            console.log("lines...");
            console.log(response.data.data[0].response, "dfghjk");
            ls.set("trackEwayData", response.data.data[0].response, {
              encrypt: true,
            });
            const vehicleList = response.data.data[0].response.VehiclListDetails;

            // Check if the array is not empty
            if (vehicleList.length > 0) {
              // Get the last element of the array
              const lastVehicle = vehicleList[vehicleList.length - 1];

              // Access the vehicleNo property from the last element
              const lastVehicleNo = lastVehicle.vehicleNo;

              // Now you can use lastVehicleNo as needed
              console.log("Last Vehicle No:", lastVehicleNo);

              // If you want to store it using ls.set, you can use your existing code
              ls.set("eway_latest_vehicle", lastVehicleNo, { encrypt: false });
            } else {
              console.log("The array is empty");
            }

            // ls.set("eway_latest_vehicle", response.data.data[0].response.VehiclListDetails, { encrypt: false })
            setIsLoader(false);
            nav("/when-click-track-EWay");
          }
          console.log(response, "response ewayyy");
        })
        .catch(function (error) {
          // Handle error
          console.error(error, "errorWway");
        });
    }
  }

  // planYourJourneyRedirect(elm.vin)
  function planYourJourneyRedirect(vehicle_number) {
    dispatch(ADDVehicles(vehicle_number));
    ls.set('planVehicle', vehicle_number);
    nav('/MyJourney/true')
  }

  // share ongoing journey
  function shareJourney(e, id) {
    console.log(`${share_trip}/${id}/share/`);
    dispatch(addBoolean(true));
    const allDropDown = document.querySelectorAll('.table_more_dropdown');
    for (let index = 0; index < allDropDown.length; index++) {
      allDropDown[index].style.display = 'none';
    }
    axios.get(`${share_trip}/${id}/share/`).then(response => {
      dispatch(addShareLinkJourney((response.data.data.share_link).slice(27, -1)));
      ls.set('trip_share_link', (response.data.data.share_link).slice(27, -1));
    })
  }

  // moreClickHandle
  function moreClickHandle(dropdownId, event) {
    const ddlID = document.getElementById(dropdownId);
    if (ddlID.style.display == "none") {
      const allDropDown = document.querySelectorAll('.table_more_dropdown');
      for (let index = 0; index < allDropDown.length; index++) {
        allDropDown[index].style.display = 'none';
      }
      ddlID.style.display = "block";
    } else {
      ddlID.style.display = "none";
    }
  }

  // editVehicle(e)
  function editVehicle(index, vehical_id) {
    let a = ".vehicle" + index
    const tr = document.querySelectorAll(a);
    tr.forEach((element, ind) => {
      document.cookie = `vehicle${ind}=${element.id}`;
      document.cookie = `vehical_id=${vehical_id}`;
    });
    nav('/update-vehicle')
  }

  // delete vehicle
  function deleteVehicleData(vehical_id) {
    axios.delete(`${deleteVehicle}/${vehical_id}`, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    })
      .then(function (response) {
        if (response.data.success) {
          window.alert("Vehicle has deleted successfully")
          window.location.reload();
        }
        console.log(response);
      })
      .catch(function (error) {
        window.alert(error);
        console.log(error);
      });
  }

  // viewHistoryTracking(e, `/when-click-track/${elm.id}`)
  function viewHistoryTracking(e, url) {
    dispatch(ADDPAGENAME("fromHistory"));
    nav(url);
  }

  // onGourney or active journey delete
  // function deleteJourney(e, trip_id) {
  //   axios.delete(`${deleteActiveJourney}/${trip_id}`, {
  //     headers: {
  //       Authorization: `Hello ${token_user}`
  //     }
  //   })
  //     .then(function (response) {
  //       if (response.data.success) {
  //         window.alert("journey has deleted successfully")
  //         window.location.reload();
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  // edit or update journey
  function editJourney(ind, trip_id) {
    let a = ".ongoing" + ind
    const tr = document.querySelectorAll(a);
    tr.forEach((element, ind) => {
      document.cookie = `ongoing${ind}=${element.id}`;
      document.cookie = `ongoing_id=${trip_id}`;
    });
    nav('/update-ongoing-journey')
  }

  // deletePastJourney(elm._id)
  function deletePastJourney(trip_id) {
    axios.delete(`${pastJourneyDelete}/${trip_id}`, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    }).then(response => {
      console.log(response)
      window.location.reload();
    }).catch(err => {
      console.log(err)
    })
  }

  // clickOnGoingRaw(e)
  function clickOnGoingRaw(e, trip_id) {
    console.log("and trip_id:", trip_id);
    setIsLoader(true);
    axios.get(`https://lorrycare.com:5000/v1/trip-details/${trip_id}`, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    }).then(response => {
      if (response.data.success) {
        dispatch(addTollData(response.data.data));
        ls.set("journeyPlan", response.data.data);
        ls.set('journeyTollData', response.data.data, { encrypt: true });
        setIsLoader(false)
        nav('/full-trip-list')
      }
      // console.log(response.data.data);
    }).catch(err => {
      console.log(err);
      setIsLoader(false)
    })
  }

  // preAlertActive(e,elm._id)
  function preAlertActive(e, trip_id) {
    if (e.target.checked) {
      axios.put(`${preAlertCheck}/${trip_id}`).then(response => {
        if (response.data.success) {
          window.alert(response.data.message);

          let currentTime = moment().format("LLLL");
          let currentTime2 = moment().format("DD-MM-YYYY");
          let obj = {
            "name": "preAlertActive",
            "message": response.data.message,
            "time": currentTime,
            "forFilter": currentTime2
          }

          ls.set('preAlertActive', obj)
          window.location.reload();
        }
      }).catch(error => {
        console.log(error)
      })
    } else {
      axios.put(`${preAlertCheck}/${trip_id}`).then(response => {
        if (response.data.success) {
          window.alert(response.data.message);
          let currentTime = moment().format("LLLL");
          let currentTime2 = moment().format("DD-MM-YYYY");
          let obj = {
            "name": "preAlertActive",
            "message": response.data.message,
            "time": currentTime,
            "forFilter": currentTime2
          }

          ls.set('preAlertActive', obj)
          window.location.reload();
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }

  // journey creadits calculation
  function dayCalculate(journeyDate, Current) {

    console.log(journeyDate + "=" + Current)
    var end_day, today;
    end_day = new Date(journeyDate);
    today = new Date(Current);

    //calculate time difference  
    var time_difference = end_day.getTime() - today.getTime();

    //calculate days difference by dividing total milliseconds in a day  
    var days_difference = time_difference / (1000 * 60 * 60 * 24);
    return days_difference;
  }

  // for get table all row length code START
  var allRows = document.querySelectorAll("tr");
  dispatch(totalRows(allRows.length))
  // for for get table all row length code START

  return (
    <section className='track_my_goods' style={css ? css : { "width": "80%", "margin": "auto" }}>
      <div className="track_container">
        {/* main ongoing section */}
        <section className='tracker_main_wrapper' style={{ "marginBottom": "3rem", "width": "90%", "margin": "auto" }}>

          <div className={extraCss ? extraCss : 'tracker_main_container'} id={ids}>

            {/* table */}
            <h1 className='mt-5  please_scroll' style={{ "display": "none" }}><p>scroll table</p> <span className="material-symbols-outlined">
              arrow_forward
            </span></h1>
            <table className='onGoing_table' id={valueB11 ? 'website_table' : 'table_after'} style={marginSet ? marginSet : { "margin": "3rem auto", "width": "100%", "border": "none", "position": "relative" }}>
              <thead style={{ "border": "none" }}>
                <tr>
                  {tableHead.map((head, ind) => {
                    return <th key={`${head}${ind}${ind * 5}`} style={{ "width": "25%", "background": "black", "color": "white", "padding": "0.5rem 0rem" }}>{head}</th>
                  })}
                </tr>
              </thead>

              <tbody>
                {/* ongoing journey */}
                {type == "1" ? (tableData.length === 0 ? <h1 className='text-start pl-3 text-sm'>{defaultMessage}</h1> : "") : ""}
                {type == "1" ?

                  tableData.map((elm, ind) => {
                    return <>
                      <tr key={`${ind}${elm._id}tarun${ind}`} style={{ "background": "white" }} >
                        <td key={`${ind}123tarun1`} onClick={(e) => { clickOnGoingRaw(e, elm._id) }} className={"ongoing" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem", "cursor": "pointer" }} id={elm.vehicle_no}>{elm.vehicle_no}</td>
                        <td key={`${ind}453tarun2`} onClick={(e) => { clickOnGoingRaw(e, elm._id) }} className={"ongoing" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem", "cursor": "pointer" }} id={elm.source[0].source}>{elm.source[0].source}</td>
                        <td key={`${ind}163tarun3`} onClick={(e) => { clickOnGoingRaw(e, elm._id) }} className={"ongoing" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem", "cursor": "pointer" }} id={elm.destination[0].destination}>{elm.destination[0].destination}</td>
                        <td key={`${ind}723tarun4`} onClick={(e) => { clickOnGoingRaw(e, elm._id) }} className={"ongoing" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem", "cursor": "pointer" }} id={elm.trip_no}>{elm.trip_no}</td>
                        <td key={`${ind}183tarun5`} onClick={(e) => { clickOnGoingRaw(e, elm._id) }} className={"ongoing" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem", "cursor": "pointer" }} id={(elm.start_date).slice(0, 10)}>{(elm.start_date).slice(0, 10)}</td>
                        <td key={`${ind}199tarun7`} onClick={(e) => { clickOnGoingRaw(e, elm._id) }} className={"ongoing" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem", "cursor": "pointer" }} id={elm.end_date.slice(0, 10)}>{elm.end_date.slice(0, 10)}</td>
                        <td key={`${ind}199tarun7`} onClick={(e) => { clickOnGoingRaw(e, elm._id) }} className={"ongoing" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem", "cursor": "pointer" }} >{(dayCalculate(elm.end_date.slice(0, 10), (elm.start_date).slice(0, 10)) + 1) * 10} Credits</td>
                        <td key={`${ind}199tarun7`} className={"ongoing" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem", "cursor": "pointer" }}><button style={{ fontSize: "0.8rem", background: "#262626", color: "white", borderRadius: "0.3rem", padding: "0.3rem", border: "none" }} onClick={(e) => { shareJourney(e, elm._id); }}>Share</button></td>
                        <td key={`${ind}103tarun8`} className={"ongoing" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0.7rem", "cursor": "pointer" }} >

                          <div key={`${ind}123tarun1sdsd`} className='relative px-10'>

                            {/* three dot more icon */}
                            <button className="material-symbols-outlined cursor-pointer z-0 " id={`more_vert${ind}`} onClick={(e) => { moreClickHandle(`more_ddl${ind}`, e) }}>
                              more_vert
                            </button>
                            {/* three dot more icon */}

                            {/* ddl START*/}
                            <section className="absolute left-[-125px] right-[22px] rounded z-50 w-[12rem] h-[auto] bg-white table_more_dropdown" id={`more_ddl${ind}`} style={{ "display": "none" }} >

                              <div className='share_option_wrapper border_bottpm_1px_black cursor-pointer' onClick={() => { editJourney(ind, elm._id) }}>
                                <h1 className='px-6 py-1'>Edit End Date</h1>
                              </div>

                              <div className='share_option_wrapper border_bottpm_1px_black cursor-pointer' onClick={() => { dispatch(setIsShowFeed(true)); dispatch(addCloseJourneyData(elm._id)) }}>
                                <h1 className='px-6 py-1'>Close Journey</h1>
                              </div>

                            </section>
                            {/* ddl END*/}

                          </div>

                        </td>
                      </tr>
                    </>
                  }) : ""

                }

                {/* past journey */}
                {type == "2" ? (tableData.length === 0 ? <h1 className='text-start pl-3 text-sm'>{defaultMessage}</h1> : "") : ""}
                {type == "2" ?
                  tableData.map((elm, ind) => {
                    return <tr className='cursor-pointer' style={{ "background": "white" }}>
                      <td onClick={(e) => { clickOnGoingRaw(e, elm._id) }} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{elm.vehicle_no}</td>
                      <td onClick={(e) => { clickOnGoingRaw(e, elm._id) }} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{elm.source[0].source}</td>
                      <td onClick={(e) => { clickOnGoingRaw(e, elm._id) }} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{elm.destination[0].destination}</td>
                      <td onClick={(e) => { clickOnGoingRaw(e, elm._id) }} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{elm.trip_no}</td>
                      <td onClick={(e) => { clickOnGoingRaw(e, elm._id) }} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{elm.start_date.slice(0, 10)}</td>
                      <td onClick={(e) => { clickOnGoingRaw(e, elm._id) }} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{elm.end_date}</td>
                      <td style={{ "textAlign": "center", "padding": "0.6rem 0.7rem" }}>

                        <div className='relative px-10'>

                          {/* three dot more icon */}
                          <span className="material-symbols-outlined cursor-pointer" id={`more_vert${ind}`} onClick={(e) => { moreClickHandle(`more_ddl${ind}`, e) }}>
                            more_vert
                          </span>
                          {/* three dot more icon */}

                          {/* ddl START*/}
                          <section className="absolute left-[-125px] right-[22px] rounded z-50 w-[12rem] h-[auto] bg-white table_more_dropdown" id={`more_ddl${ind}`} style={{ "display": "none" }}>

                            {/* <div className='share_option_wrapper border_bottpm_1px_black cursor-pointer'>
                              {isSubscribe ?
                                <CSVLink className='px-6 py-1' data={[elm]} filename={`${elm.vehicle_no}-${elm.start_date.slice(0, 10)}-${elm.end_date}`}>Download</CSVLink> :
                                <button className='px-6 py-1' onClick={() => { window.alert("Subscribe plan for download") }}>Download</button>
                              }
                            </div> */}

                            <div><button className='px-6 py-1' onClick={() => { deletePastJourney(elm._id) }}>delete</button></div>

                          </section>
                          {/* ddl END*/}

                        </div>

                      </td>
                    </tr>
                  }) : ""
                }

                {/* vehicle list */}
                {type == "3" ? (tableData.length === 0 ? <h1 className='text-start pl-3 text-sm'>{defaultMessage}</h1> : "") : ""}
                {type == "3" ?

                  tableData.map((elm, ind) => {
                    return <tr style={{ "background": "white" }}>
                      <td className={"vehicle" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }} id={elm.vin}>{elm.vin}</td>
                      <td className={"vehicle" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }} id={elm.make}>{elm.make}</td>
                      <td className={"vehicle" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }} id={elm.model}>{elm.model}</td>
                      <td className={"vehicle" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }} id={elm.type}>{elm.type}</td>
                      <td className={"vehicle" + ind} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }} id={elm.validity}>{elm.validity}</td>
                      <td style={{ "textAlign": "center", "padding": "0.6rem 0.7rem" }}>

                        <div className='relative px-10'>

                          {/* three dot more icon */}
                          <span className="material-symbols-outlined cursor-pointer" id={`more_vert${ind}`} onClick={(e) => { moreClickHandle(`more_ddl${ind}`, e) }}>
                            more_vert
                          </span>
                          {/* three dot more icon */}

                          {/* ddl START*/}
                          <section style={{ "display": "none" }} className="absolute left-[-125px] right-[22px] rounded z-50 w-[12rem] h-[auto] bg-white table_more_dropdown" id={`more_ddl${ind}`}>

                            <div className='share_option_wrapper border_bottpm_1px_black cursor-pointer  py-1'>
                              <CSVLink className='px-6 py-1' data={[elm]}>Download</CSVLink>
                            </div>

                            <div className='share_option_wrapper border_bottpm_1px_black cursor-pointer' onClick={() => { editVehicle(ind, elm.id) }}>
                              <h1 className='px-6 py-1'>Update</h1>
                            </div>

                            <div className='share_option_wrapper border_bottpm_1px_black cursor-pointer' onClick={() => { trackThisVehicle(elm.vin) }}>
                              <h1 className='px-6 py-1'>Track</h1>
                            </div>

                            <div className='share_option_wrapper border_bottpm_1px_black cursor-pointer' onClick={() => { planYourJourneyRedirect(elm.vin) }}>
                              <h1 className='px-6 py-1'>Plan Journey</h1>
                            </div>

                            <div className='share_option_wrapper border_bottpm_1px_black cursor-pointer' onClick={() => { deleteVehicleData(elm.id) }}>
                              <h1 className='px-6 py-1'>Delete</h1>
                            </div>

                          </section>
                          {/* ddl END*/}

                        </div>

                      </td>
                    </tr>

                  }) : ""

                }

                {/* search history */}
                {type == "4" ? (tableData.length === 0 ? <h1 className='text-start pl-3 text-sm'>{defaultMessage}</h1> : "") : ""}
                {type == "4" ?

                  tableData.map((elm, ind) => {
                    return <>
                      <Tooltip title={"Click to expand"}>
                        <tr style={{ "background": "white", "cursor": "pointer" }} >
                          <td onClick={(e) => { viewHistoryTracking(e, `/history-tracking/${elm.id}`) }} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{elm.tracking_no}</td>
                          <td onClick={(e) => { viewHistoryTracking(e, `/history-tracking/${elm.id}`) }} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{elm.vehicle_no}</td>
                          <td onClick={(e) => { viewHistoryTracking(e, `/history-tracking/${elm.id}`) }} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{(elm.request_time).slice(0, 10)}</td>
                          <td onClick={(e) => { viewHistoryTracking(e, `/history-tracking/${elm.id}`) }} style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{(elm.request_time).slice(11, 19)}</td>
                          {/* <td style={{ "textAlign": "center", "padding": "0.6rem 0.7rem" }}>

                            <div className='relative px-10'>

                              <span className="material-symbols-outlined cursor-pointer" id={`more_vert${ind}`} onClick={(e) => { moreClickHandle(`more_ddl${ind}`, e) }}>
                                more_vert
                              </span>

                              <section style={{ "display": "none" }} className="absolute left-[-125px] right-[22px] rounded z-50 w-[12rem] h-[auto] bg-white table_more_dropdown" id={`more_ddl${ind}`}>

                                <div className='share_option_wrapper border_bottpm_1px_black cursor-pointer'>
                                  <CSVLink className='px-6 py-1' data={[elm]}>Download</CSVLink> :
                                </div>

                              </section>

                            </div>

                          </td> */}
                        </tr>
                      </Tooltip>

                    </>

                  }) : ""

                }
                {console.log("28JUNE")}
                {console.log(tableData)}



                {/* Transaction History */}
                {type == "5" ? (tableData.length === 0 ? <h1 className='text-start pl-3 text-sm'>{defaultMessage}</h1> : "") : ""}
                {type === '5'
                  ? tableData.map((elm) => {
                    return (
                      <tr style={{ background: 'white' }}>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {new Date(elm.createdAt).toLocaleDateString('en-US')}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {new Date(elm.createdAt).toLocaleTimeString('en-US')}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.id}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.amount}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.credits}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.status}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0.7rem',
                          }}>
                          {elm.status == "Success" ?
                            <button
                              style={{
                                background: 'black',
                                color: 'white',
                                padding: '0.3rem 1rem',
                                'margin-right': '5%',
                                border: 'none',
                                borderRadius: '0.3rem',
                                boxShadow:
                                  'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                              }} onClick={() => { dispatch(addAMT(elm.amount)); dispatch(addDate(elm.createdAt.slice(0, 10))); dispatch(addCD(elm.credits)); nav(`/invoice/${elm.id}`) }}>
                              Invoice
                            </button> : <></>
                          }

                          {/* <button
                            style={{
                              background: 'white',
                              color: 'black',
                              padding: '0.3rem 1rem',
                              border: 'none',
                              borderRadius: '0.3rem',
                              boxShadow:
                                'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                            }}>
                            Refunds
                          </button> */}
                        </td>
                      </tr>
                    );
                  })
                  : ''}

                {/* transfer credits */}
                {type == "6" ? (tableData.length === 0 ? <h1 className='text-start pl-3 text-sm'>{defaultMessage}</h1> : "") : ""}
                {type === '6'
                  ? tableData.map((elm) => {
                    return (
                      <tr style={{ background: 'white' }}>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {/* {elm.transfer_date.slice(0, 10)} */}
                          {new Date(elm.transfer_date).toLocaleDateString('en-US')}

                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {new Date(elm.transfer_date).toLocaleTimeString('en-US')}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.transaction_id}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.receiver_email}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.transfer_credit}
                        </td>

                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0.7rem',
                          }}>
                          {/* <button
                            style={{
                              background: 'black',
                              color: 'white',
                              padding: '0.3rem 1rem',
                              'margin-right': '5%',
                              border: 'none',
                              borderRadius: '0.3rem',
                              boxShadow:
                                'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                            }} onClick={() => { nav('/invoice') }}>
                            Invoice
                          </button> */}
                          {/* <button
                            style={{
                              background: 'white',
                              color: 'black',
                              padding: '0.3rem 1rem',
                              border: 'none',
                              borderRadius: '0.3rem',
                              boxShadow:
                                'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                            }}>
                            Refunds
                          </button> */}
                        </td>
                      </tr>
                    );
                  })
                  : ''}

                {/* transaction Activity */}
                {/* {type == "6" ? (tableData.length === 0 ? <h1 className='text-start pl-3 text-sm'>{defaultMessage}</h1> : "") : ""} */}
                {type === '20'
                  ? tableData.map((elm) => {
                    return (
                      <tr style={{ background: 'white' }}>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {/* {elm.date.slice(0, 10)} */}
                          {new Date(elm.date).toLocaleDateString('en-US')}

                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {new Date(elm.date).toLocaleTimeString('en-US')}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.transction_id}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.credit}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.type}
                        </td>

                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0.7rem',
                          }}>

                        </td>
                      </tr>
                    );
                  })
                  : ''}

                {/* Credit Received table */}
                {type == "7" ? (tableData.length === 0 ? <h1 className='text-start pl-3 text-sm'>{defaultMessage}</h1> : "") : ""}
                {type === '7'
                  ? tableData.map((elm) => {
                    return (
                      <tr style={{ background: 'white' }}>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {/* {elm.transfer_date.slice(0, 10)} */}
                          {new Date(elm.transfer_date).toLocaleDateString('en-US')}

                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {new Date(elm.transfer_date).toLocaleTimeString('en-US')}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.transaction_id}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.sender_id}
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            padding: '0.1rem 0rem',
                          }}>
                          {elm.transfer_credit}
                        </td>

                      </tr>
                    );
                  })
                  : ''}

                {/* EWayBill */}
                {type === "9" ? (
                  Array.isArray(tableData) && tableData.map((elm, ind) => (
                    <>
                      <Tooltip title={"Click to Track"}>
                        <tr style={{ "background": "white", "cursor": "pointer" }} >
                          <td style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{elm.eWaybillNo}</td>
                          <td style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{new Date(elm.uploadDate).toLocaleDateString("en-IN")}</td>
                          <td style={{ "textAlign": "center", "padding": "0.6rem 0rem" }}>{new Date(elm.uploadDate).toLocaleTimeString("en-IN")}</td>
                          <td
                            style={{
                              textAlign: 'center',
                              padding: '0.1rem 0.7rem',
                            }}>
                            <button
                              style={{
                                background: 'black',
                                color: 'white',
                                padding: '0.3rem 1rem',
                                'margin-right': '5%',
                                border: 'none',
                                borderRadius: '0.3rem',
                                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                              }}
                              onClick={() => clickTrackFunctionEway(elm.eWaybillNo)}
                            >
                              Track Now
                            </button>
                          </td>
                        </tr>
                      </Tooltip >

                    </>
                  ))
                ) : ""}
              </tbody>
            </table>
            {/* table-end */}
          </div>
        </section>
      </div >

      {/* loader */}
      <section section style={isLoader ? { "display": "flex" } : { "display": "none" }} id="home_loader" className="absolute top-0 left-0 w-[100%] h-[100%] bg-white opacity-50 flex justify-center items-center" >
        <CircularProgress />
      </section >
    </section >
  )
}

export default CommonTable
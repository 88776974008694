import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
}

export const five_sec_popup = createSlice({
    name: 'five_sec_popup',
    initialState,
    reducers: {
        isShowFiveSec: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { isShowFiveSec } = five_sec_popup.actions

export default five_sec_popup.reducer
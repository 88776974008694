import React from 'react';
import {useNavigate} from 'react'

function RedirectBack() {
    return (
        <>
            
        </>
    );
}

export default RedirectBack;
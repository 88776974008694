import React, { useState, useEffect } from "react";

function Timer() {
  const [otp, setOTP] = useState("");
  const [timeLeft, setTimeLeft] = useState(300);

  useEffect(() => {
    // Update the timer every second
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // If the timer has run out, disable the submit button
    if (timeLeft === 0) {
      const submitButton = document.querySelector("button[type='submit']");
      submitButton.disabled = true;
    }
  }, [timeLeft]);

  function handleSubmit(event) {
    event.preventDefault();
    // TODO: Verify OTP
  }

  function handleResetTimer() {
    setTimeLeft(300);
    const submitButton = document.querySelector("button[type='submit']");
    submitButton.disabled = false;
  }

  function formatTime(time) {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  }

  return (
    <div>
      <span>{timeLeft>0?formatTime(timeLeft):"00:00"}</span>
      <button type="submit" disabled={timeLeft === 0}></button>
      <button type="button" onClick={handleResetTimer}></button>
    </div>
  );
}

export default Timer;

import React, { useState } from "react";
import styled from "styled-components";
import { registerPost, postKYC } from "../../../apis/api";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Blue_button from "../../../components/buttons/Blue_button";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import Para from "../../../components/layouts/introPara/Para";
import { WiDirectionRight } from "react-icons/wi";
import FileBase from 'react-file-base64'
import ls from 'localstorage-slim'

const RStep8 = () => {
  const nav = useNavigate();
  // const iLogin = ls.get('iLogin');
  // let login_response_object="",token_user="",user_id="";
  // if(iLogin){
  //   login_response_object = ls.get("login_data_object",{decrypt:true});
  //   token_user = login_response_object.token;
  //   user_id = login_response_object.user._id;
  // }
  const user_id = ls.get("user-id-when-signup", { decrypt: true })

  // iniatize state variables...
  const [inputData, setInputData] = useState({
    u_id: user_id,
    aadhar_number: "",
    pan_number: "",
    aadhar_file: "",
    pan_file: ""
  });

  // postKycData()
  function postKycData() {

    if (inputData.aadhar_number.length !== 12) {
      window.alert("aadhar number must be valid")
    } else if (inputData.pan_number.length !== 10) {
      window.alert("pan number must be valid")
    } else {
      // post kyc
      axios.post(postKYC, inputData)
        .then(function (response) {
          console.log(response)
          if (response.data.success) {
            ls.set("kyc_id", response.data.data._id, { encrypt: true })
            nav('/login')
          }
        })
        .catch(function (error) {
          window.alert(error)
          console.log(error);
        });
    }

  }

  // when user user will input data in input box given function will be run...
  function inputChange(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
    console.log(inputData)
  }


  return (
    <div className="flex items-center w-full h-[100vh] justify-center bg-gray-100 ">
      <Row className="w-[45%] bg-white rounded-lg py-8 h-[38rem]">
        <div className="px-8">
          <div className="flex">
            {/* <Header /> */}
            <div className="w-40 flex justify-center items-center font-Cardo font-normal text-xs underline">
              <Link to="/login">Skip for now </Link>
              <span>
                <WiDirectionRight />
              </span>
            </div>
          </div>
        </div>
        <div className="px-36 flex items-center flex-col justify-center h-[30rem]">
          <div>
            <div>
              <MainHeading HeadinName="Fill KYC form" />
            </div>
            <Para Detail="Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio" />
          </div>
          <div className="w-[100%] ">
            <label htmlFor="">Adhar Number</label>

            <Input
              label="Aadhar Card No."
              onChange={(e) => {
                inputChange(e);
              }}
              type="number"
              name="aadhar_number"
              placeholder="Example 9573 8102 9543"
            />
          </div>
          <div className="w-[100%] ">
            <label htmlFor="">Pan Number</label>

            <Input
              label="Pan Card No."
              onChange={(e) => {
                inputChange(e);
              }}
              type="text"
              name="pan_number"
              placeholder="Example ABCTY1234D"
            />
          </div>

          {/* upload adhar card */}
          <div className="pass w-[100%] relative mt-[1.375rem]">
            <FileBase
              type="file"
              multiple={false}
              onDone={({ base64 }) =>
                setInputData({ ...inputData, aadhar_file: base64 })
              }
              name="aadhar_file"
            />
          </div>

          {/* upload pan card */}
          <div className="pass w-[100%] relative mt-[1.375rem]">
            <FileBase
              type="file"
              multiple={false}
              onDone={({ base64 }) =>
                setInputData({ ...inputData, pan_file: base64 })
              }
              name="pan_file"
            />
          </div>

          <div className="w-full mt-[2.5rem]" onClick={() => { postKycData() }}>
            <Link>
              <Blue_button
                name="Continue"
              />
            </Link>
          </div>
          <div className="mt-[0.625rem]">
            <p className="font-Inter font-normal text-[rgba(0,0,0,1)] text-base">
              Already have an account?
              <span
                style={{ color: "black", fontWeight: "600", cursor: "pointer" }}
              >
                <Link to="/login">Login</Link>
              </span>
            </p>
          </div>
        </div>
      </Row>
    </div>
  );
};
const Row = styled.div`
  box-shadow: -2px 2px 2px #8080805c;
  border: 1px solid #80808024;
  background-color: #ffffff;
  border-radius: 15px;
`;
const Input = styled.input`
  margin-top: 0.75rem;
  width: 100%;
  padding: 0.75rem 2.25rem 0.75rem 1rem;
  border-radius: 5px;
  border: none;
  border: 1px solid #b2b2b2;
  font-family: "Inter", sans-serif;
`;
export default RStep8;

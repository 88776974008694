import React, { useState } from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import ls from 'localstorage-slim'
import env from "react-dotenv";

const AboutUs = () => {

  // get all notification from localstorage...
  let notifyArray = [];

  if (ls.get('planSuccess')) {
    notifyArray.push(ls.get('planSuccess'));
  }
  if (ls.get('loginSuccess')) {
    notifyArray.push(ls.get('loginSuccess'));
  }
  if (ls.get('vehicleAddSuccess')) {
    notifyArray.push(ls.get('vehicleAddSuccess'));
  }
  if (ls.get('preAlertActive')) {
    notifyArray.push(ls.get('preAlertActive'));
  }
  if (ls.get('journeyCreated')) {
    notifyArray.push(ls.get('journeyCreated'));
  }
  if (ls.get('transferCred')) {
    notifyArray.push(ls.get('transferCred'));
  }

  console.log(notifyArray);


  // removeNotification()
  function removeNotification(keyname) {
    localStorage.removeItem(keyname);
    window.location.reload();
  }

  // markAsRead()
  function markAsRead(id) {
    document.getElementById(id).style.display="none";
    if(ls.get('markAsRead')){
      let totalRead = ls.get('markAsRead');
      ls.set('markAsRead',totalRead+1)
    }else{
      ls.set('markAsRead',1)
    }
  }

  console.log("totalNotification" + JSON.stringify(notifyArray.length))

  return (
    <>

      {/* navbar START*/}
      <SecondNavBar />
      {/* navbar END */}

      {/* top section Component */}
      <TopSection
        bigi={"Notifications"}
        small={"List of your notification"}
      >
      </TopSection>
      {/* top section Component END*/}

      {/* mainSectionStart */}
      <main className="notifi_wrapper m-auto" style={{ "width": "73.5%" }}>

        <Card css={{ "width": "100%", "margin": "auto", "paddingTop": "0.8rem", "paddingBottom": "1rem" }}>

          {/* today heading */}
          <div>
            <h1 className="font-Cardo text-xl font-semibold">Latest</h1>
          </div>

          <div className="container__notifi m-auto mt-5" style={{ "width": "100%" }}>
            {notifyArray.map((elm, ind) => {
              return <div className="">
                <div className="notification__divi pb-3 pt-3 innerMAgix flex justify-between items-center">
                  <div>
                    <h1 className="text-xl font-semibold">{elm.message}</h1>
                    <span className="text-sm">{elm.time}</span>
                    <span className="text-sm">{elm.forFilter}</span>
                  </div>
                  <div className="flex">
                    {/* <img onClick={() => { markAsRead(`m1${ind}`) }} id={`m1${ind}`} src="double-tick.png" className="mr-5 cursor-pointer" style={{ "width": "1.3rem" }} /> */}
                    <span className="material-symbols-outlined cursor-pointer" onClick={(e) => { removeNotification(elm.name) }}>
                      close
                    </span>
                  </div>
                </div>

              </div>
            })}

          </div>

          <div className="mt-5">
            <h1 className="font-Cardo text-xl font-semibold">Oldest</h1>
          </div>

        </Card>

      </main>
      {/* mainSectionEnd */}

    </>
  );
};

export default AboutUs;

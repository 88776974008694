
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
}

export const Vehicle_Number_from_vehicleList = createSlice({
    name: 'Vehicle_Number_from_vehicleList',
    initialState,
    reducers: {
        ADDVehicles: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { ADDVehicles } = Vehicle_Number_from_vehicleList.actions

export default Vehicle_Number_from_vehicleList.reducer
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
}

export const closeJourneyPostData = createSlice({
    name: 'closeJourneyPostData',
    initialState,
    reducers: {
        addCloseJourneyData: (state, action) => {
            state.value = (action.payload);
        }
    },
})


export const { addCloseJourneyData } = closeJourneyPostData.actions

export default closeJourneyPostData.reducer
import React, { useState } from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Footer from "../../components/layouts/Footer/Footer";
import { updateJourney1,updateJourney2 } from "../../apis/api";
import ls from 'localstorage-slim'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const UpdateOnGoing = () => {

    const nav = useNavigate();
    const valueB11 = useSelector((state) => state.mouseOnVehicle.value);
    // get data from cookie
    var vehicleNumber = getCookie("ongoing0");
    var ongoingID = getCookie("ongoing_id");
    var source = getCookie("ongoing1");
    var destination = getCookie("ongoing2");
    var trip_no = getCookie("ongoing3");
    var start_date = getCookie("ongoing4");
    var end_date = getCookie("ongoing5");

    const login_response_object = ls.get("login_data_object", { decrypt: true });
    const token_user = login_response_object.token;

    const [inputdata, setInputData] = useState({
        // "vehicle_no": vehicleNumber,
        // "source": source,
        // "destination": destination,
        // "trip_no": trip_no,
        // "start_date": start_date,
        "end_date": end_date
    })

    // get cookie data function
    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // update function
    function updateJourney(e, id) {
        axios.put(`${updateJourney2}/${id}`, inputdata, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        })
            .then(function (response) {
                if (response.data.success) {
                    window.alert("data has updated successfully")
                    nav("/OngoingJourneys");
                }
                console.log(response);
            })
            .catch(function (error) {
                window.alert(error);
                console.log(error);
            });
    }

    function changeHandle(e){
        setInputData({...inputdata,[e.target.name]:e.target.value})
    }


    return (
        <>

            {/* navbar START*/}
            <SecondNavBar />
            {/* navbar END */}

            {/* top section Component */}
            <TopSection
                bigi={"Update Journey"}
                small={""}
            >
            </TopSection>
            {/* top section Component END*/}

            {/* form wrapper */}
            <section className="form-wrapper m-auto pb-10" style={{ "width": "73.5%" ,"position":"relative"}} id={valueB11?'qwernh':'qdwcvvcf'}>
                <form className="mt-10">

                    {/* vehicle number */}
                    <div className="mt-2" style={{ "width": "100%","zIndex":"-99999999" }}>
                        <TextField disabled name={"vehicle_no"} label={"vehicle registration number"} onChange={(e)=>{changeHandle(e)}} value={vehicleNumber} id="vehicle-number" variant="filled" style={{ "width": "100%" }} />
                    </div>

                    {/* source */}
                    <div className="mt-2" style={{ "width": "100%" }}>
                        <TextField disabled name={"source"} label={"source"} onChange={(e)=>{changeHandle(e)}} value={source} id="source" variant="filled" style={{ "width": "100%" }} />
                    </div>

                    {/* destination */}
                    <div className="mt-2" style={{ "width": "100%" }}>
                        <TextField disabled name={"destination"} label={"destination"} onChange={(e)=>{changeHandle(e)}} value={destination} id="destination" variant="filled" style={{ "width": "100%" }} />
                    </div>

                    {/* trip_no */}
                    <div className="mt-2" style={{ "width": "100%" }}>
                        <TextField  name={"trip_no"} label={"trip number"} onChange={(e)=>{changeHandle(e)}} value={trip_no} disabled id="trip_no" variant="filled" style={{ "width": "100%" }} />
                    </div>

                    {/* start_date */}
                    <div className="mt-2" style={{ "width": "100%" }}>
                        <TextField disabled name={"start_date"} value={start_date}  id="start_date" variant="filled" style={{ "width": "100%" }} />
                    </div>

                    {/* end_date */}
                    <div className="mt-2" style={{ "width": "100%" }}>
                        <TextField name={"end_date"} label={'end date'} type={"date"} onChange={(e)=>{changeHandle(e)}} value={inputdata.end_date} id="end_date" variant="filled" style={{ "width": "100%" }} />
                    </div>

                    {/* button */}
                    <div className="mt-5" onClick={(e) => { updateJourney(e, ongoingID) }}>
                        <Button className="w-[100%]" variant="contained" style={{"background":"black"}}>Update</Button>
                    </div>

                </form>
            </section>

            <Footer />

        </>
    );
};

export default UpdateOnGoing;

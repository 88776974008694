// import section...
import React, { useState, useRef } from "react";
import Dot from "../../components/assets/svg/icons/Dot";
import Line from "../../components/assets/svg/Line";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import TopSection from "../../TopSection";
import Tick from "../../components/assets/svg/Tick"
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowCircleRight } from "react-icons/md";
import ls from 'localstorage-slim'
import Footer from "../../components/layouts/Footer/Footer";


function AddMyVehicle() {

  // all local states and variables
  const [data, setData] = useState();
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [stateSuggChecker, setStateSuggChecker] = useState(true);
  const iLogin = ls.get('iLogin');
  let stateSuggestionData = [];
  let stateArray = [];
  let state_suggesREF = useRef();


  // define navigation functionality
  const nav = useNavigate();
  const inputElement = useRef();

  // when user input vehicle number then 
  // 1. vehicle number must be in uppercase 
  // 2. convert space -> no space vehicle number
  function inputChange(e, check) {
    if (check) {
      const value = ((e).replace(/\s/g, '')).toUpperCase();
      setData(value);
    } else {
      e.preventDefault();
      const value = ((e.target.value).replace(/\s/g, '')).toUpperCase();
      setData(value);
    }
  }

  // state hit suggestion START
  if (!ls.get('stateSuggestionData')) {
    ls.set('stateSuggestionData', stateSuggestionData);
  }
  if (ls.get('stateSuggestionData')) {
    stateArray = ls.get('stateSuggestionData');
  }

  // remove duplication from suggestion array using given function
  function removeDuplicates(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }

  // when user will focus on vehicle number input box this function will be executed
  function onFocusVehicleNumber(e) {
    e.preventDefault();
    setStateSuggChecker(false);
    !stateSuggChecker ? setShowSuggestion(true) : setShowSuggestion(false);
  }

  // insert vehicle number in input box when click on state
  function stackClicking(e, elms) {
    inputElement.current.value = elms;
    inputChange(elms, true);
    setShowSuggestion(false);
  }
  //   state suggestion hint END

  // when user click on next button then it function will be executed
  function okNext(e) {
    if ((inputElement.current.value).length > 10) {
      window.alert("characters limit 10");
    } else if ((inputElement.current.value).length == 0) {
      window.alert("please fill your vehicle number");
    } else if (!isFinite((inputElement.current.value).slice(-4))) {
      window.alert("please enter valid vehicle number")
    }
    else {
      const stateSuggestionDataLocal = ls.get('stateSuggestionData');
      stateSuggestionDataLocal.push(data);
      ls.set('stateSuggestionData', stateSuggestionDataLocal);
      ls.set('vehical_iden_number', data, { encrypt: true });
      nav('/VehicleMake');
    }
  }

  return (
    <div onClick={() => { setStateSuggChecker(true); stateSuggChecker ? setShowSuggestion(false) : setShowSuggestion(true) }}>
      <div>

        <div className="nav">
          <SecondNavBar />
        </div>

        {/* top section Component */}
        <TopSection
          bigi={"Add My Vehicle"}
          small={"Please enter vehicles details"}
        >
        </TopSection>
        {/* top section Component END*/}

        {/* MAIN SECTION START  */}
        <div className="parent-elem relative" onClick={() => { setStateSuggChecker(true); stateSuggChecker ? setShowSuggestion(false) : setShowSuggestion(false) }}>
          <div className="header"></div>
          <div className="py-5">
            <div  className="main-Box-Vehicle bg-[#fafafa] flex flex-col justify-center items-center ">
              <div style={{"boxShadow": "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"}} className="bg-white w-[90%] flex flex-col justify-center items-center Semi-Main-Box lg:w-[55%] gap-8 pb-4 rounded-md">
                <div className="h-2 bg-black w-full top-0 rounded-b-none rounded-t-md"></div>

                <div className="second-Section flex flex-col gap-7 w-[85%]">
                  <div className=" text-base font-Cardo font-normal lg:text-xl ">
                    <p>Vehicle Registration Number</p>
                  </div>
                  <div className="inputItem relative">
                    <input onChange={(e) => { inputChange(e, false) }} onFocus={(e) => { onFocusVehicleNumber(e) }} ref={inputElement}
                      type={"text"}
                      className=" border-none uppercase border-black rounded-md w-[100%] p-1 pl-2"
                      placeholder={"Example GJ18AH0998"} autoComplete={"true"} style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                    />
                    <div className="suggestion__box" style={showSuggestion ? { "display": "flex", "alignItems": "start", "flexDirection": "column" } : { "display": "none" }}>
                      <div className="h-[auto] w-[100%] overflow-auto suggestion_inner_div">
                        {
                          removeDuplicates(stateArray).map(elm => {
                            return <div ref={state_suggesREF} className="font-normal text-start hover_on_suggestion w-[100%] uppercase mt-5" onClick={(e) => { stackClicking(e, elm) }}>{elm}</div>
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex bg-black text-white cursor-pointer rounded-md px-10 py-1" onClick={(e) => { iLogin ? okNext(e) : window.alert("please login first") }}>
                      <div className="font-Cardo font-normal text-xl pr-3">
                        Ok
                      </div>
                      <div className="flex justify-center items-center">
                        <Tick />
                      </div>
                    </div>
                    
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%] flex flex-col items-center justify-center mb-5">
          <div className="hidden bg-white h-[15vh] lg:flex justify-center items-center w-[55%] pt-3 rounded flex-col md:visible" style={{ "boxShadow": "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", "background-color": "white" }}>
            <div className="line relative">
              <Line />
            </div>

            <div className="flex relative w-full justify-between pr-16 pl-20 bottom-[0.7rem]">
              <div className=" flex flex-col items-center">
                <Dot />
                <p className="text-black mt-2">Step 1</p>
              </div>
              <div className=" flex flex-col items-center ">
                <Dot />
                <p className="text-black mt-2">Step 2</p>
              </div>
              <div className=" flex flex-col items-center ">
                <Dot />
                <p className="text-black mt-2">Step 3</p>
              </div>
              <div className=" flex flex-col items-center ">
                <Dot />
                <p className="text-black mt-2">Step 4</p>
              </div>
              <div className=" flex flex-col items-center">
                <Dot />
                <p className="text-black mt-2">Step 5</p>
              </div>

            </div>
          </div>
        </div>
        {/* MAIN SECTION END */}

      </div>
      <Footer />
    </div>
  );
}

export default AddMyVehicle;

import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button } from '@mui/material';
import HeadWithUnderline from '../../components/layouts/HeadingUnderline/HeadWithUnderline';
import SecondNavbar from '../../components/layouts/Navbar/SecondNavbar/SecondNavbar';
import Stroke1 from '../../components/assets/svg/Stroke1';
import Stroke2 from '../../components/assets/svg/Stroke2';
import { MdOutlineArrowCircleRight } from 'react-icons/md';
import Tick from '../../components/assets/svg/Tick';
import { useNavigate } from 'react-router-dom';
import TopSection from '../../TopSection'
import './Wallet.css';
import Footer from '../../components/layouts/Footer/Footer';
import { useState } from 'react';
import axios from 'axios'
import { getRazorPayKey, sendAmount } from "../../apis/api"
import ls from 'localstorage-slim'

function Wallet() {

  const iLogin = ls.get('iLogin');
  let amount, order_id;
  let uid = "";
  if (iLogin) {
    const login_response_object = ls.get("login_data_object", { decrypt: true });
    uid = login_response_object.user._id;
  }
  const navigate = useNavigate();
  const [inputData, setInputData] = useState({
    "amount": 0,
  });
  const [apiData, setApiData] = useState();

  // add amount to wallet
  const checkoutHandler = async () => {

    // get razorpay key
    // await axios.get(getRazorPayKey).then(resp => {
    //   console.log(resp)
    // }).then(err => {
    //   console.log(err)
    // })

    // post amount
    await axios.post(`${sendAmount}`, inputData).then(resp => {
      amount = resp.data.order.amount;
      order_id = resp.data.order.id;
      setApiData(resp.data.order);
    }).then(err => {
      console.log(err)
    })

    sessionStorage.setItem("amount", amount);
    sessionStorage.setItem("order_id", order_id);

    const options = {
      key: "rzp_test_sn7ptzOLk8J69O",
      amount: sessionStorage.getItem("amount"),
      currency: "INR",
      description: "Wallet Balance",
      order_id: sessionStorage.getItem("order_id"),
      callback_url: `https://lorrycare.com:5000/v1/paymentverification/${uid}/${amount}`,
      notes: {
        "address": "Razorpay Corporate Office"
      },
      theme: {
        "color": "#121212"
      }
    };
    const razor = new window.Razorpay(options);
    razor.open();
  }

  return (
    <>
      <div className="">
        <div className="nav">
          <SecondNavbar />
        </div>

        {/* top section Component */}
        <TopSection
          bigi={"Balance Your Wallet"}
          small={"Add money in your wallet"}
        >
        </TopSection>
        {/* top section Component END*/}

        <div className="parent-elem relative my-16">
          <div>
            <div className="main-Box-Vehicle bg-[#fafafa] flex flex-col justify-center items-center ">
              <div className="bg-white w-[90%] flex flex-col justify-center items-center Semi-Main-Box lg:w-[55%] gap-8 pb-4 rounded-md">
                <div className="h-2 bg-black w-full top-0 rounded-b-none rounded-t-md"></div>

                <div className="heading text-3xl font-Cardo font-bold lg:text-[40px] w-full px-8">
                  <h1>Balance Your Wallet</h1>
                </div>
                <div className="second-Section flex flex-col gap-7 w-[85%]">
                  <h1 className="wallet">Add Into Your Wallet</h1>

                  <div className="grid grid-cols-3 gap-3">
                    <button onClick={() => { setInputData({ ...inputData, "amount": 100 }) }} className="col-span-1 bg-black text-white rounded-md px-10 py-1 shadow-gray-700 hover:bg-#ffffff hover:text-black amount">
                      100
                    </button>
                    <button onClick={() => { setInputData({ ...inputData, "amount": 500 }) }} className="col-span-1 bg-black text-white rounded-md px-10 py-1 shadow-gray-700 amount">
                      500
                    </button>
                    <button onClick={() => { setInputData({ ...inputData, "amount": 1000 }) }} className="col-span-1 bg-black text-white rounded-md px-10 py-1 shadow-gray-700 amount">
                      1000
                    </button>
                  </div>
                  <h1 className="wallet">Add Custom Amount</h1>

                  <input
                    className=" border-2 border-black rounded-md w-[30%] p-1 pl-2"
                    placeholder="Example 400"
                    name='amount'
                    type={"number"}
                    value={inputData.amount}
                    onChange={(e) => { setInputData({ ...inputData, [e.target.name]: e.target.value }); console.log(inputData) }}
                  />

                  <div className="flex gap-between">
                    <div className="flex bg-black text-white rounded-md px-10 py-1" onClick={() => { iLogin ? checkoutHandler() : window.alert("please login first") }}>
                      <Link className="flex">
                        <button className="font-Cardo font-normal text-xl pr-3 w-20 flex flex-col justify-center items-center" >
                          Done
                        </button>
                        <div className="flex justify-center items-center">
                          <Tick />
                        </div>
                      </Link>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex justify-center"
          onClick={() => {
            navigate('/contact-us');
          }}>
          <div
            className="rounded-tl-lg ncdbottom cursor-pointer rounded-tr-lg flex justify-center items-center"
            style={{
              width: '53.5%',
              margin: 'auto',
              height: '7rem',
              background: '#FFFFFF',
              background: '#FFFFFF',
              "boxShadow": "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset"
            }}>
            <p className="requst_bottom_para flex justify-center items-center">
              <span style={{ "color": "black" }}>Contact us</span>{' '}
              <span className="material-symbols-outlined ml-5 text-black">
                arrow_forward
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />


    </>
  );
}

export default Wallet;
